// @ts-nocheck
import {
  flow,
} from 'mobx-state-tree';

export default {
  actions: (self) => ({
    getConversations: flow(function* getConversations(
      coachId,
      timeframe,
    ) {
      self.coachConversations = [];

      const result = yield self.hades.request(
        'coach.conversationsList',
        {
          coachId,
          timeframe,
        },
      );

      if (result) {
        self.coachConversations = result;
      }

      return result;
    }),
  }),
};
