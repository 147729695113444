// @ts-nocheck
import {
  flow,
  getParent,
  getRoot,
  types,
} from 'mobx-state-tree';
import { isEmpty } from 'lodash';

import Coach from './Coach';

import DateTime from '../utils/mst/types/DateTime';

export const Instructions = types
  .model('Instructions', {
    automatedMessages: types.array(types.string),
    steps: types.string,
  })
  .views((self) => ({
    get activity() {
      return getParent(self);
    },
  }));

export const Activity = types
  .model('Activity', {
    completed: types.boolean,
    id: types.identifierNumber,
    instructions: types.maybe(Instructions),
    milestoneId: types.number,
    name: types.string,
    notes: types.maybe(types.string),
  })
  .actions((self) => ({
    update: flow(function* update({ completed, notes }) {
      const application = getRoot(self);

      const result = yield application.hades.request(
        'activity.update',
        {
          memberId: self.milestone.track.member.id,
          activityId: self.id,
          ...(completed && { completed }),
          ...(notes && { notes }),
        },
      );

      if (result) {
        self.completed = result.status.completed;
        self.notes = result.status.notes;
      }
    }),
  }))
  .views((self) => ({
    get blocked() {
      return self.milestone.completed;
    },

    get milestone() {
      return getParent(self, 2);
    },
  }));

const ToolboxItem = types
  .model('ToolboxItem', {
    id: types.identifierNumber,
    title: types.string,
    type: types.enumeration('Type', ['QUESTIONNAIRE', 'RESOURCE', 'SAFETY_PLAN']),
  });

export const formReportMixin = {
  guidance: types.string,
  intro: types.optional(types.string, ''),
  memberId: types.number,
  milestoneId: types.number,
  toolbox: types.array(ToolboxItem),
};

export const Report = types
  .model('Report', {
    coachId: types.reference(Coach),
    createdAt: DateTime,
    id: types.identifierNumber,
    ...formReportMixin,
  })
  .actions((self) => ({
    update: flow(function* update({ intro, guidance, toolbox }) {
      const application = getRoot(self);
      const { memberId, milestoneId } = self;

      const response = yield application.hades.request(
        'report.send',
        {
          memberId,
          milestoneId,
          introText: intro,
          reportText: guidance,
          toolbox,
        },
      );

      if (response) {
        self.intro = intro;
        self.text = guidance;
        self.toolbox = toolbox;
      }

      return response;
    }),
  }))
  .views((self) => ({
    get submittedBy() {
      return self.coachId.name;
    },
  }));

export const Milestone = types
  .model('Milestone', {
    activities: types.array(Activity),
    expanded: types.optional(types.boolean, false),
    id: types.identifierNumber,
    name: types.string,
    report: types.maybe(Report),
    trackId: types.number,
  })
  .actions((self) => ({
    send: flow(function* send(values) {
      const application = getRoot(self);
      const {
        guidance,
        intro,
        toolbox,
      } = values;

      const result = yield application.hades.request(
        'report.send',
        {
          introText: intro,
          milestoneId: self.id,
          reportText: guidance,
          toolbox,
          userId: self.member.id,
        },
      );

      if (result) {
        self.report = result;
      }
    }),

    toggleExpanded() {
      self.expanded = !self.expanded;
    },
  }))
  .views((self) => ({
    get completed() {
      return self.activities.every((a) => a.completed);
    },

    get hasReport() {
      return !isEmpty(self.report);
    },

    get member() {
      return self.track.member;
    },

    get track() {
      return getParent(self, 2);
    },
  }));

const Track = types
  .model('Track', {
    assigned: types.boolean,
    color: types.string,
    createdAt: types.string,
    expanded: types.optional(types.boolean, false),
    id: types.identifierNumber,
    milestones: types.array(Milestone),
    name: types.string,
    updatedAt: types.string,
  })
  .actions((self) => ({
    toggleExpanded() {
      self.expanded = !self.expanded;
    },
  }))
  .views((self) => ({
    get member() {
      return getParent(self, 2);
    },
  }));

export default Track;
