import { flow, getParent, getRoot, types } from 'mobx-state-tree';
import { trackBenefitSentInChat } from '@utils/analytics';

import FAQ from './FAQ';
import Service from './Service';
import type { IMember, IRootStore } from './ApplicationInterfaces';

export default types
  .model('Resources', {
    faqs: types.array(FAQ),
    isSearchView: types.optional(types.boolean, false),
    services: types.array(Service),
  })
  .actions((self) => ({
    loadResources: flow(function* loadResources(query) {
      const application = getRoot<IRootStore>(self);
      const member = getParent<IMember>(self);

      // clear the previous search!
      self.services.clear();
      self.faqs.clear();

      const result = yield application.hades?.request('resource.list', {
        memberId: member.id,
        ...(query && { query }),
      }) as Promise<any>;

      if (result && result.length) {
        self.isSearchView = !!query;
        self.services = result.filter(
          ({ type }: { type: string }) => type === 'service',
        );
        self.faqs = result.filter(
          ({ type }: { type: string }) => type === 'faq',
        );
      }
    }),

    shareService: flow(function* shareService(services) {
      const application = getRoot<IRootStore>(self);
      const { member } = application;
      const result = yield application.hades?.request('service.share', {
        memberId: member.id,
        services,
      }) as Promise<any>;

      const servicesId: Array<number> = result.map(
        ({ serviceId }: { serviceId: number }) => serviceId,
      );

      self.services
        .filter(({ id }) => servicesId.includes(id))
        .map((service) => service.setShared(true));

      servicesId.forEach((id) => {
        const serviceFound = self.services.find((service) => service.id === id);
        if (serviceFound) {
          const data = {
            ...member,
            vendorName: serviceFound.vendor.name,
            serviceName: serviceFound.name,
          };
          trackBenefitSentInChat(data);
        }
      });

      return result;
    }),
  }))
  .views((self) => ({
    get member() {
      return getParent(self);
    },
  }));
