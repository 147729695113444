import { MonitoringService } from '@services';
import { LogLevel, SiblySDK } from '@sibly/sibly-sdk-browser';
import { Logger } from '@utils/logger';
import EventEmitter from 'events';
import ReconWebSocket from 'reconnecting-websocket';
import { v4 } from 'uuid';
import { IS_PROD } from '../Config';

// API Gateway WebSocket API will close connection after 10 minutes if there's no activity.
// To prevent this, we use ping pong heartbeat (client sends ping, server responds with pong).
const PING_INTERVAL = 30000; // 30 seconds

export default class Socket extends EventEmitter {
  client: ReconWebSocket;

  pingTimer?: number;

  constructor(urlProvider: () => Promise<string>, socket: typeof WebSocket) {
    super();

    const options = {
      debug: !IS_PROD, // enable debug output if not in prod (logs JWT/auth token)
      WebSocket: socket || WebSocket,
    };

    this.client = new ReconWebSocket(urlProvider, [], options);

    this.client.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (!data) {
        Logger.error('WebSocket:client:onmessage No message data');
        MonitoringService.addLog({
          message: 'WebSocket:client:onmessage No message data',
          logGroup: 'Socket',
          logRecordType: 'WebSocketClientOnMessageNoDataError',
          error: new Error('No message data'),
        });
      } else if (data.error) {
        const { error } = data;
        Logger.error('WebSocket:client:onmessage Error', error);
        MonitoringService.addLog({
          message: 'WebSocket client:onmessage Error',
          logGroup: 'Socket',
          logRecordType: 'WebSocketClientOnMessageError',
          error,
          details: {
            message: error.message,
            type: error.type,
            target: error.target,
          },
        });
      } else if (!data.method) {
        Logger.error(`WebSocket:client:onmessage Message data has no method message=${data.message} connectionId=${data.connectionId}`);
        MonitoringService.addLog({
          message: 'WebSocket client:onmessage Message data has no method',
          logGroup: 'Socket',
          logRecordType: 'WebSocketClientOnMessageNoMethodError',
          details: {
            connectionId: data.connectionId,
          },
          error: data.error || new Error('Message data has no method'),
        });
      } else {
        Logger.log(`WebSocket:client:onmessage method=${data.method}`);
        SiblySDK.Monitoring.addBreadcrumb({
          message: `WebSocket:client:onmessage method=${data.method}`,
          category: 'WebSocket',
        });

        this.emit(data.method, data.result);
      }
    };

    this.client.addEventListener('open', () => {
      Logger.log('WebSocket:client:onopen');
      MonitoringService.addLog({
        level: LogLevel.DEBUG,
        message: 'WebSocket client:onopen',
        logGroup: 'Socket',
        logRecordType: 'WebSocketClientOnOpen Debug',
      });

      clearInterval(this.pingTimer);
      this.pingTimer = window.setInterval(this.ping.bind(this), PING_INTERVAL);
    });

    this.client.addEventListener('close', (event) => {
      Logger.log(`WebSocket:client:onclose: code=${event.code} reason=${event.reason}`);
      MonitoringService.addLog({
        level: LogLevel.DEBUG,
        message: 'WebSocket client:onclose',
        logGroup: 'Socket',
        logRecordType: 'WebSocketClientOnClose Debug',
        details: {
          ...event,
          code: event.code,
          reason: event.reason,
        },
      });
    });

    /**
     * Fired when a connection with a WebSocket has been closed because
     * of an error, such as when some data couldn't be sent.
     */
    this.client.onerror = (error) => {
      Logger.error('WebSocket:client:onerror', error.message, error.error);
      MonitoringService.addLog({
        message: 'WebSocket client:onerror',
        logGroup: 'Socket',
        logRecordType: 'WebSocketClientOnError',
        error: error.error,
        details: {
          message: error.message,
          type: error.type,
          target: error.target,
        },
      });
    };
  }

  // TODO: replace this with HTTP API
  request(method: string, params = {}): void {
    Logger.log(`WebSocket:client:request method=${method}`);
    SiblySDK.Monitoring.addBreadcrumb({
      message: `request method: ${method}`,
      category: 'Websocket',
    });

    const id = v4();

    const envelope = {
      method,
      params,
      id,
      jsonrpc: '2.0',
    };

    this.client.send(JSON.stringify(envelope));
  }

  ping() {
    const browserTabId = window.name;

    if (this.client && this.client.readyState === 1) {
      this.client.send(
        JSON.stringify({
          browserTabId,
          method: 'ping',
        }),
      );

      Logger.log(`WebSocket:client:ping PING sent to server, browserTabId=${browserTabId}`);
      SiblySDK.Monitoring.addBreadcrumb({
        message: `WebSocket:client:ping PING sent to server, browserTabId=${browserTabId}`,
        category: 'WebSocket',
      });
    } else {
      Logger.log(`WebSocket:client:ping Connection is not open, no ping sent, browserTabId=${browserTabId}`);
      MonitoringService.addLog({
        level: LogLevel.DEBUG,
        message: 'WebSocket client:ping Connection is not open, no ping sent',
        logGroup: 'Socket',
        logRecordType: 'WebSocketClientPing Debug',
        details: {
          browserTabId,
        },
      });
    }
  }
}
