// @ts-nocheck
import { types } from 'mobx-state-tree';
import moment from 'moment-timezone';

import AccessCode from '../AccessCode';

import DateTime from '../../utils/mst/types/DateTime';

const Membership = types.model(
  'Membership',
  {
    accessCode: types.reference(AccessCode),
    startAt: DateTime,
    endAt: DateTime,
    status: types.string,
    terminatedAt: DateTime,
    type: types.maybe(types.union(types.literal('enterprise'), types.literal('selfpaid'))),
    newMemberAlertSentAt: types.maybe(DateTime),
    scheduledEndAt: types.maybe(DateTime),
  },
)
  .actions((self) => ({
    setNewMemberAlertSentAt(date) {
      self.newMemberAlertSentAt = date;
    },
  }))
  .views((self) => ({
    get formmatedStartAt() {
      return self.startAt.format('YYYY-MM-DD hh:mma');
    },

    get isActive() {
      return self.status === 'active' && moment(self.startAt) < moment();
    },

    get isStopped() {
      return self.status === 'stopped' && self.endAt !== undefined;
    },

    get isTerminated() {
      return self.status === 'terminated' && self.terminatedAt !== undefined;
    },

    get formattedScheduledEndAt(): string | null {
      return self.scheduledEndAt ? self.scheduledEndAt.format('YYYY-MM-DD') : null;
    },
  }));

export default Membership;
