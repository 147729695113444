/* eslint-disable max-classes-per-file */

// eslint-disable-next-line import/prefer-default-export

class CustomError extends Error {
  constructor(name: string, message: string) {
    const trueProto = new.target.prototype;
    super(`${name}: ${message}`);
    this.name = name;
    Object.setPrototypeOf(this, trueProto);
  }
}

export class ValidationError extends CustomError {
  constructor(message: string) {
    super('ValidationError', message);
  }
}

export class APIError extends CustomError {
  requestId?: string;

  code?: number;

  stack?: string;

  url?: string;

  method?: string;

  constructor(message: string, error?: APIError) {
    super('APIError', message);
    if (error) {
      const { message: errorMessage, ...restErrorParams } = error;
      Object.assign(this, restErrorParams);
    }
  }
}
