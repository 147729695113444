import Concern, { EditModeConcern } from '@stores/Member/AgendaMapping/Concern';
import {
  getRoot,
  flow,
  Instance,
  types,
} from 'mobx-state-tree';
import _ from 'lodash';
import { ValidationError } from '@utils/customErrors';
import {
  trackAgendaMappingSentinChat,
  trackAgendaMappingEditedConcernText,
  trackAgendaMappingSave,
  trackAgendaMappingConcernDeleted,
  trackAgendaMappingConcernMarkedAsPriority,
  trackAgendaMappingConcernMarkedDoNotDiscuss,
} from '@utils/analytics';
import LastShared from './LastShared';
import LastUpdated from './LastUpdated';

export type EditableFields = Omit<Partial<EditModeConcern>, 'id'>

export type EditModeConcerns = { [concernId: number]: EditModeConcern }

type Volatile = {
  editModeConcerns: { [concernId: number]: EditModeConcern };
  concernIdEditingText: number | null;
  enableSave: boolean;
}
type ConcernType = Instance<typeof Concern>;

export const CONCERN_TEXT_LENGTH_LIMIT = 99;

const AgendaMapping = types.model('AgendaMapping', {
  concerns: types.array(Concern),
  lastShared: types.maybeNull(LastShared),
  lastUpdated: types.maybeNull(LastUpdated),
  attached: types.array(types.reference(Concern)),
})
  .volatile<Volatile>(() => ({
    editModeConcerns: {},
    concernIdEditingText: null,
    enableSave: false,
  }))
  .actions((self) => ({
    addAttached(concern: Instance<ConcernType>) {
      self.attached.push(concern);
      // @ts-ignore
      self.attached = _.uniq(self.attached, 'id');
    },

    removeAttached(concern: Instance<ConcernType>) {
      self.attached.remove(concern);
    },

    clearAttached() {
      self.attached.clear();
    },

    shareConcerns: flow(function* shareConcerns(concerns: Array<Instance<typeof Concern>>) {
      const {
        chat, hades, member,
      } = getRoot(self);

      const result = yield hades.request(
        'agendaConcern.share',
        {
          memberId: member.id,
          sharedConcernIds: concerns.map((t) => t.id),
        },
      );

      trackAgendaMappingSentinChat({
        concerns: concerns.map((t) => t.text),
        memberId: member.id,
      });

      self.lastShared = result.lastShared;
      self.attached.clear();
      chat.input.clear();

      return result;
    }),

    startEditConcerns() {
      self.editModeConcerns = this.mapConcernsToEditModeConcerns();
      self.enableSave = false;
    },

    cancelEditConcerns() {
      self.editModeConcerns = {};
      self.enableSave = false;
    },

    setConcernIdEditingText(concernId: number | null) {
      self.concernIdEditingText = concernId;
    },
    updateEditModeConcern(concernId: number, newProperties: EditableFields) {
      self.enableSave = true;
      self.editModeConcerns = {
        ...self.editModeConcerns,
        [concernId]: {
          ...self.editModeConcerns[concernId],
          ...newProperties,
        },
      };
    },
    trackEditConcern<T extends keyof EditableFields>(
      concernId: number, propertyName: T, value: EditableFields[T] | void,
    ) {
      const { me: { name: coachName }, member: { id: memberId } } = getRoot(self);
      const concern = self.concerns.find((t) => t.id === concernId)?.text;
      if (!concern) return;
      switch (propertyName) {
        case 'priority':
          trackAgendaMappingConcernMarkedAsPriority({ coachName, concern, memberId });
          break;
        case 'doNotDiscuss':
          trackAgendaMappingConcernMarkedDoNotDiscuss({ coachName, memberId, concern });
          break;
        case 'deleted':
          trackAgendaMappingConcernDeleted({ coachName, memberId, concern });
          break;
        case 'text':
          trackAgendaMappingEditedConcernText({
            coachName, editedConcern: value, memberId, concern,
          });
          break;
        default:
          break;
      }
    },
    isCharsLimitExceeded(concern: EditModeConcern) {
      return concern.text.length > CONCERN_TEXT_LENGTH_LIMIT;
    },
    validateEditedConcerns() {
      const concernIds = Object.keys(self.editModeConcerns);
      concernIds.forEach((concernId) => {
        const editedConcern = self.editModeConcerns[concernId];
        const { text } = editedConcern as EditModeConcern;
        if (!text.length) {
          throw new ValidationError("Concerns text can't be empty");
        }
        if (editedConcern.text.length > CONCERN_TEXT_LENGTH_LIMIT) {
          throw new ValidationError("Concerns text can't contain more than 99 characters");
        }
      });
    },
    saveEditedConcerns: flow(function* saveEditedConcerns() {
      try {
        const { hades, member: { id: memberId }, me: { name } } = getRoot(self);
        const concernsArray = Object.values(self.editModeConcerns);
        self.enableSave = false;
        const result = yield hades.request('agendaConcern.update', { concerns: concernsArray, memberId });
        trackAgendaMappingSave({ memberId, coachName: name });
        self.concerns = result.concerns;
        self.lastShared = result.lastShared;
        self.lastUpdated = result.lastUpdated;
        self.editModeConcerns = {};
      } catch (e) {
        self.enableSave = true;
        throw (e);
      }
    }),

    mapConcernsToEditModeConcerns() {
      return self.concerns.reduce((acum, concern) => ({
        ...acum,
        [concern.id]: concern.editModeVersion(),
      }), {} as EditModeConcerns);
    },
  }))
  .views((self) => ({
    get lastCreatedConcern() {
      return self.concerns.reduce((lastCreated, concern) => {
        if (!lastCreated || concern.id > lastCreated.id) {
          return concern;
        }
        return lastCreated;
      }, null as ConcernType | null);
    },

    get latestColorRankUsed() {
      if (!this.lastCreatedConcern) {
        return null;
      }

      return this.lastCreatedConcern.colorId.orderRank;
    },

    get nextConcernColor() {
      const { concernColorsByRankMap } = getRoot(self);

      // if concerns list is empty, select the first color in rank order
      if (this.latestColorRankUsed === null) return concernColorsByRankMap.get(1);

      // if last concern created used the last color, pick the first one
      return concernColorsByRankMap.get(
        this.latestColorRankUsed + 1,
      ) || concernColorsByRankMap.get(1);
    },
  }));

export default AgendaMapping;
