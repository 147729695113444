// @ts-nocheck
import {
  flow,
  getParent,
  getRoot,
  Instance,
  types,
} from 'mobx-state-tree';

import moment from 'moment-timezone';

import {
  reject,
  findLast,
  first,
  last,
} from 'lodash';

import { formatDbMessage } from '@utils/formatDbMessage';

import { getTimeDiff } from '@utils/dates';
import { runTitleNotification } from '@utils/runTitleNotification';
import { Logger } from '@utils/logger';

import Message from './Message';

import DateTime from '../utils/mst/types/DateTime';

const safeParse = (string) => {
  if (
    string.length > 1
    && string[0].match(/\{|\[/)
    && string.substr(-1).match(/\}|\]/)
  ) {
    return JSON.parse(string);
  }

  return {};
};

const ChannelModel = types.model('Channel', {
  id: types.identifierNumber,
  lastReadAt: DateTime,
  messages: types.array(Message),
  searchResult: types.array(Message),
  searchMessages: types.array(Message),
  url: types.string,
  likedMessagesIds: types.optional(types.array(types.number), []),
  sortTimestamp: DateTime,
  lastMemberMessageAt: DateTime,
  elapsedTimeSinceLastMemberMessage: types.optional(types.string, '0 min'),
})
  .actions((self) => ({
    updateElapsedTimeSinceLastMemberMessage() {
      self.elapsedTimeSinceLastMemberMessage = getTimeDiff(self.lastMemberMessageAt);
    },
  }))
  .actions((self) => ({
    appendMessages(messages = []): void {
      const { timers } = getRoot(self);

      const { lastMessage: previousLastMessage } = self;

      Logger.log(`appending ${messages.length} message(s) to channel: ${self.id}`);
      self.setMessages(self.messages.concat(messages));

      self.updateSortTimestamp(self.lastMessage, previousLastMessage);

      self.updateLastMemberMessageAt();

      self.checkForAudioNotification();

      timers.set(
        self.id,
        setTimeout(
          () => {
            if (!self.lastMessage.isMember) {
              const date = moment().subtract(1, 'month');
              self.setLastReadAt(date);
            }
          },
          moment(new Date())
            .add(5, 'minutes')
            .subtract(self.lastMessage?.timestamp << 0) // eslint-disable-line
            .valueOf(),
        ),
      );
    },

    updateSortTimestamp(newMessage, lastMessage) {
      if (newMessage.isBot || (newMessage.isMember && lastMessage?.isMember)) {
        return false;
      }

      Logger.log(`updating sort timestamp for channel: ${self.id}`, newMessage.momentTimestamp.format('MM-DD hh:mm'));
      self.sortTimestamp = newMessage.momentTimestamp;
      return true;
    },

    updateLastMemberMessageAt() {
      const { lastMessage } = self;
      if (lastMessage.isMember) {
        Logger.log(`updating lastMemberMessageAt for channel: ${self.id}`, lastMessage.momentTimestamp.format('MM-DD hh:mm'));
        self.lastMemberMessageAt = lastMessage.momentTimestamp;
        self.updateElapsedTimeSinceLastMemberMessage();
      }
    },

    checkForAudioNotification() {
      const {
        playUnassignedAudio,
        playNewMessageAudio,
        playSoundNotifications,
      } = getRoot(self);

      // TODO Investigate: does member here always refer to the member in this channel?
      const { member } = self;

      Logger.log(`checkForAudioNotification member=${member.id} channel=${self.id} playSoundNotifications=${playSoundNotifications} self.lastMessage.isMember=${self.lastMessage.isMember} member.isUnassigned=${member.isUnassigned} member.isGrabbedByMe=${member.isGrabbedByMe}`);

      if (playSoundNotifications && self.lastMessage.isMember) {
        if (member.isUnassigned) {
          Logger.log(`playing unassigned audio for channel: ${self.id}`);
          playUnassignedAudio();
          runTitleNotification(`🔔 ${member.chatName} has sent a message...`);
        } else if (member.isGrabbedByMe) {
          Logger.log(`playing new message audio for channel: ${self.id}`);
          playNewMessageAudio();
          runTitleNotification(`🔔 ${member.chatName} has sent you a message...`);
        }
      }
    },

    clearSearchResult() {
      self.searchResult = [];
    },

    formatSendbirdMessage(message) {
      // eslint-disable-next-line
      const sender = message._sender || message.sender;
      return !sender
        ? message
        : {
          content: message.url || message.message,
          data: safeParse(message.data),
          id: message.messageId,
          isFile: Boolean(message.url),
          // eslint-disable-next-line
          senderId: sender.userId,
          timestamp: message.createdAt,
        };
    },

    getHighlights: flow(function* getHighlights(limit) {
      const { hades } = getRoot(self);

      const response = yield hades.request('channel.highlights', {
        channelId: self.id,
        limit,
      });

      return response;
    }),

    getMessageById(messageId) {
      return self.messages.find((message) => message.id === messageId);
    },

    getSearchMessageById(messageId) {
      return self.searchMessages.find(
        (message) => message.id === messageId,
      );
    },

    isJumpToMessageById(messageId) {
      return self.searchResult.find(
        (message) => message.id === messageId && message.isJumpTo,
      );
    },

    prependMessages(messages = []): void {
      self.setMessages(messages.concat(self.messages));
    },

    prependSearchMessages(messages = []): void {
      self.setSearchMessages(messages.concat(self.searchMessages));
    },

    appendSearchMessages(messages = []): void {
      self.setSearchMessages(self.searchMessages.concat(messages));
    },

    removeMessageById(messageId): void {
      self.setMessages(reject(self.messages, { id: messageId }));
    },

    search: flow(function* search(query) {
      const application = getRoot(self);

      const messageResults = yield application.hades.request('message.search', {
        channel: self.id,
        query,
      });

      const searchResult = messageResults.map((message, index) => ({
        ...formatDbMessage({
          ...message,
          // TODO: revisit this and clean up post-replatform.
          // this is a quick workaround because we have a lot of messages in legacy
          // without sendbird ID. See https://sibly-jira.atlassian.net/browse/SIB-2810
          id: message.sendbirdId || -index,
        }, self.member.chatId),
        searchHighlightRanges: message.highlightRanges,
        searchRelevance: message.relevance,
      }));

      self.searchResult = searchResult;
    }),

    setLastReadAt(dateish) {
      Logger.log(`setting last read at for channel: ${self.id}`, moment(dateish).toDate().toISOString());
      self.lastReadAt = moment(dateish).toDate();
    },

    setMessages(messages): void {
      const { formatSendbirdMessage } = self;

      const formattedMessages = messages.map(formatSendbirdMessage);

      self.messages = formattedMessages;

      self.updateMessagesLinkPreviews(self.messages);
    },

    updateMessagesLinkPreviews(messages) {
      const { addPreviewUrl } = getRoot(self);

      messages.forEach((message) => {
        const { data } = message;

        if (data.linkPreview && data.linkPreview.length) {
          (data.linkPreview as Array<string>).forEach((url) => addPreviewUrl(url));
        }
      });
    },

    setSearchMessages(messages) {
      const { formatSendbirdMessage } = self;

      const formattedMessages = messages.map(formatSendbirdMessage);

      self.searchMessages = formattedMessages;
    },

    update() {
      const root = getRoot(self);
      const { id } = self;

      root.socket.request('channel.update', {
        channelId: id,
        lastReadAt: Date.now(),
      });
    },

    addLikeMessage(messageId) {
      self.likedMessagesIds.push(messageId);
    },

    removeLikedMessage(messageId) {
      self.likedMessagesIds = self.likedMessagesIds.filter(
        (id) => id !== messageId,
      );
    },
  }))

  .views((self) => ({
    get member() {
      return getParent(self);
    },

    get isValid() {
      return /sendbird_group_channel/.test(self.url);
    },

    get isValidFlag() {
      return self.isValid ? '' : ' *';
    },

    get momentLastReadAt(): boolean | moment.Moment {
      if (!self.lastReadAt) {
        return false;
      }

      return moment(self.lastReadAt);
    },

    get lastMemberMessage() {
      return findLast(self.messages, (m) => m.isMember);
    },

    get lastMemberMessageDaysAgo() {
      const dateDiff = moment().diff(
        self?.lastMemberMessage?.momentTimestamp,
        'days',
      );
      return dateDiff >= 0 ? `${dateDiff} days ago` : 'Never';
    },

    get lastReadMemberMessage() {
      return findLast(
        self.messages,
        (m) => m.isMember && m.momentTimestamp.isSameOrBefore(self.momentLastReadAt),
      );
    },

    get unreadMemberMessages() {
      return self.messages.filter(
        (m) => m.isMember && m.momentTimestamp.isAfter(self.momentLastReadAt),
      );
    },

    // channel has unread member messages
    get hasPinkDot(): boolean {
      const {
        lastMessage,
        member,
        momentLastReadAt,
      } = self;

      const lastMessageIsFromMember = lastMessage?.isMember;
      const lastMessageIsAfterMomentLastReadAt = lastMessage?.momentTimestamp.isAfter(
        momentLastReadAt,
      );

      const hasPinkDot = Boolean(lastMessageIsFromMember && lastMessageIsAfterMomentLastReadAt);

      member.log(`Channel:hasPinkDot momentLastReadAt=${momentLastReadAt} lastMessageId=${lastMessage?.id} lastMessageMomentTimestamp=${lastMessage?.momentTimestamp} lastMessageIsFromMember=${lastMessageIsFromMember} lastMessageIsAfterMomentLastReadAt=${lastMessageIsAfterMomentLastReadAt} hasPinkDot=${hasPinkDot}`);

      return hasPinkDot;
    },

    // channel's last message is written by a coach AND
    // it's been more than 5 minutes since the last time the chat was open
    get hasYellowDot(): boolean {
      return Boolean(
        self.lastMessage
        && self.lastMessage.isSibly
        && self.lastMessage.momentTimestamp.isAfter(self.momentLastReadAt)
        && self.lastMessage.momentTimestamp.isSameOrBefore(
          moment().subtract(5, 'minutes'),
        ),
      );
    },

    get messageIndicator() {
      if (self.hasPinkDot) {
        return 'Pink dot';
      }
      if (self.hasYellowDow) {
        return 'Yellow dot';
      }
      return 'No dot';
    },

    get hasUnreadMemberMessages() {
      return Boolean(self.unreadMemberMessages.length > 0);
    },

    get likedMessages() {
      return self.messages.filter((message) => self.likedMessagesIds.includes(message.id));
    },

    get firstMessage() {
      return first(self.messages);
    },

    get lastMessage() {
      return last(self.messages);
    },

    get firstSearchMessage() {
      return first(self.searchMessages);
    },

    get lastSearchMessage() {
      return last(self.searchMessages);
    },
  }));

export default ChannelModel;

export type ChannelInstance = Instance<typeof ChannelModel>
