// @ts-nocheck
import {
  types,
} from 'mobx-state-tree';

import { momentPT } from '@utils/dates';

import Coach from './Coach';

import DateTime from '../utils/mst/types/DateTime';

const FollowUp = types
  .model('FollowUp', {
    message: types.string,
    frequency: types.union(
      types.literal(0),
      types.literal(1),
      types.literal(3),
      types.literal(7),
      types.literal(14),
    ),
    hour: types.number, // on 24-hour-clock
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(Coach)),
  })
  .views((self) => ({
    get formmatedTimestamp() {
      return momentPT(self.updatedAt).format('hh:mm A [PST] MMM DD, YYYY');
    },
  }));

export default FollowUp;
