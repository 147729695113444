import { useCallback, useState } from 'react';
import { SiblySDK } from '@sibly/sibly-sdk-browser';

import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import Modal from '@components/Modal';

import Store from '@store';

import ChangePasswordModal, {
  ChangePasswordModalProps,
} from '@components/ChangePasswordModal';
import styles from './chat-navigation-settings-modal.module.scss';

interface ChatNavigationSettingModalProps {
  visible: boolean;
  onClose: () => void;
}

const ChatNavigationSettingModal = ({
  visible = false,
  onClose,
}: ChatNavigationSettingModalProps) => {
  const {
    logout,
    me,
    playSoundNotifications,
    toggleSoundNotifications,
  } = Store;
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmPasswordLoading, setIsConfirmPasswordLoading] = useState(false);

  const handleChangePassword = () => setIsVisible(true);

  const handleClose = () => setIsVisible(false);

  const handleSubmit = useCallback<ChangePasswordModalProps['onSubmit']>(
    async ({ currentPassword, newPassword }) => {
      try {
        setIsConfirmPasswordLoading(true);
        await SiblySDK.Authorization.updatePassword({
          oldPassword: currentPassword.trim(),
          newPassword: newPassword.trim(),
        });
      } finally {
        setIsConfirmPasswordLoading(false);
      }
    },
    [],
  );

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Settings"
      size="sm"
      label="chat-navigation-settings"
    >
      <Container className={cn(styles.content, 'px-2 py-1')}>
        <div className="m-3">
          <div className="text-muted">Name:</div>
          <div>{me?.name}</div>
        </div>

        <div className="m-3">
          <div className="text-muted">ID:</div>
          <div>{me?.id}</div>
        </div>

        <div className="m-3">
          <div className="text-muted">Email:</div>
          <div>{me?.email}</div>
        </div>

        <Form className="m-3">
          <Form.Check
            type="switch"
            id="sound-notification-switch"
            label="Sound Notification"
            checked={playSoundNotifications}
            onChange={toggleSoundNotifications}
          />
        </Form>
        <div className="d-flex justify-content-center m-3">
          <ChangePasswordModal
            onClose={handleClose}
            visible={isVisible}
            isLoading={isConfirmPasswordLoading}
            onSubmit={handleSubmit}
          />
          <Button
            className="w-100"
            data-testid="change-password"
            onClick={handleChangePassword}
            variant="outline-secondary"
          >
            Change Password
          </Button>
        </div>
        <div className="d-flex justify-content-center m-3">
          <Button
            className="w-100"
            data-testid="logout-btn"
            onClick={logout}
            variant="outline-danger"
          >
            Log out
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default observer(ChatNavigationSettingModal);
