import ReactDOM from 'react-dom';

import { bootup } from '@utils/bootup';
import { IS_PROD } from './Config';

import App from './App';
import UserSnap from './components/UserSnap';

import * as serviceWorker from './serviceWorker';

import Analytics from './components/Analytics';
import { SplitContainer } from './SplitIO';

// bootup global event listeners and integrations
bootup();

ReactDOM.render(
  <>
    <Analytics />
    {
      !IS_PROD && (
        <UserSnap />
      )
    }
    <SplitContainer>
      <App />
    </SplitContainer>
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
