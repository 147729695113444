import { flow } from 'mobx-state-tree';
import { navigate } from '@reach/router';
import { SiblySDK } from '@sibly/sibly-sdk-browser';

import { IRootStore } from '@stores/ApplicationInterfaces';
import { APIError } from '@utils/customErrors';
import FullStoryService from '@services/FullStory';
import { MonitoringService } from '@services';

export type AuthMixinActions = {
  onAuthenticated: () => Promise<void>;
  onAcceptLegalAgreement: () => Promise<void>;
  onCancelLegalAgreement: () => Promise<void>;
  logout: () => Promise<void>;
  clearUserSession: () => Promise<void>;
  authenticate: (username: string, password: string) => Promise<void>;
  forgotCoachPassword: ({ username }: { username: string }) => Promise<void>;
  resetPassword: (data: any) => Promise<void>;
};

const AuthMixin = {
  actions: (self: IRootStore) => ({
    onAuthenticated: flow(function* onAuthenticated() {
      yield self.initializeDashboard() as any;

      const url = new URL(window.location.href);
      const pathname = url.pathname.substring(1);

      navigate(`/${!pathname.length ? 'dashboard' : pathname}`);
    }),

    authenticate: flow(function* authenticate(username, password) {
      try {
        const { nextStep } = yield SiblySDK.Authorization.signIn({
          username,
          password,
        });

        if (
          nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ) {
          self.isShowingConfirmSignInWithNewPasswordModal = true;
        }

        if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          yield SiblySDK.Authorization.resendSignUpCode({ username });
          self.isShowingConfirmEmailModal = true;
        }

        if (nextStep.signInStep === 'DONE') {
          yield SiblySDK.Authorization.getSessionTokens();

          self.isShowingLegalAgreement = true;
        }
      } catch (e) {
        MonitoringService.addLog({
          message: '[MST][AuthMixin] authenticate error',
          logGroup: '[AuthMixin] authenticate',
          logRecordType: 'CoachLoginError',
          error: e as Error,
          details: {
            username,
          },
        });
        throw e;
      }
    }),

    onCancelConfirmSignInWithNewPassword() {
      self.isShowingConfirmSignInWithNewPasswordModal = false;
    },

    onConfirmSignInWithNewPassword: flow(
      function* onConfirmSignInWithNewPassword(password: string) {
        try {
          yield SiblySDK.Authorization.confirmSignIn({
            challengeResponse: password,
          });
          yield SiblySDK.Authorization.sendUserAttributeVerificationCode({
            userAttributeKey: 'email',
          });
          self.isShowingConfirmSignInWithNewPasswordModal = false;
          self.isShowingConfirmEmailModal = true;
        } catch (error) {
          MonitoringService.addLog({
            message:
              '[ConfirmSignInScreen] onConfirmSignInWithNewPassword error',
            logGroup: '[AuthMixin] onConfirmSignInWithNewPassword',
            logRecordType: 'OnConfirmSignInWithNewPasswordError',
            error: error as Error,
          });
          throw error;
        }
      },
    ),

    onCancelConfirmEmail() {
      self.isShowingConfirmEmailModal = false;
    },

    onConfirmEmail: flow(function* onConfirmEmail(code: string) {
      try {
        yield SiblySDK.Authorization.confirmUserAttribute({
          userAttributeKey: 'email',
          confirmationCode: code,
        });
        yield SiblySDK.Authorization.getSessionTokens();

        self.isShowingConfirmEmailModal = false;
        self.isShowingLegalAgreement = true;
      } catch (e) {
        MonitoringService.addLog({
          message: '[MST][AuthMixin] confirm email error',
          logGroup: '[AuthMixin] confirm email',
          logRecordType: 'ConfirmEmailError',
          error: e as Error,
        });
        throw e;
      }
    }),

    onAcceptLegalAgreement: flow(function* onAcceptLegalAgreement() {
      try {
        self.isShowingLegalAgreement = false;
        // Run our post authentication code-er-oni
        yield self.onAuthenticated();
      } catch (e) {
        if (e instanceof APIError) {
          self.showAlert(e.message, 'danger', e);
          self.logout();
        } else {
          self.showAlert(
            'there was an error loading dashboard resources',
            'danger',
            e,
          );
        }
      }
    }),

    onCancelLegalAgreement: flow(function* onCancelLegalAgreement() {
      self.isShowingLegalAgreement = false;
      yield self.logout();
    }),

    logout: flow(function* logout() {
      try {
        yield SiblySDK.Authorization.signOut();
        yield self.clearSendBird();
      } catch (e) {
        self.showAlert('There was an error logging out.', 'danger', e);
      } finally {
        yield self.clearUserSession();
        FullStoryService.shutdown();
        self.clearIntervalForElapsedTimeUpdate();
      }
    }),

    forgotCoachPassword: flow(function* forgotCoachPassword({ username }) {
      yield SiblySDK.Authorization.resetPassword({ username });
    }),

    resetPassword: flow(function* resetPassword(data) {
      self.dismissAlert();
      yield SiblySDK.Authorization.confirmResetPassword(data);

      self.showAlert('Your password has been reset! Please log in');
    }),

    clearUserSession() {
      window.sessionStorage.clear();
      window.location.replace('/');

      self.me = undefined;
      self.socket = undefined;

      return self.save();
    },
  }),
};

export default AuthMixin;
