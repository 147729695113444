import {
  trackGASGoalArchived,
  trackGASGoalCreated,
  trackGASGoalNameChanged,
} from '@utils/analytics';

import {
  flow,
  getRoot,
  IAnyStateTreeNode,
  IArrayType,
  Instance,
  types,
} from 'mobx-state-tree';

import Goal from '@stores/Member/GAS/Goal';
import { IRootStore } from '@stores/ApplicationInterfaces';
import _ from 'lodash';
import Hades from '@services/Hades';

export type CreateGoalInput = {
  goalName: string;
  type: 'oneway' | 'twoway' | 'maintenance';
  startDate: Date;
  desiredOutcome: string;
} & Partial<{
  endDate: Date;
  minusOneGoal: string;
  minusTwoGoal: string;
  plusOneGoal: string;
  plusTwoGoal: string;
}>;

export default {
  attributes: {
    gas: types.array(Goal),
  },
  actions: (self: IAnyStateTreeNode) => ({
    createGoal: flow(function* createGoal(values: CreateGoalInput) {
      const root = getRoot<IRootStore>(self);
      const { me } = root;

      // endpoint needs to omit the empty params
      const omitted = _.pickBy(
        values,
        (value) => !(value === undefined || value === ''),
      );

      const result = yield (root.hades as Hades).request('memberGoal.create', {
        memberId: self.id,
        goal: omitted.desiredOutcome,
        name: omitted.goalName,
        type: omitted.type,
        startAt: omitted.startDate,
        endAt: omitted.endDate,
        minusOneGoal: omitted.minusOneGoal,
        minusTwoGoal: omitted.minusTwoGoal,
        plusOneGoal: omitted.plusOneGoal,
        plusTwoGoal: omitted.plusTwoGoal,
      });

      trackGASGoalCreated({
        memberId: self.id,
        coachName: me?.name,
        organizationName: self.organization?.name,
        goalType: values.type,
        endDate: values.endDate,
        creationDate: values.startDate,
      });

      self.gas = [result];

      return result;
    }),

    archiveGoal: flow(function* archiveGoal(goalId: number) {
      const root = getRoot<IRootStore>(self);
      const { me } = root;

      const goalFound = (self.gas as Instance<IArrayType<typeof Goal>>).find(
        (goal) => goal.id === goalId,
      ) as Instance<typeof Goal>;

      yield (root.hades as Hades).request('memberGoal.archive', {
        memberId: self.id,
        goalId,
      });

      trackGASGoalArchived({
        memberId: self.id,
        coachName: me?.name,
        organizationName: self.organization?.name,
        lastReportedScore: goalFound.lastProgressUpdate.score,
        goalName: goalFound.name,
        goalType: goalFound.type,
      });

      self.gas.remove(goalFound);
    }),

    editGoal: flow(function* editGoal(values) {
      const root = getRoot<IRootStore>(self);
      const { me } = root;

      // TODO: pull this to a utility function for DRYness
      // endpoint needs to omit the empty params
      const omitted = _.pickBy(
        values,
        (value) => !(value === undefined || value === ''),
      );

      const result = yield (root.hades as Hades).request('memberGoal.update', {
        memberId: self.id,
        goalId: self.gas[0].id,
        goal: omitted.desiredOutcome,
        name: omitted.goalName,
        type: omitted.type,
        endAt: omitted.endDate,
        minusOneGoal: omitted.minusOneGoal,
        minusTwoGoal: omitted.minusTwoGoal,
        plusOneGoal: omitted.plusOneGoal,
        plusTwoGoal: omitted.plusTwoGoal,
      });

      if (values.name !== self.gas[0].name) {
        trackGASGoalNameChanged({
          memberId: self.id,
          coachId: me?.id,
          organizationName: self.organization?.name,
          goalName: values.name,
        });
      }

      const updates = self.gas[0]?.progressUpdates || [];
      result.progressUpdates = updates;

      self.gas = [result];

      return result;
    }),
  }),
};
