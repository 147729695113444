import Helmet from 'react-helmet';
import Config from '../../Config';

const USERSNAP_PROJECT_API_KEY = Config.userSnap.apiKey;

const UserSnap = () => (
  <Helmet>
    <script defer type="text/javascript">
      {`
      window.onUsersnapCXLoad = function(api) {
        api.init()
      }
      var script = document.createElement('script');
      script.defer = 1;
      script.src = 'https://widget.usersnap.com/load/${USERSNAP_PROJECT_API_KEY}?onload=onUsersnapCXLoad';
      document.getElementsByTagName('head')[0].appendChild(script);
    `}
    </script>
  </Helmet>
);

export default UserSnap;
