import md5 from 'md5';

const hashes = {};

export function getStringHash(input: string) {
  if (!hashes[input]) {
    hashes[input] = md5(input);
  }
  return hashes[input];
}
