// @ts-nocheck
import { types } from 'mobx-state-tree';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

const Method = types
  .model('Method', {
    id: types.identifierNumber,
    type: types.enumeration('Type', ['tel', 'https', 'http', 'itms', 'market']),
    value: types.string,
    vendorId: types.number,
  });

const Vendor = types
  .model('Vendor', {
    contactMethods: types.array(Method),
    description: types.string,
    eligibilityInfo: types.maybe(types.string),
    hoursOfOperation: types.maybe(types.string),
    id: types.identifierNumber,
    logoUrl: types.string,
    name: types.string,
    organizationId: types.number,
  })
  .views((self) => ({
    get formatedPhoneNumber() {
      const number = self.contactMethods.find((o) => o.type === 'tel');
      if (!number) {
        return null;
      }
      const phone = parsePhoneNumberFromString(number.value, 'US');
      return phone.formatNational();
    },
  }));

export const Tag = types
  .model('Tag', {
    name: types.string,
  });

const Service = types
  .model('Service', {
    description: types.string,
    eligibilityInfo: types.maybe(types.string),
    hoursOfOperation: types.maybe(types.string),
    id: types.identifierNumber,
    instructions: types.maybe(types.string),
    name: types.string,
    shared: types.optional(types.boolean, false),
    tags: types.array(Tag),
    vendor: Vendor,
    vendorId: types.number,
    whatToExpect: types.maybe(types.string),
  })
  .actions((self) => ({
    setShared(bool) {
      self.shared = bool;
    },
  }));

export default Service;
