import { onSnapshot } from 'mobx-state-tree';
import { Logger } from '@utils/logger';

import { IS_PROD } from '../Config';

import Application from './Application';
import { reportsInitialModelData } from './Reports/Reports';

const Store = Application.create({
  chat: {
    input: {
      value: '',
    },
  },
  drafts: {},
  memberSearch: {
    meta: {},
  },

  reports: {
    ...reportsInitialModelData,
  },
});

onSnapshot(Store, (snapshot) => !IS_PROD && Logger.log('[Store]: ', snapshot));

// @ts-expect-error
window.Store = Store;

// TODO: export a selector function to 'query' slices of the RootStore
export default Store;
