import getUrls, { type Options } from 'get-urls';
import urlRegexSafe from 'url-regex-safe';

/**
 *
 * @param text string
 * @returns boolean
 *
 * returns whether the text contains a URL
 */

export function includesURL(text: string) {
  return urlRegexSafe().test(text);
}

/**
 *
 * @param text string
 * @returns array of urls
 *
 * this function returns an array of urls found, repeated urls will be included
 */
export function getUrlsFromString(text: string): RegExpMatchArray {
  return text.match(urlRegexSafe()) || [];
}

/**
 *
 * @param text string
 * @param options Options
 * @returns Set of urls found
 *
 * this function returns a normalized version of urls
 * by doing this, repeated versions of the same url written in a different ways wont be repeated
 *
 * ex: facebook.com and https://www.facebook.com
 *
 * will both be detected and returned as https://facebook.com
 *
 * check get-urls library options for more details
 */
export function getNormalizedUrlsFromString(text: string, options?: Options) {
  return getUrls(text, options);
}
