import {
  types,
  getRoot,
  SnapshotIn,
  IMSTArray,
  IReferenceType,
  Instance,
} from 'mobx-state-tree';
import Concern from '@stores/Member/AgendaMapping/Concern';
import Questionnaire from '@stores/Questionnaire';
import Service from '@stores/Service';
import {
  trackAgendaMappingAttachedToChat,
  trackEligibilityReminderAddedToChat,
} from '@utils/analytics';
import { ELIGIBILITY_FORM_CHAT_TEMPLATE } from '@utils/constants';
import { getNormalizedUrlsFromString } from '@utils/regex';
import { MonitoringService } from '@services';
import { IRootStore } from './ApplicationInterfaces';
import {
  ILinkPreview,
  LinkPreviewState,
} from './Application/LinkPreviewsMixin/LinksPreview';

export type Pill = {
  id: number;
  text: string;
};

const Link = types.model({
  url: types.string,
  // this 'ommitPreview' boolean indicates if the coach
  // decided to ommit the link preview for this link or not
  ommitPreview: types.boolean,
});

// timeout id used to debounce the execution of detectUrlsInChatInput
let debuncedUrlDetectionId: ReturnType<typeof setTimeout>;

// TODO: this is repeated in linkpreviewmixin due to circular dep. Fix in the future
function isFileURL(url: string){
  try{
    const gDocRegex = /docs\.google/;
    const isGDoc = gDocRegex.test(url);
    if (isGDoc){
      return true;
    }
    // this list is by no means comprehensive, but these should cover most of them
    const imageExtensions = ['jpg', 'gif', 'png', 'svg', 'webp'];
    const videoExtensions = ['mp4', 'mkv', 'webm', 'avi', 'mov'];
    const audioExtensions = ['mp3', 'wav', 'aac', 'flac', 'wma', 'm4a'];
    const dataExtensions = ['pdf', 'csv', 'txt', 'yaml', 'json'];
    const extensions = imageExtensions.concat(videoExtensions, audioExtensions, dataExtensions);
    // if a file extension exists, it should be after the last '.' in the pathname
    const parsedURL = new URL(url);
    // make sure the hostname has a domain extension, we don't want https://google to match
    const urlHost = parsedURL.hostname.split('.');
    if (urlHost.length > 1) {
      const path = parsedURL.pathname;
      const splitByDot = path.split('.');
      const extension = splitByDot.length > 1 ? splitByDot.pop()!.trim() : false;
      return (extension && extensions.includes(extension));
    }
    // fail if hostname has no domain extension
    return true;
  }
  catch(error){
    console.error('Invalid URL');
    return true;
  }
}

const ChatInputModel = types
  .model('ChatInput', {
    questionnaires: types.array(types.number),
    resources: types.array(types.number),
    concerns: types.array(types.reference(Concern)),
    value: types.string,
    eligibilityForm: false,
    links: types.array(Link),
  })
  .views((self) => ({
    get rowCount() {
      const { length } = self.resources;

      return length <= 2 ? 1 : Math.max(1, 2 * Math.round(length / 2));
    },

    get questionnairePills() {
      const { member } = getRoot<IRootStore>(self);

      if (!member) {
        return [] as Pill[];
      }

      return self.questionnaires.reduce((acum, questionnaireId) => {
        const questionnaire = member.questionnaires.find(
          ({ id }: Instance<typeof Questionnaire>) => id === questionnaireId,
        );

        if (questionnaire) {
          acum.push({
            id: questionnaire.id,
            text: questionnaire.name,
          } as Pill);
        }

        return acum;
      }, [] as Pill[]);
    },

    get linksUrls() {
      return self.links.map((linkPreview) => linkPreview.url);
    },

    get resourcePills() {
      const { member } = getRoot<IRootStore>(self);
      if (!member) {
        return [] as Pill[];
      }

      const { services } = member.resources;

      return self.resources.reduce((acum, resourceId) => {
        const resource = services.find(
          (service: Instance<typeof Service>) =>
            service && service.id === resourceId,
        );

        if (resource) {
          acum.push({
            id: resource.id,
            text: resource.name,
          } as Pill);
        }
        return acum;
      }, [] as Pill[]);
    },
    get linkPreviewsRendered() {
      const { findUrlData } = getRoot<IRootStore>(self);
      return self.links.reduce((acum, link) => {
        if (link.ommitPreview) return acum;

        const urlInRootStore = findUrlData(link.url);
        if (!urlInRootStore || urlInRootStore.state === LinkPreviewState.ERROR)
          return acum;

        acum.push(urlInRootStore);
        return acum;
      }, [] as Array<ILinkPreview>);
    },

    get linkPreviewsUrlsNotOmmited() {
      return this.linkPreviewsRendered.map((link) => link.url);
    },

    get linkPreviewsLoading() {
      return (this.linkPreviewsRendered.length && this.linkPreviewsRendered.filter((link: { state: LinkPreviewState }) => link.state === LinkPreviewState.LOADING ).length);
    }
  }))
  .actions((self) => ({
    setCoachIsTyping(isTyping: boolean) {
      const application = getRoot<IRootStore>(self);
      const sendbirdClient = application.getSendBird();

      try {
        sendbirdClient.setTyping(isTyping);
      } catch (error) {
        MonitoringService.addLog({
          message: '[MST][ChatInput] setCoachIsTyping error',
          logGroup: '[MST][ChatInput] setCoachIsTyping',
          logRecordType: 'SetCoachIsTypingError',
          error: error as Error,
          details: {
            isTyping,
          },
        });
      }
    },
  }))
  .actions((self) => ({
    clear() {
      self.value = '';
      self.questionnaires.clear();
      self.resources.clear();
      self.concerns.clear();
      self.links.clear();
      self.eligibilityForm = false;
    },

    setValue: (value: string) => {
      if (value.length > 0) {
        self.setCoachIsTyping(true);
      } else if (self.value.length > 0) {
        self.setCoachIsTyping(false);
      }
      self.value = value;
      return value;
    },
    removeOldLinkPreviews(urlsSet: Set<string>) {
      const newLinks = self.links.filter((linkPreview) =>
        urlsSet.has(linkPreview.url),
      );

      self.links.replace(newLinks);
    },

    getNewUrls(urlsSet: Set<string>) {
      return Array.from(urlsSet).filter(
        (urlInChat) => !self.linksUrls.includes(urlInChat),
      );
    },

    addLinkPreview(linkPreview: SnapshotIn<typeof Link>) {
      self.links.push(linkPreview);
    },

    detectUrlsInChatInput(text: string) {
      clearTimeout(debuncedUrlDetectionId);

      // needed to preserve scope inside setTimeout callback
      const that = this;

      debuncedUrlDetectionId = setTimeout(() => {
        const { addPreviewUrl } = getRoot(self) as IRootStore;
        // get set of urls from chat input
        const urlsSet = getNormalizedUrlsFromString(text);

        // remove url previews from links that no longer exist in the text
        that.removeOldLinkPreviews(urlsSet);

        // new urls introduced since last change
        const newUrls = that.getNewUrls(urlsSet);


        // fetch new urls data in root store and add them to the input urls state
        newUrls.forEach((newUrl) => {            
            if(!(isFileURL(newUrl))){
              addPreviewUrl(newUrl);
              that.addLinkPreview({ url: newUrl, ommitPreview: false });
            }
        });
      }, 500);
    },
  }))
  .actions((self) => ({
    ommitLinkPreview(url: string) {
      const linkIndex = self.links.findIndex((link) => link.url === url);

      if (linkIndex < 0) return;

      self.links[linkIndex].ommitPreview = true;
    },

    attachQuestionnaire: ({ id }: { id: number }) => {
      // we are now only allowing 1 questionnaire added at a time
      self.questionnaires.replace([id]);
    },

    attachEligibilityForm: () => {
      const { member, me } = getRoot(self);

      // we're only allowing 1 kind of button (resource, questionnaire, or concern)
      self.clear();

      self.value = ELIGIBILITY_FORM_CHAT_TEMPLATE;
      self.eligibilityForm = true;

      trackEligibilityReminderAddedToChat({ member, coach: me });
    },

    attachConcerns: (concerns: IMSTArray<IReferenceType<typeof Concern>>) => {
      const { member, me } = getRoot(self);

      // we're only allowing 1 kind of button (resource, questionnaire, or concern)
      self.clear();

      self.concerns.replace(concerns);
      self.value = `${member.name}, as we’ve been chatting, I’ve heard you mention a number of concerns or areas where you’d like to make a change. I’ve listed them below in no particular order. Have I captured everything? What, if anything, is missing?`;

      const analytics = {
        concerns: concerns.map((t) => t.text),
        coachName: me.name,
        memberId: member.id,
      };
      trackAgendaMappingAttachedToChat(analytics);
    },

    attachResource: (resource: { answer: string; id: number }) => {
      const { answer } = resource;
      const isFAQ = Boolean(answer);

      if (isFAQ) {
        return self.setValue(answer);
      }

      const resources = self.resources.slice();

      resources.push(resource.id);
      self.resources.replace(Array.from(new Set(resources)));

      return resource;
    },

    removeAttachedQuestionnaire: (id: number) => {
      const questionnaires = self.questionnaires.slice();
      const resourceIndex = questionnaires.findIndex(
        (resourceId) => resourceId === id,
      );

      if (resourceIndex > -1) {
        questionnaires.splice(resourceIndex, 1);
        self.questionnaires.replace(questionnaires);
      }
    },

    removeEligibilityForm: () => {
      self.eligibilityForm = false;
      self.value = '';
    },

    removeAttachedConcerns: () => {
      self.concerns.clear();
      self.value = '';
    },

    removeAttachedResource: (id: number) => {
      const resources = self.resources.slice();
      const resourceIndex = resources.findIndex(
        (resourceId) => resourceId === id,
      );

      if (resourceIndex > -1) {
        resources.splice(resourceIndex, 1);
        self.resources.replace(resources);
      }
    },

    setInitialValue: () => {
      const { drafts } = getRoot(self);

      const { draftMessage } = drafts;

      if (draftMessage) {
        self.value = draftMessage;
        self.detectUrlsInChatInput(draftMessage);
      }
    },
  }));

export default ChatInputModel;
