import { MonitoringService } from '@services';
import Socket from '@services/Socket';
import { LogLevel, SiblySDK } from '@sibly/sibly-sdk-browser';

import { IRootStore } from '@stores/ApplicationInterfaces';
import { Logger } from '@utils/logger';
import { runTitleNotification } from '@utils/runTitleNotification';

import Config from '../../Config';

export type SocketMixinActions = {
  initializeSocket(): void;
};

const urlProvider = async (): Promise<string> => {
  const { idToken } = await SiblySDK.Authorization.getSessionTokens();
  return `${Config.socket.host}?Authorization=${idToken}`;
};

const SocketMixin = {
  actions: (self: IRootStore) => ({
    initializeSocket() {
      const socket = new Socket(urlProvider, WebSocket);

      socket.on('member.grab', async (payload) => {
        SiblySDK.Monitoring.addBreadcrumb({
          message: 'member.grab',
          category: 'Socket Event',
        });

        Logger.log(`Hades socket event: member.grab, memberId: ${payload.id}`);
    
        try {
          const member = await self.getMember({ memberId: Number(payload.id) });

          if (!member) {
            throw new Error(`failed to get member ${payload.id}`);
          }

          Logger.log(`setting member to coach ${payload.coachId}`);
          member.setCoach(payload.coachId, payload.assignedAt);
        } catch (e) {
          MonitoringService.addLog({
            message: '[MST][SocketMixin] member.grab error',
            logGroup: 'SocketMixin',
            logRecordType: 'MemberGrabError',
            error: e as Error,
            details: {
              memberId: payload.id,
              payloadCoachId: payload.coachId,
            },
          });
        }
      });

      socket.on('member.release', async (payload) => {
        SiblySDK.Monitoring.addBreadcrumb({
          message: 'member.release',
          category: 'Socket Event',
        });

        Logger.log('Hades socket event: member.release');
        
        try {
          const member = await self.getMember({ memberId: Number(payload.id) });

          if (!member) {
            throw new Error(`failed to get member ${payload.id}`);
          }

          Logger.log(`releasing member ${member.id}`);
          member.setCoach(undefined, payload.assignedAt);
        } catch (e) {
          MonitoringService.addLog({
            message: '[MST][SocketMixin] member.release error',
            logGroup: 'SocketMixin',
            logRecordType: 'MemberReleaseError',
            error: e as Error,
            details: {
              memberId: payload.id,
            },
          });
        }
      });

      socket.on('member.updateAcute', async (payload) => {
        const { acute, memberId } = payload;

        SiblySDK.Monitoring.addBreadcrumb({
          message: `Hades socket event: member.updateAcute memberId=${memberId}`,
          category: 'Socket Event',
        });

        Logger.log(`Hades socket event: member.updateAcute memberId=${memberId}`);

        try {
          const member = await self.getMember({ memberId: Number(memberId) });

          if (!member) {
            throw new Error(`failed to get member ${memberId}`);
          }

          member.setAcute(acute);
        } catch (e) {
          MonitoringService.addLog({
            message: '[MST][SocketMixin] member.updateAcute error',
            logGroup: 'SocketMixin',
            logRecordType: 'MemberUpdateAcuteError',
            error: e as Error,
            details: {
              memberId,
            },
          });

          self.showAlert('There was an error updating member acute status.', 'danger', e);
        }
      });

      socket.on('member.new', async (payload) => {
        try {
          const { memberId } = payload;
          Logger.log('Hades socket event: member.new');

          const logMessage = `Alert New Member! id=${memberId}`;
          Logger.log(logMessage);
          SiblySDK.Monitoring.addBreadcrumb({
            message: logMessage,
            category: 'Socket Event',
          });

          const member = await self.getMember({ memberId: Number(memberId) });

          if (!member) {
            Logger.error(`Failed to get new member ${memberId}`);
            MonitoringService.addLog({
              message: '[MST][SocketMixin] member.new error: Failed to get new member',
              logGroup: 'SocketMixin',
              logRecordType: 'MemberNewError',
              error: new Error('Failed to get member'),
              details: {
                memberId,
              },
            });
            return;
          }

          member.show();
          member.membership.setNewMemberAlertSentAt(new Date());
          // @ts-ignore 
          self.setNewMemberAlert(true);
          self.playUnassignedAudio();
          runTitleNotification('🔔 A new Sibly member is here');

          MonitoringService.addLog({
            level: LogLevel.INFO,
            message: '[MST][SocketMixin] Alert New Member!',
            logGroup: 'SocketMixin',
            logRecordType: 'NewMemberAlert',
            details: {
              memberId,
            },
          });

          self.incrementNewMemberAlertCount();
        } catch (e) {
          self.showAlert(
            'There was an error adding a new member.',
            'danger',
            e,
          );
        }
      });

      socket.on('member.remove', async (payload) => {
        Logger.log('Hades socket event: member.remove', payload.memberIds);
        self.removeMembers(payload.memberIds);
      });

      socket.on('message.like', async (payload) => {
        const { liked, memberId, messageId } = payload;
        Logger.log(`Hades socket event: message.like memberId=${memberId} messageId=${messageId}`);

        const member = self.members.get(memberId);
        if (!member) return; // Why does this just return?

        member.likedSiblyMessage(messageId, liked);
      });

      socket.on('channel.update', async (channel) => {
        try {
          Logger.log(`Hades socket event: channel.update memberId=${channel.memberId}`);

          SiblySDK.Monitoring.addBreadcrumb({
            message: `Hades socket event: channel.update memberId=${channel.memberId}`,
            category: 'Socket Event',
          });

          const member = await self.getMember({
            memberId: Number(channel.memberId),
          });

          if (!member) {
            throw new Error(`failed to get member ${channel.memberId}`);
          }

          Logger.log(`updating last readAt for channel for member ${channel.memberId} with value ${channel.lastReadAt}`);
          member.channel.setLastReadAt(channel.lastReadAt);
        } catch (e) {
          self.showAlert(
            'There was an error updating member channel.',
            'danger',
            e,
          );
        }
      });

      socket.on('member.updateMessageCount', async ({ memberId, count }) => {
        try {
          Logger.log(`Hades socket event: member.updateMessageCount memberId=${memberId} count=${count}`);

          const member = await self.getMember({ memberId: Number(memberId) });

          if (!member) {
            Logger.error(`failed to get member ${memberId} in member.updateMessageCount`);
            throw new Error(`failed to get member ${memberId}`);
          }

          Logger.log(`setting messageCountLast15Min for ${memberId} with value ${count}, prevValue ${member.messageCountLast15Min}`);
          member.setMessageCountLast15Min(count);
        } catch (e) {
          SiblySDK.Monitoring.addBreadcrumb({
            message: `There was an error updating member message count for memberId: ${memberId} count: ${count}.`,
            category: 'Socket Event',
          });
          self.showAlert(
            'There was an error updating member message count.',
            'danger',
            e,
          );
        }
      });

      socket.on('member.questionnaireDue', ({ memberId, questionnaireId }) => {
        Logger.log(`Hades socket event: member.questionnaireDue memberId=${memberId}`);

        if (!self.member || self.member.id !== memberId) return;

        self.member.setQuestionnaireDueInfo({
          isDueForQuestionnaire: true,
          questionnaireId,
        });
      });

      socket.on('member.fieldsAnswered', ({ memberId }) => {
        Logger.log(`Hades socket event: member.fieldsAnswered memberId=${memberId}`);

        const member = self.me?.members.find((m: any) => m.id === memberId);

        Logger.log('member.fieldsAnswered member', member);
        if (member) {
          self.showAlert('Eligibility Form Complete');
        }
      });

      socket.on('pong', (pongMessage) => {
        const { browserTabId, userId, connectionId } = pongMessage;
        const logMessage = `WebSocket:client:pong PONG received from server, coachId=${userId} connectionId=${connectionId} browserTabId=${browserTabId}`;
        Logger.log(logMessage);
        SiblySDK.Monitoring.addBreadcrumb({
          message: logMessage,
          category: 'Socket Event',
        });
      });

      self.socket = socket;
    },
  }),
};

export default SocketMixin;
