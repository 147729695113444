// @ts-nocheck
import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { applySnapshot, flow, types } from 'mobx-state-tree';
import moment from 'moment-timezone';
import { v4 as UUIDv4 } from 'uuid';

import withStorage from '@utils/mst/withStorage';
import { identify, setAnalyticsUser } from '@utils/analytics';
import { APIError } from '@utils/customErrors';
import { getSiblyClientHeader } from '@utils/userAgentData';

import { SendBird } from '@services/SendBird';
import FullStoryService from '@services/FullStory';
import Hades from '../services/Hades';
import Socket from '../services/Socket';

import {
  reportsInitialModelData,
  reportsInitialVolatileData,
} from './Reports/Reports';
import Config from '../Config';

import Chat from './Chat';
import Draft from './Draft';
import AccessCodeMixin from './Application/AccessCodeMixin';
import AlertMixin from './Application/AlertMixin';
import CoachConversationsMixin from './Application/CoachConversationsMixin';
import { NotificationWorkaroundMixin } from './Application/NotificationWorkaroundMixin';
import OrganizationMixin from './Application/OrganizationMixin';
import SendbirdMixin from './Application/SendbirdMixin';
import LinkPreviewsMixin from './Application/LinkPreviewsMixin';
import MemberSearch from './MemberSearch';

import {
  AccessCodesModel,
  AlertModel,
  BootstrappedModel,
  CoachConversationsModel,
  CoachesModel,
  MemberModel,
  MembersModel,
  MeModel,
  OrganizationsModel,
  PlaySoundNotificationsModel,
  QuestionnaireDontSendReasonsModel,
  ReportsModel,
  RequestsModel,
  ConcernsColorsModel,
  LinksPreviewDataModel,
} from './ApplicationInterfaces';
import AuthMixin from './Application/AuthMixin';
import SocketMixin from './Application/SocketMixin';
import AudioMixin from './Application/AudioMixin';
import MembersMixin from './Application/MembersMixin';
import CoachMixin from './Application/CoachMixin';

export default types
  .model('Application', {
    accessCodes: AccessCodesModel,
    bootstrapped: BootstrappedModel,
    chat: Chat,
    coaches: CoachesModel,
    members: MembersModel,

    // Currently selected member
    member: MemberModel,

    organizations: OrganizationsModel,
    // Currently logged in coach
    me: MeModel,
    playSoundNotifications: PlaySoundNotificationsModel,

    drafts: Draft,
    // visibility is determined by self.alert being an instance of Alert
    alert: AlertModel,
    requests: RequestsModel,
    memberSearch: MemberSearch,
    coachConversations: CoachConversationsModel,
    reports: ReportsModel,
    concernsColors: ConcernsColorsModel,
    questionnaireDontSendReasons: QuestionnaireDontSendReasonsModel,
    linksPreviewData: LinksPreviewDataModel,
  })
  .volatile(AlertMixin.volatile)
  .volatile(NotificationWorkaroundMixin.volatile)
  .volatile<{
    dashboardIsLoading: boolean;
    hades: Hades | undefined;
    isShowingLegalAgreement: boolean;
    isShowingConfirmSignInWithNewPasswordModal: boolean;
    isShowingConfirmEmailModal: boolean;
    nextMemberId: number | undefined;
    sendbird: SendBird | undefined;
    showNewMemberAlert: boolean;
    showUnsavedNotesModal: boolean;
    socket: Socket | undefined;
    timers: Map<number, ReturnType<typeof setTimeout>>;
    intervalForElapsedTimeUpdate: number;
  }>(() => ({
    dashboardIsLoading: false,
    hades: undefined,
    isShowingLegalAgreement: false,
    isShowingConfirmSignInWithNewPasswordModal: false,
    isShowingConfirmEmailModal: false,
    nextMemberId: undefined,
    sendbird: undefined,
    showNewMemberAlert: false,
    showUnsavedNotesModal: false,
    socket: undefined,
    timers: new Map(),
    intervalForElapsedTimeUpdate: types.optional(types.number, 0),
  }))
  .actions(AccessCodeMixin.actions)
  .actions(AlertMixin.actions)
  .actions(OrganizationMixin.actions)
  .actions(CoachConversationsMixin.actions)
  .actions(NotificationWorkaroundMixin.actions)
  .actions(SendbirdMixin.actions)
  .actions(AuthMixin.actions)
  .actions(SocketMixin.actions)
  .actions(AudioMixin.actions)
  .actions(MembersMixin.actions)
  .actions(CoachMixin.actions)
  .actions(LinkPreviewsMixin.actions)
  .actions((self) => ({
    updateMembersElapsedTimeSinceLastMessage() {
      self.members.forEach((member) => {
        member.channel.updateElapsedTimeSinceLastMemberMessage();
      });
    },
  }))
  .actions((self) => ({
    setIntervalForElapsedTimeUpdate() {
      self.updateMembersElapsedTimeSinceLastMessage();
      self.intervalForElapsedTimeUpdate = setInterval(
        self.updateMembersElapsedTimeSinceLastMessage,
        10 * 1000,
      ); // Update the timestamp every 10 seconds
    },
    clearIntervalForElapsedTimeUpdate() {
      clearInterval(self.intervalForElapsedTimeUpdate);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.hades = self.createHades();
    },

    setDashboardIsLoading(bool: boolean) {
      self.dashboardIsLoading = bool;
    },

    // runs only after authentication
    // eslint-disable-next-line consistent-return
    initializeDashboard: flow(function* initializeDashboard() {
      self.connectSendbird();
      self.setDashboardIsLoading(true);

      yield self.getMe();

      self.initializeSocket();

      const response = yield Promise.all([
        self.hades.request('member.list').catch((error) => {
          throw new APIError(
            'Members data failed to load. Please try again later.',
            error,
          );
        }),
        self.hades.request('coach.list').catch((error) => {
          throw new APIError(
            'Coaches data failed to load. Please try again later.',
            error,
          );
        }),
        self.hades.request('accessCode.list').catch((error) => {
          throw new APIError(
            'Access Codes data failed to load. Please try again later.',
            error,
          );
        }),
        self.hades.request('organization.list').catch((error) => {
          throw new APIError(
            'Organizations data failed to load. Please try again later.',
            error,
          );
        }),
        self.hades.request('agendaConcern.colorList').catch((error) => {
          throw new APIError(
            'Agenda Concerns colors failed to load. Please try again later.',
            error,
          );
        }),
        self.hades
          .request('questionnaireDoNotSendReason.list')
          .catch((error) => {
            throw new APIError(
              'Questionnaire notifications data failed to load. Please try again later.',
              error,
            );
          }),
      ]);

      const { members, coaches, accessCodes, organizations, concernsColors } =
        Object.assign({}, ...response);
      const questionnairesDontSendReasons = response[5];

      self.addMembers(members);
      self.addCoaches(coaches);
      self.addOrganizations(organizations);
      self.addAccessCodes(accessCodes);
      self.addConcernsColors(concernsColors);
      self.addQuestionnaireDontSendReasons(questionnairesDontSendReasons);

      self.setDashboardIsLoading(false);

      // identify the coach
      identify(self.me);

      FullStoryService.identify(self.me.id.toString());

      // start the timer to polling for decrementing message counts
      setInterval(self.getMemberMessages, 1000 * 60); // 1 minute

      // start the timer to polling for notifications (new member alert + messages)
      self.startCheckForNotifications();

      // start interval to update the elapsed time since last member's message
      self.setIntervalForElapsedTimeUpdate();

      return {
        members,
        coaches,
        accessCodes,
        organizations,
      };
    }),

    async afterBootstrap() {
      try {
        const { idToken } = await SiblySDK.Authorization.getSessionTokens();
        if (idToken) {
          await self.onAuthenticated();
        }

        if (self.me) {
          await self.me.sync();
          setAnalyticsUser(self.me);
        }

        self.chat.input.setValue('');
        self.drafts.clearMessages();
      } catch (e) {
        if (e instanceof APIError) {
          self.showAlert(e.message, 'danger', e);
          self.logout();
        } else {
          self.showAlert(
            'there was an error loading dashboard resources',
            'danger',
            e,
          );
        }
      }
    },

    setBootstrapped(bool) {
      self.bootstrapped = bool;
    },

    addRequest(id) {
      return self.requests.set(id, { id });
    },

    addOrganizations(items = []) {
      const orgs = items.map((org) => ({
        ...org,
        hasResources: org.providesBenefitServices,
      }));
      self.organizations = self.organizations.merge([
        ...orgs.map((org) => [org.id, org]),
      ]);
    },

    createHades() {
      const hades = new Hades(Config.hades.host);

      hades.client.interceptors.request.use(async (request) => {
        const { data = [], url } = request;
        [].concat(data).forEach(({ method }) => self.addRequest(method ?? url));

        const { idToken } = await SiblySDK.Authorization.getSessionTokens();

        if (idToken) {
          request.headers.Authorization = idToken;
        }

        request.headers['sibly-client'] = getSiblyClientHeader();
        request.headers['sibly-client-timezone'] = moment.tz.guess();
        request.headers['sibly-client-localtime'] = moment().format('YYYY-MM-DD HH:mm:ss');
        request.headers['sibly-client-utctime'] = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        request.headers['x-amzn-RequestId'] = data?.id ?? UUIDv4();

        return Promise.resolve(request);
      }, undefined);

      hades.client.interceptors.response.use(
        // executes if response status === 2xx, before handling in Hades.request
        (response) => {
          const { config, data } = response;
          const hash = JSON.parse(config.data) || [];

          [].concat(hash).forEach(({ id, method }) => {
            const request = self.requests.get(method ?? config.url);
            const result = [].concat(data).find((item) => item.id === id);

            request.process(
              result?.error ? 'ERROR' : 'RESOLVED',
              result?.error?.message,
            );
          });

          return Promise.resolve(response);
        },
        // executes if response status !== 2xx , before handling in Hades.request
        async (error) => {
          const jsonError = error.toJSON();
          const { config, message = '' } = jsonError;
          const hash = JSON.parse(config.data);

          [].concat(hash).forEach(({ method }) => {
            const request = self.requests.get(method ?? error?.config?.url);
            return request.process('REJECTED', message);
          });

          return Promise.reject(error);
        },
      );

      return hades;
    },

    setNewMemberAlert(showAlert: boolean): void {
      self.showNewMemberAlert = showAlert;
    },

    setShowUnsavedNotesModal(bool) {
      self.showUnsavedNotesModal = bool;
    },

    addConcernsColors(colors) {
      self.concernsColors = colors;
    },

    addQuestionnaireDontSendReasons(questionnairesDontSendReasons) {
      self.questionnaireDontSendReasons = questionnairesDontSendReasons;
    },

    getConcernColorById(colorId) {
      return self.concernsColors.find(
        (concernColor) => concernColor.id === colorId,
      );
    },

    resetReportsData() {
      applySnapshot(self.reports, reportsInitialModelData);
      Object.keys(reportsInitialVolatileData).forEach((key) => {
        self.reports[key] = reportsInitialVolatileData[key];
      });
    },
  }))
  .views(AlertMixin.views)
  .views(MembersMixin.views)
  .views(CoachMixin.views)
  .views((self) => ({
    getOrganizationIdByName(name) {
      return Array.from(self.organizations.values()).find(
        (org) => org.name === name,
      ).id;
    },
    get activeOrganizations() {
      return Array
        .from(self.organizations.values())
        .filter(({ isActive } ) => isActive);
    },

    get concernColorsByRankMap() {
      return new Map(
        self.concernsColors.map((concernColor) => [
          concernColor.orderRank,
          concernColor,
        ]),
      );
    },
  }))
  .extend(
    withStorage({
      except: [
        'bootstrapped',
        'member',
        'members',
        'requests',
        'reports',
        'chat',
        'alert',
        'linksPreviewData',
      ],
    }),
  );
