// @ts-nocheck
export default (path) => (
  path.map((track) => ({
    ...track,
    milestones: track.milestones.map((milestone) => ({
      ...milestone,
      activities: milestone.activities.map((activity) => ({
        ...activity,
        completed: activity.status ? activity.status.completed : false,
      })),
    })),
  }))
);
