import Hades from '@services/Hades';
import { IRootStore } from '@stores/ApplicationInterfaces';
import Coach from '@stores/Coach';
import Member from '@stores/Member';
import Questionnaire from '@stores/Questionnaire';
import {
  trackQuestionnaireNOTSentFromAlertBanner,
  trackQuestionnaireSentFromAlertBanner,
} from '@utils/analytics';
import { NPS_QUESTIONNAIRE_ID } from '@utils/constants';
import {
  flow,
  getParent,
  getRoot,
  IAnyStateTreeNode,
  IMSTArray,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree';

export const QuestionnaireDueInfo = types
  .model({
    isDueForQuestionnaire: types.boolean,

    // cant make it a reference to questionnaire model,
    // because nps survey is not stored as a questionnaire in the store
    questionnaireId: types.maybe(types.number),
  })
  .views((self) => ({
    get isNPSSurveyNotification() {
      return self.questionnaireId === NPS_QUESTIONNAIRE_ID;
    },

    get questionnaire() {
      const member = getParent(self);
      // @ts-ignore
      return (member.questionnaires as IMSTArray<typeof Questionnaire>).find(
        (questionnaire) => questionnaire.id === self.questionnaireId,
      );
    },

    get questionnaireName() {
      return this.isNPSSurveyNotification
        ? 'NPS Survey'
        : this.questionnaire?.name;
    },
  }));

export default {
  attributes: {
    questionnaireDueInfo: types.maybe(QuestionnaireDueInfo),
  },
  actions: (self: IAnyStateTreeNode) => ({
    dontSendDueQuestionnaire: flow(function* dontSendDueQuestionnaire(
      reasonId: number,
    ) {
      const root = getRoot<IRootStore>(self);
      const hades = root.hades as Hades;
      if (!self.questionnaireDueInfo?.questionnaireId) {
        throw new Error('questionnaire is not in store');
      }

      yield hades.request('questionnaireDue.doNotSend', {
        memberId: self.id,
        questionnaireId: self.questionnaireDueInfo?.questionnaireId,
        doNotSendReasonId: reasonId,
      });

      // @ts-ignore
      self.questionnaireDueInfo.isDueForQuestionnaire = false;
    }),

    trackSentQuestionnaire(questionnaireId?: number) {
      if (
        questionnaireId &&
        self.questionnaireDueInfo?.questionnaireId === questionnaireId
      ) {
        self.questionnaireDueInfo.isDueForQuestionnaire = false;
        self.trackSendQuestionnaireFromNotification();
      }
    },

    setQuestionnaireDueInfo(
      questionnaireDueInfo: SnapshotIn<typeof QuestionnaireDueInfo>,
    ) {
      self.questionnaireDueInfo = questionnaireDueInfo;
    },

    trackSendQuestionnaireFromNotification() {
      const root = getRoot<IRootStore>(self);

      const member = root.member as Instance<typeof Member>;
      const me = root.me as Instance<typeof Coach>;

      trackQuestionnaireSentFromAlertBanner({
        memberId: member.id,
        coachId: me.id,
        organizationName: member.organization.name,
        questionnaireName: self.questionnaireDueInfo.questionnaireName,
      });
    },

    trackQuestionnaireNotSentFromNotification() {
      const root = getRoot<IRootStore>(self);

      const member = root.member as Instance<typeof Member>;
      const me = root.me as Instance<typeof Coach>;

      trackQuestionnaireNOTSentFromAlertBanner({
        memberId: member.id,
        coachId: me.id,
        organizationName: member.organization.name,
        questionnaireName: self.questionnaireDueInfo.questionnaireName,
      });
    },
  }),
};
