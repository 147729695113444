// @ts-nocheck
import { types } from 'mobx-state-tree';

export const Tag = types.model('Tag', {
  name: types.string,
});

const BenefitCategory = types.model('BenefitCategory', {
  color: types.string,
  id: types.identifierNumber,
  name: types.string,
  sort: types.number,
});

export default types.model('FAQ', {
  answer: types.string,
  benefitCategory: types.maybe(BenefitCategory),
  id: types.identifierNumber,
  organizationId: types.number,
  question: types.string,
  tags: types.array(Tag),
});
