export interface DbMessage {
  coachId?: number;
  createdAt: string;
  data?: any;
  id: number;
  memberLiked?: boolean;
  sendbirdId?: number;
  text?: string;
}

const formatSender = (dbMessage: DbMessage, memberChatId: string): string => {
  const {
    coachId,
    data,
    sendbirdId,
  } = dbMessage;

  if (coachId === 0) {
    return `${sendbirdId}-bot`;
  }

  if (coachId || data.followUp || data.sam) {
    return `${sendbirdId}-sibly`;
  }

  return memberChatId;
};

/**
 * Format Sibly DB message to Message model format
 */
export const formatDbMessage = (dbMessage: DbMessage, memberChatId: string) => {
  const {
    createdAt,
    data,
    id,
    memberLiked,
    text,
  } = dbMessage;

  return {
    content: text || '',
    data,
    id,
    isLiked: memberLiked,
    senderId: formatSender(dbMessage, memberChatId),
    timestamp: createdAt,
  };
};
