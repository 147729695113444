import { SplitFactory } from '@splitsoftware/splitio-react';
import { Logger } from '@utils/logger';
import Config from './Config';

type SplitConfig = {
  core: {
    authorizationKey: string;
    key: string;
  };
}

const getSplitConfig = (): SplitConfig | undefined => (Config.split.key ? ({
  core: {
    authorizationKey: Config.split.key,
    key: 'key',
  },
}) : undefined);

export const SplitContainer = ({ children }: { children: JSX.Element }) => {
  const splitConfig = getSplitConfig();

  if (!splitConfig) {
    Logger.warn('Split.io configuration not provided');
    return children;
  }

  return (
    <SplitFactory config={splitConfig} updateOnSdkUpdate>
      {children}
    </SplitFactory>
  );
};
