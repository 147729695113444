import { Logger } from './logger';

type HiddenProperty = 'hidden' | 'msHidden' | 'webkitHidden';
type VisibilityEventName = keyof DocumentEventMap;

type VisibilityPropNamesTypes = {
  hiddenPropertyName: HiddenProperty;
  visibilityChangeEventName: VisibilityEventName;
}

// visibility event type and property vary on different browsers
function getVisibilityPropertyNames(): VisibilityPropNamesTypes {
  // @ts-ignore
  if (typeof document.msHidden !== 'undefined') {
    return {
      hiddenPropertyName: 'msHidden',
      // @ts-ignore
      visibilityChangeEventName: 'msvisibilitychange',
    };
  }
  // @ts-ignore
  if (typeof document.webkitHidden !== 'undefined') {
    return {
      hiddenPropertyName: 'webkitHidden',
      // @ts-ignore
      visibilityChangeEventName: 'webkitvisibilitychange',
    };
  }

  return {
    hiddenPropertyName: 'hidden',
    // @ts-ignore
    visibilityChangeEventName: 'visibilitychange',
  };
}

export const { hiddenPropertyName, visibilityChangeEventName } = getVisibilityPropertyNames();

export function isVisibleTab() {
  return !document[hiddenPropertyName];
}

function handleVisibilityChange() {
  Logger.log(`visibility in tab changed to ${document[hiddenPropertyName] ? 'hidden' : 'visible'} ${window.name ? `in window: ${window.name}` : ''}`);
}

export function addVisibilityChangeHandler(visibilityHandler = handleVisibilityChange) {
  document.addEventListener(visibilityChangeEventName, visibilityHandler, false);
}

export function removeVisibilityChangeHandler(visiblityHandler = handleVisibilityChange) {
  document.removeEventListener(visibilityChangeEventName, visiblityHandler);
}
