// @ts-nocheck
import {
  flow,
  applyPatch,
  onSnapshot,
  getSnapshot,
  getType,
} from 'mobx-state-tree';

import { get, pick, omit } from 'lodash';
import { getNEXTContext } from '@utils/mst/getNEXTContext';
import { Logger } from '@utils/logger';

const { sessionStorage } = window;

const adapter = {
  save: async (key, snapshot) => sessionStorage.setItem(key, JSON.stringify(snapshot)),

  load: async (key) => {
    const raw = sessionStorage.getItem(key);

    if (raw) {
      return JSON.parse(raw);
    }

    return undefined;
  },
};

const WithStorage = (options) => (
  (self) => {
    let disposer;

    let bootstrapped = false;

    const key = get(options, 'key', getType(self).name);
    const autoSave = get(options, 'autoSave', true);

    if (!adapter) {
      throw new Error('No adapter available');
    }

    if (!adapter.load || !adapter.save) {
      throw new Error('The available adapter does not have a valid load or save method');
    }

    const filterSnapshotKeys = (snapshot) => {
      // Serenity now
      if (!snapshot) {
        return snapshot;
      }

      // Cast madness
      const only = [].concat(get(options, 'only', []));
      const except = [].concat(get(options, 'except', []));

      // use the input if there's no filters
      if (only.length === 0 && except.length === 0) {
        return snapshot;
      }

      // Assign to whole snapshot if only is empty
      const result = only.length > 0
        ? pick(snapshot, only)
        : snapshot;

      // Omit some things
      return omit(result, except);
    };

    const enableSaving = () => {
      disposer?.();
      disposer = onSnapshot(self, (snapshot) => {
        adapter.save(key, filterSnapshotKeys(snapshot));
        adapter.save('NEXT', getNEXTContext(self));
      });
    };

    return {
      actions: {
        hasBootstrapped() {
          return bootstrapped;
        },

        load: flow(function* load() {
          if (bootstrapped) {
            return self;
          }

          const data = yield adapter.load(key);

          if (data) {
            Logger.log('Loading data snapshot', data);

            // applySnapshot(self, data);
            const patches = Object.keys(data).map((patchKey) => (
              {
                op: 'replace',
                path: `/${patchKey}`,
                value: data[patchKey],
              }
            ));

            Logger.log('Applying patches', patches);

            applyPatch(self, patches);
          }

          applyPatch(
            self,
            [
              {
                op: 'replace',
                path: '/bootstrapped',
                value: true,
              },
            ],
          );

          bootstrapped = true;

          if (autoSave) {
            enableSaving();
          }

          if (self.afterBootstrap) {
            self.afterBootstrap();
          }

          return data;
        }),

        save: flow(function* save() {
          yield adapter.save(key, filterSnapshotKeys(getSnapshot(self)));
          yield adapter.save('NEXT', getNEXTContext(self));
        }),

        beforeDetach() {
          disposer?.();
        },
      },
    };
  }
);

export default WithStorage;
