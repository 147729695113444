// @ts-nocheck
import { MonitoringService } from '@services';
import { APIError } from '@utils/customErrors';
import { Logger } from '@utils/logger';

export default {
  // self will refer to Application
  actions: (self) => ({
    // TODO move danger variant calls to another method
    showAlert(
      message,
      variant = 'success',
      error: unknown = undefined,
      dismissOnTimeout = false,
    ) {
      clearTimeout(self.alertTimeoutId);
      self.alert = {
        message,
        variant,
      };

      if (variant === 'danger') {
        if (error) {
          Logger.error('showAlert: displaying error', error);
          MonitoringService.addLog({
            message,
            logGroup: '[MST][Application] showAlert',
            logRecordType: 'ApplicationError',
            error: error as APIError,
          });
        }
        // keep the Alert component open, user has to manually close it
        if (!dismissOnTimeout) {
          return;
        }
      }

      self.alertTimeoutId = setTimeout(() => {
        self.dismissAlert();
      }, 3000);
    },

    dismissAlert() {
      self.alert = undefined;
      if (self.alertTimeoutId) {
        clearTimeout(self.alertTimeoutId);
      }
    },
  }),

  views: (self) => ({
    get alertVisible() {
      return !!self.alert;
    },
  }),

  volatile: () => ({
    alertTimeoutId: null,
  }),
};
