// @ts-nocheck
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import RBTooltip from 'react-bootstrap/Tooltip';

const Tooltip = ({ placement, title, children, className }) => {
  const overlay = useCallback(
    (...[p]) => (
      <RBTooltip {...p} show={p.show.toString()} className={className}>
        {title}
      </RBTooltip>
    ),
    [className, title],
  );

  return (
    <OverlayTrigger placement={placement} overlay={overlay}>
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  placement: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  title: '',
  className: undefined,
};
