import { IRootStore } from '@stores/ApplicationInterfaces';
import { flow, getRoot, types } from 'mobx-state-tree';

export default {
  attributes: {
    linksShared: types.array(types.string),
  },
  actions: (self: any) => ({
    loadSharedLinks: flow(function* loadSharedLinks() {
      const { hades, addPreviewUrl } = getRoot<IRootStore>(self);

      self.linksShared.clear();

      const result = yield hades?.request('linkPreview.getShared', {
        memberId: self.id,
      }) as Promise<any>;

      if (result.sharedLinks?.length) {
        const links = result.sharedLinks;

        links.forEach((link: string) => {
          addPreviewUrl(link);
          self.linksShared.push(link);
        });
      }
    }),
    addSharedLinks(links: string[]) {
      // avoid adding repeated links
      const sharedSet = new Set<string>([...self.linksShared, ...links]);
      self.linksShared = Array.from(sharedSet);
    },
  }),

  views: (self: any) => ({
    get linksSharedData() {
      const { findUrlData } = getRoot<IRootStore>(self);
      const data = self.linksShared.map((link: string) => findUrlData(link)).filter(Boolean);
      return data;
    },
  }),
};
