import Hades from '@services/Hades';
import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { IRootStore } from '@stores/ApplicationInterfaces';
import Coach from '@stores/Coach';
import getBrowser from '@utils/getBrowser';
import {
  flow, SnapshotIn, Instance,
} from 'mobx-state-tree';

type AddCoachesInput = Omit<SnapshotIn<typeof Coach>, 'permissions' | 'role'> & {
  role: {
    name: SnapshotIn<typeof Coach>['role'];
    permissions: SnapshotIn<typeof Coach>['permissions'];
  };
}

export type CoachMixinActions = {
  getMe: () => Promise<void>;
  addCoaches(coaches?: AddCoachesInput[]): void;
  setCoach(id: Instance<typeof Coach>): void;
  createCoach: (username: string) => Promise<void>;
  reportTechnicalCrisis: (description: string) => Promise<any>;
}

export type CoachMixinViews = {
  readonly coachList: Array<Instance<typeof Coach>>;
  readonly sortedCoaches: Array<Instance<typeof Coach>>;
}

const CoachMixin = {
  actions: (self: IRootStore) => ({
    getMe: flow(function* getMe() {
      const { idToken } = yield SiblySDK.Authorization.getSessionTokens();
      const result = yield (self.hades as Hades).request(
        'coach.me',
        {},
        {
          headers: {
            Authorization: idToken,
          },
        },
      );

      self.coaches.put({
        ...result,
        role: result.role.name,
        permissions: result.role.permissions,
      });
      self.me = result.id;

      // Start saving snapshots
      self.save();
    }),

    addCoaches(coaches: AddCoachesInput[] = []) {
      const coachList: Array<SnapshotIn<typeof Coach>> = coaches
        .filter((coach) => coach.email !== null)
        .reduce((acum, coach) => {
          if (self.me && coach.id !== self.me.id) {
            acum.push(
              {
                ...coach,
                permissions: coach?.role?.permissions,
                role: coach?.role?.name,
              },
            );
          }

          return acum;
        }, [] as Array<SnapshotIn<typeof Coach>>);

      self.coaches = self.coaches.merge([...coachList.map((c) => [c.id, c])]);
    },

    setCoach(id: Instance<typeof Coach>) {
      self.me = id;
    },

    createCoach: flow(function* createCoach(username) {
      const response: AddCoachesInput = yield (self.hades as Hades).request(
        'coach.create',
        {
          username,
        },
      );

      self.addCoaches([response]);
      self.showAlert(
        `User account has been created ${response.email}`,
        'success',
      );
    }),

    reportTechnicalCrisis: flow(function* reportTechnicalCrisis(description) {
      const { browser, version } = getBrowser();
      const alias = `[UserId]: ${(self.me as Instance<typeof Coach>).id}; [BrowserName]: ${browser}; [BrowserVersion]: ${version};`;

      const response = yield (self.hades as Hades).request(
        'crisis.report',
        {
          alias,
          description,
        },
      );

      return response;
    }),
  }),
  views: ((self: IRootStore) => ({
    get coachList() {
      return Array.from(self.coaches.values());
    },

    get sortedCoaches() {
      return self.coachList
        .sort((a, b) => {
          if (self.me && self.me.id === a.id) {
            return -1;
          }

          return a.name.localeCompare(b.name);
        })
        .filter(({ id, members }) => (
          id !== self.me?.id
          && !!members.length));
    },
  })),
};

export default CoachMixin;
