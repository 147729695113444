// @ts-nocheck
import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { getRoot, flow, types } from 'mobx-state-tree';

import { isEmpty, find } from 'lodash';
import moment from 'moment-timezone';

import { getCloserDate, getRecentUpdater } from '@utils/dates';
import { trackMemberGrabbed, trackMemberReleased } from '@utils/analytics';

import { Logger } from '@utils/logger';

import Channel from './Channel';
import Coach from './Coach';
import DailySummary, {
  getDailySummaryString,
} from './Member/DailySummaryMixin';
import Membership from './Member/Membership';
import Note from './Note';
import Track from './Track';

import EmploymentProfile from './Member/EmploymentProfile';
import Resources from './Member/ResourcesMixin';
import Questionnaire from './Member/QuestionnaireMixin';
import FollowUp from './Member/FollowUpMixin';
import NPSSurvey from './Member/NPSSurveyMixin';
import ConcernsMixin from './Member/ConcernsMixin';

import DateTime from '../utils/mst/types/DateTime';
import formatTracks from '../utils/mst/formatTracks';
import Organization from './Organization';
import Report from './Reports/Report';
import AgendaMapping from './Member/AgendaMapping/AgendaMapping';
import EligibilityInfoMixin from './Member/EligibilityInfoMixin';
import GASMixin from './Member/GASMixin';
import QuestionnaireNotificationMixin from './Member/QuestionnaireNotificationMixin';
import LinksSharedMixin from './Member/LinksSharedMixin';

export default types
  .model('Member', {
    acute: types.boolean,
    assignedAt: DateTime,
    channel: Channel,
    chatId: types.string,
    chatToken: types.maybeNull(types.string),
    coach: types.maybe(types.reference(Coach)),
    createdAt: DateTime,
    id: types.identifierNumber,
    membership: Membership,
    name: types.string,
    notes: types.map(Note),
    employmentProfile: types.optional(EmploymentProfile, {}),
    messageCountLast15Min: types.number,
    tracks: types.array(Track),
    safetyPlan: types.maybe(types.string),
    organization: types.reference(Organization),
    isHidden: types.maybe(types.boolean),
    hasUnsavedNotes: false, // MST knows this is a boolean
    reports: types.optional(types.array(Report), []),
    agendaMapping: types.maybe(AgendaMapping),
    language: types.string,
    locale: types.maybe(types.string),
    ...Resources.attributes,
    ...Questionnaire.attributes,
    ...DailySummary.attributes,
    ...FollowUp.attributes,
    ...NPSSurvey.attributes,
    ...EligibilityInfoMixin.attributes,
    ...GASMixin.attributes,
    ...QuestionnaireNotificationMixin.attributes,
    ...LinksSharedMixin.attributes,
  })
  .volatile(() => ({
    isLogged: false,
    lastLikedMessage: null,
    loadingEligibilityInfo: true,
    isLoadingData: false,
  }))
  .actions(NPSSurvey.actions)
  .actions(FollowUp.actions)
  .actions(DailySummary.actions)
  .actions(Questionnaire.actions)
  .actions(ConcernsMixin.actions)
  .actions(GASMixin.actions)
  .actions(EligibilityInfoMixin.actions)
  .actions(QuestionnaireNotificationMixin.actions)
  .actions(LinksSharedMixin.actions)
  .actions((self) => ({
    getServiceById(serviceId) {
      return self.resources.services.find(
        (service) => service.id === serviceId,
      );
    },

    setMessageCountLast15Min(count) {
      self.messageCountLast15Min = count;
    },

    disableLogging(): void {
      self.isLogged = false;
    },

    enableLogging(): void {
      self.isLogged = true;
    },

    load: flow(function* load() {
      self.selectedSummary = undefined;
      self.initiallySelectedSummaryDate = null;
      self.summaries = [];
      self.gas = [];
      self.lastLikedMessage = null;
      self.loadingEligibilityInfo = true;
      self.isLoadingData = true;
      self.setUnsavedNotes(false);
      const application = getRoot(self);

      const memberId = self.id;

      const [
        memberGetResult,
        questionnairesResult,
        dailySummaryResult,
        messagesLikedResult,
        reportsResult,
        agendaConcerns,
        memberGoals,
      ] = yield Promise.all([
        application.hades.request('member.get', { memberId: self.id }),
        application.hades.request('questionnaire.list', { memberId: self.id }),
        application.hades.request('dailySummary.listDates', {
          memberId: self.id,
        }),
        application.hades.request('message.listLiked', { memberId: self.id }),
        application.hades.request('member.reports', { memberId: self.id }),
        application.hades.request('agendaConcern.list', { memberId: self.id }),
        application.hades.request('memberGoal.list', { memberId: self.id }),
        self.resources.loadResources(),
        self.loadSharedLinks(),
      ]);

      if (!self.isLoadingData) {
        Logger.log(`Cancelled member load for memberId:${memberId}`);
        SiblySDK.Monitoring.addBreadcrumb({
          message: `Cancelled member load for memberId:${memberId}`,
          category: 'Member',
        });
        return;
      }

      if (memberGetResult) {
        Object.entries(memberGetResult.notes).forEach(
          ([key, { coachId, text, updatedAt }]) =>
            self.notes.set(key, {
              id: key,
              updatedBy: coachId,
              lastUpdated: updatedAt || null,
              text,
            }),
        );

        const { followUp, showEligibilityBanner, questionnaireDueInfo } =
          memberGetResult;

        if (followUp) {
          self.followUp.frequency = followUp.frequency;
          self.followUp.hour = followUp.hour;
          self.followUp.message = followUp.message || '';
          self.followUp.updatedAt = getCloserDate(followUp);
          self.followUp.updatedBy = getRecentUpdater(followUp);
        }

        self.employmentProfile = memberGetResult.employmentProfile;

        // this only means we need to call the eligibilityInfo BE
        if (showEligibilityBanner) {
          yield self.getEligibilityInfo();
        }
        self.loadingEligibilityInfo = false;

        if (questionnaireDueInfo) {
          self.questionnaireDueInfo = questionnaireDueInfo;
        }
      }

      if (questionnairesResult) {
        self.questionnaires = questionnairesResult.questionnaires;

        self.NPSSurvey = questionnairesResult.NPSSurvey;
      }

      if (dailySummaryResult) {
        self.summaryDates = dailySummaryResult.dates.map(getDailySummaryString);

        if (dailySummaryResult.summary) {
          self.summaries = [dailySummaryResult.summary];
          self.initiallySelectedSummaryDate = getDailySummaryString(
            dailySummaryResult.summary?.date,
          );
        }
      }

      if (messagesLikedResult?.ids?.length) {
        self.channel.likedMessagesIds = messagesLikedResult.ids;
      }

      if (reportsResult?.length) {
        self.reports = reportsResult.reverse();
      }

      if (agendaConcerns) {
        self.agendaMapping = agendaConcerns;
      }

      if (memberGoals?.length) {
        // need to remap goalId to id >.<
        const goal = memberGoals[0];
        goal.id = goal.goalId;

        self.gas = [goal];
      }

      self.isLoadingData = false;
    }),

    onAssignTrack: flow(function* onAssignTrack(id) {
      const application = getRoot(self);

      const result = yield application.hades.request('track.assign', {
        memberId: self.id,
        trackId: id,
      });

      const foundIndex = self.tracks.findIndex(
        (currentTrack) => currentTrack.id === id,
      );
      const [newTrack] = formatTracks([result]);

      self.tracks[foundIndex] = newTrack;
    }),

    setCoach(coachOrUndefined, assignedAt) {
      self.coach = coachOrUndefined;
      self.assignedAt = assignedAt && moment(assignedAt).toDate();
    },

    hide() {
      self.isHidden = true;
    },

    show() {
      self.isHidden = false;
    },

    setUnsavedNotes(bool) {
      self.hasUnsavedNotes = bool;
    },

    setAcute(isAcute) {
      self.acute = isAcute;
    },

    // TODO: Clean up the following logic.
    // If there is a pink dot when you release member, then member should go to Unassigned
    // set conditions to put member in Unassigned:
    // when you release a member , you check if pinkDot
    // if there is a pinkdot then do not update the coachAssignmentTimestamp at all.
    // if there is no pinkDot then set timestamp as now();
    grab() {
      const root = getRoot(self);
      root.socket.request('member.grab', {
        memberId: self.id,
        preventTimestampUpdate: self.channel.hasPinkDot,
      });

      trackMemberGrabbed(self);
    },

    release() {
      const root = getRoot(self);

      root.socket.request('member.release', {
        memberId: self.id,
        preventTimestampUpdate: self.channel.hasPinkDot,
      });

      trackMemberReleased(self);
    },

    terminate: flow(function* terminate() {
      const root = getRoot(self);
      yield root.hades.request('member.terminate', {
        memberId: self.id,
      });
    }),

    startQuestionnaire: flow(function* startQuestionnaire({
      memberId,
      questionnaireId,
    }) {
      const application = getRoot(self);

      const result = yield application.hades.request('questionnaire.start', {
        memberId,
        questionnaireId,
      });

      return result;
    }),

    update: flow(function* update({
      acute,
      employmentProfile,
      followUp,
      note,
    }) {
      const application = getRoot(self);
      const noteParams = {};

      if (note) {
        noteParams.notes = {
          [note.id]: note.value,
        };
      }

      const result = yield application.hades.request('member.update', {
        attributes: {
          acute,
        },
        followUp,
        ...noteParams,
        memberId: self.id,
        employmentProfile: employmentProfile || {},
      });

      self.setUnsavedNotes(false);

      if (result.notes) {
        Object.entries(result.notes).forEach(
          ([key, { coachId, text, updatedAt }]) =>
            self.notes.put({
              id: key,
              updatedBy: coachId,
              lastUpdated: updatedAt || null,
              text,
            }),
        );
      }

      if (result.employmentProfile) {
        self.employmentProfile = result.employmentProfile;
      }

      if (result.followUp) {
        self.followUp.hour = result.followUp.hour;
        self.followUp.frequency = result.followUp.frequency;
        self.followUp.message = result.followUp.message || '';
        self.followUp.updatedAt = getCloserDate(result.followUp);
        self.followUp.updatedBy = getRecentUpdater(result.followUp);
      }

      self.acute = result.acute;
    }),

    uploadSafetyPlan: flow(function* uploadSafetyPlan(safetyPlan) {
      const application = getRoot(self);

      const result = yield application.hades.request('member.update', {
        memberId: self.id,
        safetyPlan,
      });

      if (result?.safetyPlan) {
        self.safetyPlan = result.safetyPlan;
      }
    }),

    likedSiblyMessage(messageId, liked) {
      const { member: memberInChat } = getRoot(self);
      const isMemberInChat = memberInChat && memberInChat.id === self.id;
      const message = self.channel.messages.find(({ id }) => id === messageId);
      if (isMemberInChat && !message) return;
      if (liked) {
        self.channel.addLikeMessage(messageId);
      } else {
        self.channel.removeLikedMessage(messageId);
      }
      if (isMemberInChat) {
        self.lastLikedMessage = liked ? messageId : null;
      }
    },

    log(message: string): void {
      if (self.isLogged) {
        Logger.log(`${message} memberId=${self.id}`);
      }
    },
  }))
  .views(DailySummary.views)
  .views(Questionnaire.views)
  .views(LinksSharedMixin.views)
  .views((self) => ({
    get assignableTracks() {
      return self.tracks.filter(({ assigned }) => !assigned);
    },

    get assignedTracks() {
      return self.tracks.filter(({ assigned }) => assigned);
    },

    get chatName(): string {
      return `${self.name} ${self.id}`;
    },

    get hasSafetyPlan(): boolean {
      return !isEmpty(self.safetyPlan);
    },

    // members who are grabbed by a coach
    get isGrabbed(): boolean {
      return Boolean(self.coach?.id);
    },

    // this is not used anywhere
    // get isNew(): boolean {
    //   return moment(self.createdAt).isSameOrAfter(moment().subtract(1, 'hour'));
    // },

    // members who arent grabbed by any coach AND
    // they have already been assigned before(assignedAt) AND
    // their last message came before they were released
    get isReleased(): boolean {
      const {
        assignedAt,
        channel: { lastMemberMessageAt },
        isGrabbed,
        // not sure why we use both assignedAt and momentAssignedAt.
        // in one of my tests, i saw assignedAt undefined
        // and momentAssignedAt the current date time, might be causing a bug
        momentAssignedAt,
      } = self;

      const lastMemberMessageIsBeforeMomentAssignedAt =
        lastMemberMessageAt?.isBefore(momentAssignedAt);

      // when a coach releases a member, coach id (member.coach) is set to null.
      // if member does not have a pink dot,
      // assignedAt is set to the time of the release (see preventTimestampUpdate)
      const isReleased = Boolean(
        !isGrabbed && assignedAt && lastMemberMessageIsBeforeMomentAssignedAt,
      );

      self.log(
        `Member:isReleased assignedAt=${assignedAt} lastMemberMessageAt=${lastMemberMessageAt} isGrabbed=${isGrabbed} momentAssignedAt=${momentAssignedAt} lastMemberMessageIsBeforeMomentAssignedAt=${lastMemberMessageIsBeforeMomentAssignedAt} isReleased=${isReleased}`,
      );

      return isReleased;
    },

    get isUnassigned(): boolean {
      const { unassignedMembers } = getRoot(self);
      return Boolean(unassignedMembers.find((m) => m.id === self.id));
    },

    get isGrabbedByMe(): boolean {
      const { grabbedMembers } = getRoot(self);
      return Boolean(grabbedMembers.find((m) => m.id === self.id));
    },

    get options() {
      const { me } = getRoot(self);
      const options = [
        'viewReports',
        'safetyPlan',
        ...(self.acute ? ['unmarkAsAcute'] : ['markAsAcute']),
        'markChatRead',
        'sendQuestionnaire',
        'sendReview',
      ];

      // TODO(kai): refactor this post-replatform
      if (me.isAdmin) {
        options.push('terminateMember');
      }

      return options;
    },

    get momentAssignedAt(): moment.Moment {
      return moment(self.assignedAt);
    },

    get nameColorClass(): string {
      const createdAt = moment(self.createdAt);
      const hoursDifference = moment().diff(createdAt, 'hours', true);
      if (hoursDifference <= 2) {
        return 'text-flamingo';
      }
      // 168 hours = 7 days
      if (hoursDifference <= 168) {
        return 'text-mustard';
      }
      return '';
    },

    get nameColor(): string {
      const createdAt = moment(self.createdAt);
      const hoursDifference = moment().diff(createdAt, 'hours', true);
      if (hoursDifference <= 2) {
        return 'Pink';
      }
      // 168 hours = 7 days
      if (hoursDifference <= 168) {
        return 'Gold';
      }
      return 'Light Purple';
    },

    get leftNavGrouping(): string {
      const application = getRoot(self);

      if (self.isGrabbedByMe) {
        return 'My Active Members';
      }

      if (self.isUnassigned) {
        return 'Unassigned';
      }
      if (find(application.recentlyReleaasedMembers, { id: self.id })) {
        return 'Recently Released';
      }
      // not grabbed by me
      if (find(application.grabbedMembers, { id: self.id })) {
        return 'Other coach Lists';
      }
      return '';
    },
  }));
