import {
  flow,
  SnapshotIn,
} from 'mobx-state-tree';
import Hades from '@services/Hades';
import { IOrganizations } from '@stores/ApplicationInterfaces';
import Organization from '@stores/Organization';

export default {
  actions: (self: { hades: Hades; organizations: IOrganizations }) => ({
    createOrganization: flow(function* createOrganization({
                                                            industry,
                                                            name,
                                                          }) {
      const result: SnapshotIn<typeof Organization> = yield self.hades.request(
        'organization.create',
        {
          industry,
          name,
        },
      );

      if (result) {
        self.organizations.set(`${result.id}`, result);
      }

      return result;
    }),

    updateOrganization: flow(function* updateOrganization({
                                                            industry,
                                                            name,
                                                            organizationId,
                                                          }) {
      const result: SnapshotIn<typeof Organization> = yield self.hades.request(
        'organization.update',
        {
          industry,
          name,
          organizationId,
        },
      );

      if (result) {
        self.organizations.set(`${result.id}`, result);
      }

      return result;
    }),
    activateOrganization: flow(function* activateOrganization({ id }: { id: string | number }) {
      const result: SnapshotIn<typeof Organization> = yield self.hades.post(`/organizations/${id}/activate`);

      const organization = self.organizations.get(`${id}`);
      if (organization) {
        self.organizations.set(`${id}`, Organization.create(result));
      }
    }),
    deactivateOrganization: flow(function* deactivateOrganization({ id }) {
      const result: SnapshotIn<typeof Organization> = yield self.hades.post(`/organizations/${id}/deactivate`);

      const organization = self.organizations.get(id);
      if (organization) {
        self.organizations.set(id, Organization.create(result));
      }
    }),
  }),
};
