import {
  SiblySDK,
  MonitoringAddLogParams,
  LogLevel,
} from '@sibly/sibly-sdk-browser';
import { APIError } from '@utils/customErrors';
import { getLogDetails } from './getLogDetails';

/**
 * Parameters for adding a log entry to monitoring.
 * @typedef {object} AddLogParameters
 * @property {Error|APIError} [error] - The error to be logged (optional).
 * @property {Record<string, unknown>} [details] - Additional details about the log entry (optional).
 * @property {string} logGroup - The log group for categorizing the log entry.
 * @property {string} logRecordType - The log record type for categorizing the log entry.
 * @property {string} message - The message to be logged.
 * @property {LogLevel} [level=LogLevel.ERROR] - The log level (optional, defaults to ERROR).
 * @property {string} [refId] - The reference ID associated with the log entry (optional).
 * @property MonitoringAddLogParams - Other parameters for adding logs to monitoring.
 */
export type AddLogParameters = Omit<MonitoringAddLogParams, 'details'> & {
  error?: Error | APIError;
  details?: Record<string, unknown>;
};

/**
 * Add a log entry to Sibly Monitoring.
 *
 * @param {AddLogParameters} options - Options for adding a log entry.
 * @returns {Promise<void>} A Promise that resolves once the log entry is added to monitoring.
 */
export async function addLog({
  logGroup,
  logRecordType,
  details = {},
  message,
  error,
  level = LogLevel.ERROR,
  ...restParams
}: AddLogParameters): Promise<void> {
  const logDetails = await getLogDetails({
    ...details,
    errorMessage: error?.message,
    error,
  });

  const addLogParams: MonitoringAddLogParams = {
    level,
    logGroup,
    logRecordType,
    message,
    refId: error && 'requestId' in error ? error.requestId : undefined,
    details: logDetails,
    ...restParams,
  };

  SiblySDK.Monitoring.addLog(addLogParams);
}
