// @ts-nocheck
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import RBModal from 'react-bootstrap/Modal';

import cn from 'classnames';

import DraggableModal from './Draggable';

import styles from './modal.module.scss';

function ModalFooter({ onClose, isLoading, onSave, saveTitle, dataTestId }) {
  return onSave || onClose ? (
    <RBModal.Footer>
      {onClose ? (
        <Button
          data-testid={`${
            dataTestId ? `${dataTestId}-` : ''
          }common-modal-close-button`}
          variant="outline-secondary"
          onClick={onClose}
        >
          Close
        </Button>
      ) : null}
      {onSave ? (
        <Button
          data-testid="common-modal-save-button"
          disabled={isLoading}
          onClick={!isLoading ? onSave : null}
          variant="magenta"
        >
          {isLoading ? 'Loading...' : saveTitle}
        </Button>
      ) : null}
    </RBModal.Footer>
  ) : null;
}

function Modal({
  boundsSelector, // used to set the boundaries where the modal can be moved around
  bsPrefix,
  className, // used to override styles on the modal
  contentClassName,
  dataTestId,
  label,
  loading,
  visible,
  onClose,
  onEnter,
  onSave,
  saveTitle,
  title,
  size, // using the Bootstrap Modal default sizing
  children,
  Footer,
  backdrop,
  scrollable,
  id,
}) {
  const DialogComponent = useCallback(
    (props) => <DraggableModal {...props} bounds={boundsSelector} />,
    [boundsSelector],
  );

  return (
    <RBModal
      id={id}
      bsPrefix={bsPrefix}
      aria-labelledby={`${label}-modal`}
      centered
      data-testid={dataTestId || 'common-modal'}
      dialogAs={DialogComponent}
      dialogClassName={cn(className, styles.modal)}
      contentClassName={contentClassName}
      onHide={onClose}
      onEnter={onEnter}
      show={visible}
      size={size}
      backdrop={backdrop}
      scrollable={scrollable}
    >
      <RBModal.Header closeButton className={styles.header}>
        <RBModal.Title
          id={`${label}-title`}
          className={cn('w-100', styles.title)}
        >
          {title}
        </RBModal.Title>
      </RBModal.Header>
      <RBModal.Body className="p-0">{children}</RBModal.Body>
      {/* trying to explicitly not render a footer? pass in Footer={null} */}
      {Footer || Footer === null ? (
        Footer
      ) : (
        <ModalFooter
          dataTestId={dataTestId}
          isLoading={loading}
          saveTitle={saveTitle}
          onSave={onSave}
          onClose={onClose}
        />
      )}
    </RBModal>
  );
}

export default Modal;

ModalFooter.propTypes = {
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  saveTitle: PropTypes.string,
  dataTestId: PropTypes.string,
};

ModalFooter.defaultProps = {
  onSave: null,
  onClose: null,
  isLoading: false,
  saveTitle: 'Save',
  dataTestId: '',
};

Modal.propTypes = {
  boundsSelector: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bsPrefix: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onSave: PropTypes.func,
  saveTitle: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  Footer: PropTypes.node,
  backdrop: PropTypes.bool,
  scrollable: PropTypes.bool,
  id: PropTypes.string,
};

Modal.defaultProps = {
  boundsSelector: false,
  bsPrefix: 'modal',
  className: '',
  contentClassName: '',
  dataTestId: '',
  loading: false,
  onEnter: null,
  onSave: null,
  saveTitle: 'Save',
  size: undefined,
  Footer: undefined,
  visible: true,
  backdrop: undefined,
  scrollable: false,
  id: undefined,
};
