// @ts-nocheck
// eslint-disable import/prefer-default-export
import moment from 'moment-timezone';
import { Instance } from 'mobx-state-tree';
import Coach from '@stores/Coach';
import Member from '@stores/Member';

import Config from '../Config';
import { getDashboardInstancesCount } from './locastorage';

function callAnalytics(name, data) {
  if (Config.segment.track) {
    window.analytics.track(name, data);
  }
}

export function identify(coach) {
  if (Config.segment.track) {
    window.analytics.identify(`coach-${coach.id}`, {
      name: coach.name,
      role: coach.role,
    });
  }
}

export function setAnalyticsUser({ id, email, name, role }) {
  window.analytics.identify({
    id,
    email,
    username: name,
    role,
  });
}

export function trackReportIncludesUrl(data, url) {
  callAnalytics('URL Sent In Report', { ...data, url });
}

export function trackReportIncludesResources(data) {
  callAnalytics('Resource attached to a report', data);
}

export function trackSendReport(data) {
  const resources = [];
  const resourcesKeys = ['benefits', 'questionnaires'];

  // linkPreview attachments are reported in trackReportIncludesUrl, they dont have a title property
  resourcesKeys.forEach((type) => {
    if (data.attachments[type].length) {
      data.attachments[type].forEach((attach) => {
        resources.push({
          resourceTitle: attach.title,
          resourceType: type,
        });
      });
    }
  });

  if (data.hasSafetyPlan) {
    resources.push({
      resourceType: 'Sibly Resource',
      resourceTitle: 'Safety Plan',
    });
  }

  const analyticsData = {
    reportType: data.reportTemplateType?.optionTitle || 'No Template',
    reportTitle: data.title || 'Legacy Report',
    resourcesAttached: resources,
    dateReceived: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  };

  callAnalytics('Report Received', analyticsData);

  if (data.attachments.linkPreview?.length) {
    data.attachments.linkPreview.forEach((url) => {
      trackReportIncludesUrl(analyticsData, url);
    });
  }

  if (resources.length) {
    trackReportIncludesResources(analyticsData);
  }
}

export function trackAgendaMappingAttachedToChat({
  concerns,
  coachName,
  memberId,
}) {
  callAnalytics('Coach attached Agenda Map to Chat', {
    concern: concerns,
    coachName,
    memberId,
  });
}

export function trackAgendaMappingSentinChat({ concerns, memberId }) {
  callAnalytics('Coach sent Agenda Map image in Chat', {
    concern: concerns,
    memberId,
  });
}

export function trackAgendaMappingTemplateMessageEdited({ memberId }) {
  callAnalytics('Coach edited the templated message in the chat', {
    memberId,
  });
}

export function trackAgendaMappingSelected({ coachName }) {
  callAnalytics('Coach clicked the Agenda mapping tab', {
    coachName,
  });
}

export function trackAgendaMappingFirstTimeClickedAddConcern({
  concern,
  memberId,
  coachName,
}) {
  callAnalytics('Agenda Mapping First Time Clicked Add Concern', {
    concern,
    memberId,
    coachName,
  });
}

export function trackAgendaMappingClickedAddConcern({
  concern,
  memberId,
  coachName,
}) {
  callAnalytics('Agenda Mapping Clicked Add Concern', {
    concern,
    memberId,
    coachName,
  });
}

export function trackAgendaMappingEditedConcernText({
  concern,
  editedConcern,
  memberId,
  coachName,
}) {
  callAnalytics('Agenda Mapping Edited Concern', {
    concern,
    editedConcern,
    memberId,
    coachName,
  });
}

export function trackAgendaMappingConcernMarkedAsPriority({
  concern,
  memberId,
  coachName,
}) {
  callAnalytics('Agenda Mapping Concern Marked As Priority', {
    concern,
    memberId,
    coachName,
  });
}

export function trackAgendaMappingConcernMarkedDoNotDiscuss({
  concern,
  memberId,
  coachName,
}) {
  callAnalytics('Agenda Mapping Concern Marked Do Not Discuss', {
    concern,
    memberId,
    coachName,
  });
}

export function trackAgendaMappingConcernDeleted({
  concern,
  memberId,
  coachName,
}) {
  callAnalytics('Agenda Mapping Concern Deleted', {
    concern,
    memberId,
    coachName,
  });
}

export function trackAgendaMappingSave({ memberId, coachName }) {
  callAnalytics('Agenda Mapping Saved', {
    memberId,
    coachName,
  });
}

export function trackMemberClicked({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Member Clicked', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackMemberGrabbed({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Member Grabbed', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackMemberReleased({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Member Released', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackBrowseAllMembersOpened({ name, role }) {
  window.analytics.track('Browse All Members Opened', {
    coachName: name,
    coachRole: role,
  });
}

export function trackCoachSentMessage({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Coach Sent Message', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackSearchConversationClicked({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Search Conversation Clicked', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackMemberOptionsClicked({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Options Clicked', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackMarkAsAcute({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Marked As Acute', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackMarkAsNotAcute({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Marked As Not Acute', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackTerminateMember({
  id,
  acute,
  organization: { name },
  coach,
}) {
  window.analytics.track('Terminate Member', {
    memberId: id,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackViewResourcesClicked({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('View Resources Clicked', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackMessageIncludesURL({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
}) {
  window.analytics.track('Message Type Included URL', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackBenefitSentInChat({
  id,
  nameColor,
  leftNavGrouping,
  acute,
  channel: { messageIndicator },
  organization: { name },
  coach,
  vendorName,
  serviceName,
}) {
  window.analytics.track('Benefit Sent in Chat', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
    vendorName,
    serviceName,
  });
}

export function trackEligibilityStatusSelected({
  member: {
    id,
    nameColor,
    leftNavGrouping,
    acute,
    channel: { messageIndicator },
    organization: { name },
  },
  coach,
}) {
  window.analytics.track('Eligibility Status Selected', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackEligibilityReminderAddedToChat({
  member: {
    id,
    nameColor,
    leftNavGrouping,
    acute,
    channel: { messageIndicator },
    organization: { name },
  },
  coach,
}) {
  window.analytics.track('Eligibility Reminder Added To Chat', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

export function trackEligibilityReminderSentInChat({
  member: {
    id,
    nameColor,
    leftNavGrouping,
    acute,
    channel: { messageIndicator },
    organization: { name },
  },
  coach,
}) {
  window.analytics.track('Eligibility Reminder Sent In Chat', {
    memberId: id,
    membershipAge: nameColor,
    memberGrouping: leftNavGrouping,
    messageIndicator,
    memberStatus: acute ? 'Acute' : 'Not Acute',
    membershipType: name,
    coachName: coach?.name,
    coachRole: coach?.role,
  });
}

// GAS analytics
export function trackGoalsTabSelected({ memberId, coachName }) {
  callAnalytics('Goals Tab Selected', {
    memberId,
    coachName,
  });
}

export function trackNewGoalClicked({ memberId, coachName }) {
  callAnalytics('Create New Goal Clicked', {
    memberId,
    coachName,
  });
}

export function trackGASGoalCreated({
  memberId,
  coachName,
  organizationName,
  goalType,
  endDate,
  creationDate,
}) {
  callAnalytics('GAS Goal Created', {
    memberId,
    coachName,
    organizationName,
    goalType,
    endDate,
    creationDate,
  });
}

export function trackGASGoalArchived({
  memberId,
  coachName,
  organizationName,
  lastReportedScore,
  goalName,
  goalType,
}) {
  callAnalytics('Goal Archived', {
    memberId,
    coachName,
    organizationName,
    lastReportedScore,
    goalName,
    goalType,
  });
}

export function trackGoalSummaryClicked({
  memberId,
  coachName,
  organizationName,
  goalType,
  endDate,
  creationDate,
  desiredOutcome,
  goalIncompleteAlert,
}) {
  callAnalytics('Goal Summary Clicked', {
    memberId,
    coachName,
    organizationName,
    goalType,
    endDate,
    creationDate,
    desiredOutcome,
    goalIncompleteAlert,
  });
}

export function trackGASGoalNameChanged({
  memberId,
  coachId,
  organizationName,
  goalName,
}) {
  callAnalytics('Goal Name Changed', {
    memberId,
    coachId,
    organizationName,
    goalName,
  });
}

export function trackGASClickAddUpdate({
  memberId,
  coachId,
  organizationName,
  lastReportedScore,
  desiredOutcome,
  goalName,
}) {
  callAnalytics('Goal Click Add Update', {
    memberId,
    coachId,
    organizationName,
    lastReportedScore,
    desiredOutcome,
    goalName,
  });
}

export function trackGASSaveUpdate({
  memberId,
  coachId,
  organizationName,
  lastReportedScore,
  scoreUpdate,
  dateCreated,
  progressUpdate,
  goalName,
  desiredOutcome,
}) {
  callAnalytics('Goal Save Update', {
    memberId,
    coachId,
    organizationName,
    lastReportedScore,
    scoreUpdate,
    dateCreated,
    progressUpdate,
    goalName,
    desiredOutcome,
  });
}

export function trackQuestionnaireSentFromAlertBanner(data: {
  memberId: number;
  coachId: number;
  organizationName: string;
  questionnaireName: string;
}) {
  callAnalytics('Questionnaire is sent from alert banner', data);
}

export function trackQuestionnaireNOTSentFromAlertBanner(data: {
  memberId: number;
  coachId: number;
  organizationName: string;
  questionnaireName: string;
}) {
  callAnalytics('Questionnaire is NOT sent from alert banner', data);
}

export function trackNotesPanelOpened(data: { coachId: string }) {
  callAnalytics('Notes Panel Opened', data);
}

export function trackNotesPanelClosed(data: { coachId: string }) {
  callAnalytics('Notes Panel Closed', data);
}

export function trackBioPanelOpened(data: { coachId: string }) {
  callAnalytics('Bio Panel Opened', data);
}

export function trackBioPanelClosed(data: { coachId: string }) {
  callAnalytics('Bio Panel Closed', data);
}

export function trackLogoutModalVisible(coachId) {
  callAnalytics('Session Log Out Modal Triggered', {
    coachId,
    dashboardInstancesCount: getDashboardInstancesCount(),
  });
}

export function trackSafetyPlan(
  eventName: string,
  data: {
    coachName?: Instance<typeof Coach>['name'];
    coachRole?: Instance<typeof Coach>['role'];
    memberId: Instance<typeof Member>['id'];
    membershipType: Instance<typeof Organization>['name'];
  },
) {
  callAnalytics(eventName, data);
}

export function trackCreateDailySummary(data) {
  callAnalytics('Created daily summary', data);
}

export function trackSavedDailySummary(data) {
  callAnalytics('Saved daily summary', data);
}

export function trackViewDailySummaries(data) {
  callAnalytics('Viewed all daily summaries', data);
}

export function trackEditDailySummary(data) {
  callAnalytics('Edited existing daily summary', data);
}

export function trackSelectedDailySummary(data) {
  callAnalytics('Selected daily summary calendar date', data);
}

export function trackSearchedDailySummary(data) {
  callAnalytics('Searched daily summaries', data);
}
