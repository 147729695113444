import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import cn from 'classnames';

import RBAlert from 'react-bootstrap/Alert';

import Store from '@store';

import { UNMASK_CLASSNAME } from '@components/FullStory';
import { FC } from 'react';
import styles from './alert.module.scss';

interface Props {
  className?: string;
}

export const Alert: FC<Props> = ({ className }) => {
  const {
    alert,
    alertVisible,
    dismissAlert,
  } = Store;

  return (
    <RBAlert
      data-testid="alert-box"
      className={cn('position-fixed', styles.toast, className)}
      dismissible
      onClose={dismissAlert}
      show={alertVisible}
      variant={alert?.variant}
    >
      <p className={UNMASK_CLASSNAME}>
        {alert?.message}
      </p>
    </RBAlert>
  );
};

Alert.propTypes = {
  className: PropTypes.string,
};

Alert.defaultProps = {
  className: '',
};

export default observer(Alert);
