import Store from '@store';
import { coachTimezoneAbbreviation } from '@utils/dates';
import getBrowser from '@utils/getBrowser';
import {
  getAppVersion,
  getDeviceModel,
  getDeviceOS,
  getDeviceType,
} from '@utils/userAgentData';
import Config from '../../Config';

/**
 * Get log details including device information and application details.
 *
 * @param {Record<string, unknown>} input - The input object containing additional details.
 * @returns {Record<string, unknown>} A new object containing input details, device information, and application details.
 */
export const getLogDetails = async (
  input: Record<string, unknown>,
): Promise<Record<string, unknown>> => ({
  ...input,
  ...(Store.me?.id ? { coachId: Store.me?.id } : {}),
  ...(Store.member?.channel?.url
    ? { channelUrl: Store.member?.channel?.url }
    : {}),
  ...(Store.member?.id ? { memberId: Store.member?.id } : {}),
  releaseVersion: getAppVersion(),
  deviceType: getDeviceType(),
  deviceModel: getDeviceModel(),
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  OS: getDeviceOS(),
  isEmulator: false,
  clientType: 'browser',
  timeZone: coachTimezoneAbbreviation,
  playSoundNotifications: Store.playSoundNotifications,
  environment: Config.env,
  browser: getBrowser().browser,
  browserVersion: getBrowser().version,
  browserTabId: window.name,
  url: window.location.href,
});
