// @ts-nocheck
import { types } from 'mobx-state-tree';

const EmploymentProfile = types.model('EmploymentProfile', {
  companyRelationship: types.maybe(types.string),
  isEligible: types.maybe(types.boolean),
  isFullTime: types.maybe(types.boolean),
  isImpacted: types.maybe(types.boolean),
  isEligibleForTherapy: types.maybe(types.boolean),
});

export default EmploymentProfile;
