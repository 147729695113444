// @ts-nocheck
import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { Logger } from './logger';

let audioContext = null;

export async function resumeAudioContext() {
  if (audioContext && audioContext.state !== 'running') {
    await audioContext.resume();
    const message = 'resumeAudioContext: audioContext resumed';
    Logger.log(message);
    SiblySDK.Monitoring.addBreadcrumb({ message, category: 'Audio' });
  }
}

export function startAudioContext() {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  audioContext = new AudioContext();
  const message = `startAudioContext: audio context started in ${audioContext.state} state`;
  Logger.log(message);
  SiblySDK.Monitoring.addBreadcrumb({ message, category: 'Audio' });

  audioContext.onstatechange = function changed() {
    const stateChangeMsg = `audioContext.onstatechange: audio context changed to ${audioContext.state} state`;
    Logger.log(stateChangeMsg);
    SiblySDK.Monitoring.addBreadcrumb({
      message: stateChangeMsg,
      category: 'Audio',
    });
  };

  // any click will enable audio context, needed to autoplay sound notifications
  document.addEventListener('click', resumeAudioContext);

  return audioContext;
}
