import PropTypes, { InferProps } from 'prop-types';
import cn from 'classnames';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UNMASK_CLASSNAME } from '@components/FullStory';

const proptypes = {
  cancelText: PropTypes.string,
  children: PropTypes.element.isRequired,
  confirmationText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  showCancelButton: PropTypes.bool,
  modalIcon: PropTypes.shape({}),
  unMaskContent: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const ConfirmModal: React.FC<InferProps<typeof proptypes>> = ({
  children,
  confirmationText,
  cancelText,
  onClose,
  onConfirm,
  title,
  visible,
  showCancelButton,
  modalIcon,
  unMaskContent,
  isLoading,
}) => (
  <Modal centered show={visible} onHide={onClose}>
    <Modal.Body className={cn('d-flex flex-column align-items-center m-2', unMaskContent ? UNMASK_CLASSNAME : undefined)}>
      {
        modalIcon && (
        <FontAwesomeIcon
        // @ts-ignore
          icon={modalIcon}
          className="my-3 justify-content-center"
          size="5x"
        />
        )
      }
      <div className="h1">{title}</div>
      <div className="d-flex flex-column align-items-center m-2">
        {children}
      </div>
      <div className="d-flex justify-content-center">
        {showCancelButton && (
          <Button className="m-1" variant="outline-secondary" onClick={onClose}>
            {cancelText}
          </Button>
        )}
        <Button disabled={!!isLoading} className="m-1" variant="magenta" onClick={onConfirm}>
          {confirmationText}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

ConfirmModal.propTypes = proptypes;

ConfirmModal.defaultProps = {
  cancelText: 'Cancel',
  confirmationText: 'Save',
  showCancelButton: true,
  modalIcon: undefined,
  unMaskContent: false,
  isLoading: false,
};

export default ConfirmModal;
