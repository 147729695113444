// @ts-nocheck
import { types } from 'mobx-state-tree';

import Questionnaire from '../Questionnaire';

export default {
  attributes: {
    questionnaires: types.array(Questionnaire),
  },

  actions: (self) => ({
    getQuestionnaireById: (id) => (
      self.questionnaires.find((questionnaire) => questionnaire.id === id)
    ),
  }),

  views: (self) => ({
    get hasQuestionnaireAnswers() {
      return self.questionnaires.some((q) => q.answers.length);
    },

    get unsentQuestionnaires() {
      const unsent = self.questionnaires.filter((questionnaire) => (
        questionnaire.status === 'unsent' && !questionnaire.legacy
      ))
        // coaching temporary wants to remove these questionnaires from the dashboard
        // https://sibly-jira.atlassian.net/browse/SIB-3193
        .filter((q) => q.name !== 'Physical Health and Mental Wellbeing')
        // "Deprecated" by Getting to know you questionnaire
        // https://sibly-jira.atlassian.net/browse/SIB-3132
        .filter((q) => q.name !== 'Screening Questionnaire');

      return unsent;
    },

    get sentQuestionnaires() {
      const sent = self.questionnaires.filter((questionnaire) => (
        (questionnaire.status === 'finished'
        || questionnaire.status === 'started'
        || questionnaire.status === 'sent') && !questionnaire.legacy
      ))
        // coaching temporary wants to remove these questionnaires from the dashboard
        // https://sibly-jira.atlassian.net/browse/SIB-3193
        .filter((q) => q.name !== 'Physical Health and Mental Wellbeing')
        // "Deprecated" by Getting to know you questionnaire
        // https://sibly-jira.atlassian.net/browse/SIB-3132
        .filter((q) => q.name !== 'Screening Questionnaire');

      return sent;
    },

  }),
};
