// @ts-nocheck
import {
  flow,
  getRoot,
  types,
} from 'mobx-state-tree';

import FollowUp from '../FollowUp';

export default {
  attributes: {
    followUp: types.maybe(FollowUp),
  },

  actions: (self) => ({
    updateFollowUp: flow(function* updateFollowUp(data) {
      const application = getRoot(self);

      const result = yield application.hades.request(
        'followUp.update',
        {
          memberId: self.id,
          message: data.message || self.followUp.message,
          frequency: data.frequency || self.followUp.frequency,
          hour: data.hour || self.followUp.hour,
          updatedBy: application.me.id,
        },
      );

      self.followUp = result;
    }),
  }),
};
