// @ts-nocheck
import { types } from 'mobx-state-tree';

const ReportTemplate = types
  .model('ReportTemplate', {
    id: types.identifierNumber,
    title: types.string,
    optionTitle: types.string,
    body: types.string,
  });

export default ReportTemplate;
