/**
 * Get timestamp that will be prepended to log msg
 */
function getTimestamp(): string {
  return `[${new Date().toISOString()}]`;
}

/**
 * Simple wrapper around console to add timestamps.
 * Will make looking at logs easier in Full story
 */
export const Logger = {
  /**
   * Log general info to stdout (console.log)
   */
  log: (...args: any[]): void => console.log(getTimestamp(), ...args),

  /**
   * Log warning to stdout (console.warn)
   */
  warn: (...args: any[]): void => console.warn(getTimestamp(), ...args),

  /**
   * Log error to stdout (console.error)
   */
  error: (...args: any[]): void => console.error(getTimestamp(), ...args),
};
