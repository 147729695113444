import {
  flow, getRoot, Instance, types,
} from 'mobx-state-tree';
import Coach from '@stores/Coach';
import DateTime from '@utils/mst/types/DateTime';
import Member from '@stores/Member';
import { IRootStore } from '@stores/ApplicationInterfaces';
import moment from 'moment-timezone';
import { ScaleOption } from '@dashComponents/ActivityTabs/GAS/components/ScaleDropdown/ScaleDropdown';
import { trackGASSaveUpdate } from '@utils/analytics';
import Progress from './Progress';

type UpdateProgressData = {
  memberId: number;
  goalId: number;
  note: string;
  score: number | null;
  scoreDescriptor: string | null | undefined;
}

type LevelSnapshot = {
  score: string;
  label: string;
  createdAt: Date;
  note: string | null;
}

const scoreNumberToStr = (number: number) => (number > 0 ? `+${number}` : number.toString());

const Goal = types.model('Goal', {
  id: types.identifierNumber,
  name: types.string,
  type: types.enumeration(
    'Type',
    ['oneway', 'twoway', 'maintenance'],
  ),
  startAt: types.maybe(DateTime),
  endAt: types.maybe(DateTime),
  minusOneGoal: types.maybe(types.string),
  minusTwoGoal: types.maybe(types.string),
  goal: types.maybe(types.string),
  plusOneGoal: types.maybe(types.string),
  plusTwoGoal: types.maybe(types.string),
  createdBy: types.maybe(types.reference(Coach)),
  createdAt: types.maybe(DateTime),
  progressUpdates: types.array(Progress),
  isActive: types.maybe(types.boolean),
  isArchived: types.maybe(types.boolean),
})
  .actions((self) => ({
    labelByScore(scoreString: string) {
      switch (scoreString) {
        case '+2': {
          return self.plusTwoGoal;
        }
        case '+1': {
          return self.plusOneGoal;
        }
        case '+0': {
          return self.goal;
        }
        case '-1': {
          return self.minusOneGoal;
        }
        case '-2': {
          return self.minusTwoGoal;
        }
        default: return undefined;
      }
    },
  }))
  .actions((self) => ({
    updateProgress: flow(function* updateProgress(note: string, score: string): any {
      const root = getRoot<IRootStore>(self);

      const data: UpdateProgressData = {
        memberId: (root.member as Instance<typeof Member>).id,
        goalId: self.id,
        note,
        score: score ? Number(score) : null,
        scoreDescriptor: score ? self.labelByScore(score) : null,
      };

      const newProgress = yield root.hades?.request('memberGoal.updateProgress', data);

      // @ts-ignore
      const lastReportedScore = self.lastProgressUpdate.score;

      self.progressUpdates.push(newProgress);

      const { me, member } = getRoot<IRootStore>(self);
      trackGASSaveUpdate({
        coachId: me?.id,
        memberId: member?.id,
        organizationName: member?.organization.name,
        lastReportedScore,
        dateCreated: newProgress.createdAt,
        desiredOutcome: self.goal,
        goalName: self.name,
        progressUpdate: note,
        scoreUpdate: score,
      });
    }),
  }))
  .views((self) => ({
    get isCompleted() {
      return (
        !!self.minusTwoGoal
        && !!self.minusOneGoal
        && !!self.goal
        && !!self.plusOneGoal
        && !!self.plusTwoGoal
      );
    },

    get formattedStartAt() {
      return self.startAt ? self.startAt.format('MMMM DD, YYYY') : 'Not defined';
    },

    get formattedEndAt() {
      return self.endAt ? self.endAt.format('MMMM DD, YYYY') : 'Not defined';
    },

    get formattedGoalType(): string {
      switch (self.type) {
        case 'twoway': return 'Two-Way';
        case 'oneway': return 'One-Way';
        case 'maintenance': return 'Maintenance';
        default: return '';
      }
    },

    get progressUpdateScaleOptions(): ScaleOption[] {
      return [
        { score: '+2', label: self.plusTwoGoal as string },
        { score: '+1', label: self.plusOneGoal as string },
        { score: '+0', label: self.goal as string },
        { score: '-1', label: self.minusOneGoal as string },
        { score: '-2', label: self.minusTwoGoal as string },
      ];
    },

    get initialLevelScore(): string {
      switch (self.type) {
        case 'twoway': return '-1';
        case 'oneway': return '-2';
        case 'maintenance': return '0';
        default: return '';
      }
    },

    get initialLevelLabel(): string {
      switch (self.type) {
        case 'twoway': return self.minusOneGoal as string;
        case 'oneway': return self.minusTwoGoal as string;
        case 'maintenance': return self.goal as string;
        default: return '';
      }
    },

    get creationLevel() {
      return {
        score: this.initialLevelScore,
        label: this.initialLevelLabel,
      };
    },

    get currentLevelLabel(): string {
      return this.lastProgressUpdateWithScoreChange
        ? this.lastProgressUpdateWithScoreChange.label
        : this.creationLevel.label;
    },

    get currentLevelScore(): string {
      return this.lastProgressUpdateWithScoreChange
        ? this.lastProgressUpdateWithScoreChange.score
        : this.creationLevel.score;
    },

    // find the last report update that has a score value
    get lastProgressUpdateWithScoreChange(): LevelSnapshot | null {
      const reversedUpdates = self.progressUpdates.reverse();

      const progressUpdateWithScaleChange = reversedUpdates.find(
        (progress) => Number.isInteger(progress.score),
      );

      return progressUpdateWithScaleChange ? {
        score: scoreNumberToStr(progressUpdateWithScaleChange.score as number),
        label: progressUpdateWithScaleChange.scoreDescriptor as string,
        createdAt: progressUpdateWithScaleChange.createdAt,
        note: progressUpdateWithScaleChange.note as string,
      } : null;
    },

    get lastProgressUpdateDate() {
      const date = this.lastProgressUpdateWithScoreChange?.createdAt || self.createdAt;

      return `Last reported score on ${moment(date).format('MMM DD, YYYY')}`;
    },

    get lastProgressUpdate(): LevelSnapshot {
      if (!self.progressUpdates.length || !this.lastProgressUpdateWithScoreChange) {
        return {
          createdAt: self.createdAt,
          label: this.initialLevelLabel,
          score: this.initialLevelScore,
          note: null,
        };
      }
      return this.lastProgressUpdateWithScoreChange;
    },
  }));

export default Goal;
