import { types } from 'mobx-state-tree';
import DateTime from '@utils/mst/types/DateTime';

import Coach from '@stores/Coach';

const Progress = types.model('Progress', {
  id: types.identifierNumber,
  note: types.maybe(types.string),
  score: types.maybeNull(types.number), // validate between -2 to +2
  scoreDescriptor: types.maybe(types.string),
  createdBy: types.maybe(types.reference(Coach)),
  createdAt: types.maybe(DateTime),
})
  .views((self) => ({
    get formattedCreatedAt() {
      return self.createdAt.format('MMMM DD, YYYY');
    },
    get formattedScore() {
      if (self.score === null) {
        return 'NC';
      }
      return self.score > 0 ? `+${self.score.toString()}` : self.score.toString();
    },
  }));

export default Progress;
