import {
  addVisibilityChangeHandler,
  removeVisibilityChangeHandler,
} from '@utils/pageVisibility';

let titleInterval: NodeJS.Timer;
const TITLE_CHECK_INTERVAL = 1500;

const setNewMessageTitle = (initialRouteTitle: string, newTitle: string) => {
  if (document.title !== newTitle) {
    document.title = newTitle;
  } else {
    document.title = initialRouteTitle;
  }
};

const clearTitleInterval = (initialRouteTitle: string) => {
  clearInterval(titleInterval);
  document.title = initialRouteTitle;
};

const handleVisibilityChange = (initialRouteTitle: string) => {
  if (!document.hidden) {
    clearTitleInterval(initialRouteTitle);
  }
};

let initialRouteTitle = document.title;

const clickListener = () => {
  clearTitleInterval(initialRouteTitle);
  document.removeEventListener('click', clickListener);
};

const visibilityChangeHandler = () => {
  handleVisibilityChange(initialRouteTitle);
  removeVisibilityChangeHandler(visibilityChangeHandler);
};

export const runTitleNotification = (newTitle: string) => {
  if (document.title.includes('Sibly')) {
    initialRouteTitle = document.title;
  }

  if (titleInterval) {
    clearTitleInterval(initialRouteTitle);
  }

  setNewMessageTitle(initialRouteTitle, newTitle);

  titleInterval = setInterval(() => {
    setNewMessageTitle(initialRouteTitle, newTitle);
  }, TITLE_CHECK_INTERVAL);

  addVisibilityChangeHandler(visibilityChangeHandler);

  document.addEventListener('click', clickListener);
};
