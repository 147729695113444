import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { flow } from 'mobx-state-tree';
import { runTitleNotification } from '@utils/runTitleNotification';
import { Logger } from '@utils/logger';
import { MonitoringService } from '@services';

const notificationWorkaroundDelay = 60000; // 1 minute

export interface NotificationWorkaroundRequest {
  startAt: string;
  endAt: string;
  feNewMemberAlertCount: number;
  feNewSendbirdMessageIds: number[];
}

export interface NotificationWorkaroundResponse {
  startAt: string;
  endAt: string;
  newMemberAlertCount: number;
  newMessageCount: number;
  newSendbirdMessageIds: number[];
  newMemberAlertCountMismatch: boolean;
  newMessagesMismatch: boolean;
}

/**
 * Notification workaround: Poll BE to keep dashboard in sync with new member alerts and messages
 */
export const NotificationWorkaroundMixin = {
  // TODO: If we type `self` as IRootStore, it causes many TS errors in other files. Investigate and fix
  actions: (self: any) => ({
    checkForNotifications: flow(function* checkForNotifications() {
      try {
        const endAt = new Date().toISOString();

        const request: NotificationWorkaroundRequest = {
          startAt: self.startTimeForNotificationCheck,
          endAt,
          feNewMemberAlertCount: self.newMemberAlertCount,
          // make a copy just in case `self.newSendbirdMessageIds` changes
          feNewSendbirdMessageIds: [...self.newSendbirdMessageIds],
        };

        const requestLogMessage = `NotificationWorkaroundMixin:checkForNotifications request startAt=${request.startAt} endAt=${request.endAt} feNewMemberAlertCount=${request.feNewMemberAlertCount} feNewMessageIds=${request.feNewSendbirdMessageIds} coachId=${self.me?.id}`;
        Logger.log(requestLogMessage);
        SiblySDK.Monitoring.addBreadcrumb({
          message: requestLogMessage,
          category: 'NotificationWorkaroundMixin',
        });

        const result: NotificationWorkaroundResponse = yield self.hades.request(
          'app.notificationWorkaround',
          request,
        );

        const resultLogMessage = `NotificationWorkaroundMixin:checkForNotifications result startAt=${result.startAt} endAt=${result.endAt} newMemberAlertCount=${result.newMemberAlertCount} newMessageCount=${result.newMessageCount} newSendbirdMessageIds=${result.newSendbirdMessageIds} newMemberAlertCountMismatch=${result.newMemberAlertCountMismatch} newMessagesMismatch=${result.newMessagesMismatch}`;
        Logger.log(resultLogMessage);
        SiblySDK.Monitoring.addBreadcrumb({
          message: resultLogMessage,
          category: 'NotificationWorkaroundMixin',
        });

        // if mismatch, refresh left hand nav
        let refreshLeftHandNav = false;

        if (result.newMemberAlertCountMismatch) {
          Logger.error('NotificationWorkaroundMixin:checkForNotifications New member alert mismatch occurred, refreshing left hand nav');
          MonitoringService.addLog({
            message: '[MST][NotificationWorkaroundMixin] checkForNotifications New member alert mismatch occurred',
            logGroup: '[MST][NotificationWorkaroundMixin] checkForNotifications',
            logRecordType: 'CheckForNotificationsError',
            error: new Error('New member alert mismatch occurred'),
            details: {
              requestLogMessage,
              resultLogMessage,
            },
          });

          refreshLeftHandNav = true;
        }

        if (result.newMessagesMismatch) {
          Logger.error('NotificationWorkaroundMixin:checkForNotifications New message mismatch occurred, refreshing left hand nav');
          MonitoringService.addLog({
            message: '[MST][NotificationWorkaroundMixin] checkForNotifications New message mismatch occurred',
            logGroup: '[MST][NotificationWorkaroundMixin] checkForNotifications',
            logRecordType: 'CheckForNotificationsError',
            error: new Error('New message mismatch occurred'),
            details: {
              requestLogMessage,
              resultLogMessage,
            },
          });

          refreshLeftHandNav = true;
        }

        if (refreshLeftHandNav) {
          yield self.refreshLeftHandNav();
        }

        if (request.feNewMemberAlertCount < result.newMemberAlertCount) {
          // if newMemberAlertCount is less than API result, we missed a new member alert, so pop alert
          Logger.error('NotificationWorkaroundMixin:checkForNotifications We missed a new member alert, popping alert');
          self.setNewMemberAlert(true);
          self.playUnassignedAudio();
          runTitleNotification('🔔 A new Sibly member is here');
        } else if (result.newMessagesMismatch) {
          // we missed a message
          Logger.error('NotificationWorkaroundMixin:checkForNotifications We missed a message, showing alert');
          if (self.member) {
            // refresh sendbird chat (re-load messages in channel)
            yield self.loadMemberMessages();
          }
          self.showAlert('A message was missed. System has been updated and all messages are up to date.');
          self.playNewMessageMissedAudio();
        }

        self.startTimeForNotificationCheck = endAt;
        // just in case activity happens between start of check and end of check
        self.newMemberAlertCount -= request.feNewMemberAlertCount;
        self.newSendbirdMessageIds = self.newSendbirdMessageIds.filter(
          (newMessageId: number) =>
            !request.feNewSendbirdMessageIds.find(
              (reqMessageId: number) => reqMessageId === newMessageId,
            ),
        );
      } catch (error) {
        Logger.error('NotificationWorkaroundMixin:checkForNotifications Error checking for notifications', error );
        self.showAlert('Notification check failed. Please refresh.', 'danger', error);
      }

      self.startCheckForNotifications();
    }),

    startCheckForNotifications: (): void => {
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      setTimeout(self.checkForNotifications, notificationWorkaroundDelay);
    },

    incrementNewMemberAlertCount(): void {
      Logger.log('NotificationWorkaroundMixin:incrementNewMemberAlertCount Increment new member alert count');
      self.newMemberAlertCount += 1;
    },

    trackNewMessage(sendbirdMessageId: number): void {
      Logger.log('NotificationWorkaroundMixin:trackNewMessage Track new message', sendbirdMessageId);
      // Make sure we don't push a duplicate message
      const messageAlreadyTracked = Boolean(
        self.newSendbirdMessageIds.find(
          (newMessageId: number) => newMessageId === sendbirdMessageId,
        ),
      );
      if (messageAlreadyTracked) {
        Logger.log('NotificationWorkaroundMixin:trackNewMessage Message already tracked, ignoring', sendbirdMessageId);
      } else {
        self.newSendbirdMessageIds.push(sendbirdMessageId);
        Logger.log('NotificationWorkaroundMixin:trackNewMessage Message tracked', sendbirdMessageId);
      }
    },

    refreshLeftHandNav: flow(function* refreshLeftHandNav() {
      const { members } = yield self.hades.request('member.list');
      self.addMembers(members);
    }),
  }),

  volatile: () => ({
    newMemberAlertCount: 0,
    newSendbirdMessageIds: [],
    startTimeForNotificationCheck: new Date().toISOString(),
  }),
};
