// @ts-nocheck
import { detach, flow, getRoot, types } from 'mobx-state-tree';
import moment from 'moment-timezone';
import { trackSendReport } from '@utils/analytics';
import MembersReports from './MembersReports';
import ReportTemplate from './ReportTemplate';

export const reportsInitialModelData = {
  reportTemplates: [],
  upcomingReportsMembers: [],
  inReviewReportsMembers: [],
  totalMembersReportsInReview: 0,
  totalMembersReportsUpcoming: 0,
  isEditingReport: false,
  isViewingReports: false,
};

const INITIAL_PAGE_SIZE = 10;
const UPCOMING_TYPE = 'upcoming';
const IN_REVIEW_TYPE = 'inReview';

const initialUpcomingReportsFiltersData = {
  lastMessageFrom: moment().subtract(3, 'months').toDate(),
  engaged: true,
};

export const reportsInitialVolatileData = {
  upcomingReportsFilters: initialUpcomingReportsFiltersData,
  upcomingReportsPageNumber: 1,
  inReviewReportsPageNumber: 1,
  upcomingPageSize: INITIAL_PAGE_SIZE,
  inReviewPageSize: INITIAL_PAGE_SIZE,
  modal: undefined,
  usePaginationInReview: true,
  usePaginationUpcoming: true,
  isLoading: true,
  query: '',
};

const Reports = types
  .model({
    reportTemplates: types.array(ReportTemplate),
    upcomingReportsMembers: types.array(MembersReports),
    inReviewReportsMembers: types.array(MembersReports),
    totalMembersReportsInReview: types.number,
    totalMembersReportsUpcoming: types.number,
  })
  .volatile(() => ({ ...reportsInitialVolatileData }))
  .actions((self) => {
    const { hades } = getRoot(self);

    return {
      getReadyForReviewReports: flow(function* getReadyForReviewReports() {
        const result = yield hades.request('report.members', {
          page: 0,
          size: self.inReviewPageSize,
          forReview: true,
          query: self.query,
        });

        self.inReviewReportsMembers = result.rows;
        self.totalMembersReportsInReview = result.count;
      }),

      getUpcomingReports: flow(function* getUpcomingReports() {
        const { engaged, lastMessageFrom } = self.upcomingReportsFilters;

        const result = yield hades.request('report.members', {
          page: 0,
          size: self.upcomingPageSize,
          engaged,
          lastMessageFrom,
          query: self.query,
        });

        self.upcomingReportsMembers = result.rows;
        self.totalMembersReportsUpcoming = result.count;
        self.upcomingReportsPageNumber = 1;
      }),

      getReportsPage: flow(function* getReportsPage(page, forReview = false) {
        const { engaged, lastMessageFrom } = self.upcomingReportsFilters;

        const result = yield hades.request('report.members', {
          page: page - 1,
          size: forReview ? self.inReviewPageSize : self.upcomingPageSize,
          query: self.query,
          forReview,
          // filters only apply on upcoming reports pagination
          ...(!forReview ? { engaged, lastMessageFrom } : null),
        });

        if (forReview) {
          self.inReviewReportsMembers = result.rows;
          self.inReviewReportsPageNumber = page;
        } else {
          self.upcomingReportsMembers = result.rows;
          self.upcomingReportsPageNumber = page;
        }
      }),

      filtersChange: flow(function* filtersChange(newFilters) {
        self.upcomingReportsFilters = newFilters;

        self.upcomingReportsMembers = [];
        self.totalMembersReportsUpcoming = 0;
        self.usePaginationUpcoming = true;
        yield self.getUpcomingReports();
      }),

      getReportTemplates: flow(function* getReportTemplates() {
        const result = yield hades.request('reportTemplates.list', {});

        if (result) {
          self.reportTemplates = result;
        }
      }),

      createReport: flow(function* createReport(data) {
        const result = yield hades.request('report.create', {
          ...data,
          reportTemplateId: data.reportTemplateId ? parseInt(data.reportTemplateId, 10) : undefined,
        });

        return result;
      }),

      updateReport: flow(function* updateReport(data) {
        const result = yield hades.request('report.update', {
          ...data,
          reportTemplateId: data.reportTemplateId ? parseInt(data.reportTemplateId, 10) : undefined,
          memberId: undefined,
        });

        return result;
      }),

      deleteReportAPI: flow(function* deleteReport(data) {
        const result = yield hades.request('report.delete', data);

        return result;
      }),

      updateMemberData: flow(function* updateMemberData({
        memberId,
        ...attributes
      }) {
        const result = yield hades.request('member.update', {
          memberId,
          employmentProfile: {},
          attributes,
        });

        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const memberFound = self[reportsListName].get(foundMemberIndex);
        const members = self[reportsListName].slice();

        members[foundMemberIndex] = {
          ...memberFound,
          ...attributes,
        };

        self[reportsListName] = members;
        return result;
      }),

      getReportVersions: flow(function* getReportVersions(reportId) {
        const result = yield hades.request('report.versions', {
          reportId,
        });

        const orderedVersions = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );

        return orderedVersions;
      }),

      saveReport: flow(function* saveReport({ reportId, ...reportData }) {
        let result = null;

        if (reportId) {
          result = yield self.updateReport({ reportId, ...reportData });
        } else {
          result = yield self.createReport(reportData);
        }

        const { memberId } = reportData;

        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const member = self[reportsListName].get(foundMemberIndex);

        if (reportId) {
          member.updateReport(result);
        } else {
          member.addReport(result);
        }

        return result;
      }),

      unengageMemberInReview: flow(function* unengageMemberInReview(
        memberId,
        nextReportDate,
      ) {
        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const memberFound = self[reportsListName].get(foundMemberIndex);
        const report = memberFound.savedReport;

        // save report as "review: false"
        yield self.saveReport({
          memberId,
          reportId: report.id,
          title: report.title || '',
          body: report.body || '',
          reportTemplateId: report.reportTemplateId,
          nextReportDate,
          attachments: {
            benefits: report?.attachments?.benefits || [],
            questionnaires: report?.attachments?.questionnaires || [],
          },
          hasSafetyPlan: report.attachments?.hasSafetyPlan || false,
          send: false,
          review: false,
        });

        // remove member from store if member type filter is set to `engaged`,
        // otherwise move member to upcoming reports list
        if (self.upcomingReportsFilters.engaged) {
          self.removeMemberFromInReviewList(memberFound);
        } else {
          self.moveMemberToUpcomingList(memberFound);
        }
      }),

      changeEngagementOfMemberInUpcoming(memberId, isMemberEngaged) {
        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const memberFound = self[reportsListName].get(foundMemberIndex);

        if (
          (self.upcomingReportsFilters.engaged && !isMemberEngaged) ||
          (self.upcomingReportsFilters.engaged === false && isMemberEngaged)
        ) {
          self.removeMemberFromUpcomingList(memberFound);
        }
      },

      sendReport: flow(function* sendReport(
        { reportId, ...reportData },
        reportInReview,
      ) {
        const data = {
          send: true,
          review: false,
          ...reportData,
        };

        let result;

        // update a saved report with the send flag
        if (reportId) {
          result = yield self.updateReport({ reportId, ...data });
        } else {
          result = yield self.createReport(data);
        }

        const { memberId } = reportData;

        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const member = self[reportsListName].get(foundMemberIndex);

        if (reportId) {
          member.updateReport(result);
        } else {
          member.addReport(result);
        }
        member.updateNextReportDate(reportData.nextReportDate.toString());

        if (data.reportTemplateId) {
          const templateIdInt = parseInt(data.reportTemplateId, 10);
          data.reportTemplateType = self.reportTemplates.find(
            (option) => option.id === templateIdInt,
          );
        }

        // we dont want to track the body of the reports in any analytics event
        trackSendReport({ ...data, body: undefined });

        if (reportInReview) {
          const isMemberEngaged = member.engagedAt;
          if (
            (self.upcomingReportsFilters.engaged && !isMemberEngaged) ||
            (self.upcomingReportsFilters.engaged === false && isMemberEngaged)
          ) {
            self.removeMemberFromInReviewList(member);
          } else {
            self.moveMemberToUpcomingList(member);
          }
        }
        return result;
      }),

      submitReportForReview: flow(function* submitReportForReview({
        reportId,
        ...reportData
      }) {
        const data = {
          review: true,
          ...reportData,
        };

        let result = null;

        // update a saved report with the review flag
        if (reportId) {
          result = yield self.updateReport({ reportId, ...data });
        } else {
          result = yield self.createReport(data);
        }

        const { memberId } = reportData;

        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const member = self[reportsListName].get(foundMemberIndex);
        if (reportId) {
          member.updateReport(result);
        } else {
          member.addReport(result);
        }

        self.moveMemberToInReviewList(member);
        return result;
      }),

      deleteReport: flow(function* deleteReport({ reportId, memberId }) {
        const data = {
          reportId,
        };

        const result = yield self.deleteReportAPI(data);

        const { reportsListName, foundMemberIndex } =
          self.findMemberIndexInListsById(memberId);
        const member = self[reportsListName].get(foundMemberIndex);
        member.deleteReport(reportId);

        return result;
      }),

      changePageSize(newSize, isReviewTable) {
        if (isReviewTable) {
          self.inReviewPageSize = newSize;
        } else {
          self.upcomingPageSize = newSize;
        }
        self.getReportsPage(1, isReviewTable);
      },

      findMemberIndexInListsById(memberId) {
        let foundMemberIndex = self.upcomingReportsMembers.findIndex(
          (member) => member.id === memberId,
        );
        if (foundMemberIndex >= 0) {
          return {
            foundMemberIndex,
            reportsListName: 'upcomingReportsMembers',
          };
        }
        foundMemberIndex = self.inReviewReportsMembers.findIndex(
          (member) => member.id === memberId,
        );
        return {
          foundMemberIndex,
          reportsListName: 'inReviewReportsMembers',
        };
      },

      moveMemberToInReviewList(member) {
        const removed = self.removeMemberFromUpcomingList(member);
        self.inReviewReportsMembers.push(removed);
        self.totalMembersReportsInReview += 1;
      },

      moveMemberToUpcomingList(member) {
        const removed = self.removeMemberFromInReviewList(member);
        self.upcomingReportsMembers.push(removed);
        self.totalMembersReportsUpcoming += 1;
      },

      removeMemberFromUpcomingList(member) {
        const nodeRemoved = detach(member);
        self.totalMembersReportsUpcoming -= 1;
        return nodeRemoved;
      },

      removeMemberFromInReviewList(member) {
        const nodeRemoved = detach(member);
        self.totalMembersReportsInReview -= 1;
        return nodeRemoved;
      },

      closeReportsModal() {
        self.modal = undefined;
      },

      setModal(modal) {
        self.modal = modal;
      },

      /*
       first report is sent automatically on member registration
       second report should be sent 3 days after the first report is sent(or checked as engaged)
       third report should be sent 14 days after the second report is sent(or checked as engaged)
       fourth report should be sent 30 days after the third report is sent(or checked as engaged)

       after that, they should be sent every 30 days
       as long as the member is engaged(sent a message in past 29 days)
      */
      getReportNextDate(reportsSentCount) {
        const now = moment();
        switch (reportsSentCount) {
          case 1:
            return now.add(3, 'days');
          case 2:
            return now.add(14, 'days');
          default:
            return now.add(30, 'days');
        }
      },

      // ex: for currentPage being 2 and pageSize being 10 will return Array [11..20]
      getMemberCounts(currentPage, isReviewTable) {
        const pageSize = isReviewTable
          ? self.inReviewPageSize
          : self.upcomingPageSize;
        const initialPageCount = (currentPage - 1) * pageSize + 1;
        return new Array(pageSize)
          .fill(null)
          .map((_, index) => initialPageCount + index);
      },

      disablePagination(isReviewTable) {
        if (isReviewTable) {
          self.usePaginationInReview = false;
          return;
        }
        self.usePaginationUpcoming = false;
      },

      getTableData(isReviewTable) {
        const currentPage = isReviewTable
          ? self.inReviewReportsPageNumber
          : self.upcomingReportsPageNumber;

        return {
          tableType: isReviewTable ? IN_REVIEW_TYPE : UPCOMING_TYPE,
          membersList: isReviewTable
            ? self.inReviewReportsMembers
            : self.upcomingReportsMembers,
          usePagination: isReviewTable
            ? self.usePaginationInReview
            : self.usePaginationUpcoming,
          memberCounts: self.getMemberCounts(currentPage, isReviewTable),
        };
      },

      setLoading(value) {
        self.isLoading = value;
      },

      setQuery(value) {
        self.query = value;
      },
    };
  })
  .views((self) => ({
    get reviewPagesLength() {
      if (!self.inReviewReportsMembers) return 0;
      if (!self.usePaginationInReview) return 1;
      return Math.ceil(
        self.totalMembersReportsInReview / self.inReviewPageSize,
      );
    },
    get upcomingPagesLength() {
      if (!self.upcomingReportsMembers) return 0;
      if (!self.usePaginationUpcoming) return 1;
      return Math.ceil(
        self.totalMembersReportsUpcoming / self.upcomingPageSize,
      );
    },
  }));

export default Reports;
