import { useState } from 'react';

import cn from 'classnames';

import { ReactComponent as SettingsIcon } from '@icons/settings-icon.svg';

import Tooltip from '@components/Tooltip';
import ChatNavigationSettingModal from './Modal';
import styles from './chat-navigation-settings.module.scss';

const ChatNavigationSetting = () => {
  const [visible, setModalShow] = useState(false);
  const toggleVisible = () => setModalShow(!visible);

  return (
    <>
      <div className="d-flex align-items-center">
        <Tooltip placement="bottom" title="Settings">
          <button
            data-testid="settings-button"
            type="button"
            onClick={toggleVisible}
            className={cn(
              'text-center',
              'mx-2',
              'p-0',
              styles.btn,
            )}
          >
            <SettingsIcon/>
          </button>
        </Tooltip>
      </div>
      <ChatNavigationSettingModal visible={visible} onClose={toggleVisible} />
    </>
  );
};

export default ChatNavigationSetting;
