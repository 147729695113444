import { SiblySDK } from '@sibly/sibly-sdk-browser';
import EventEmitter from 'events';
import SendBirdClient, {
  ConnectionState as SendbirdConnectionState,
  LogLevel,
} from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelHandler,
  GroupChannelModule,
} from '@sendbird/chat/groupChannel';
import {
  AdminMessage,
  BaseMessage,
  FileMessage,
  FileMessageCreateParams,
  MessageListParams,
  PreviousMessageListQuery,
  UserMessage,
  UserMessageCreateParams,
} from '@sendbird/chat/message';
import moment from 'moment-timezone';

import { Logger } from '@utils/logger';

export const ConnectionState = {
  // NOT_STARTED is not part of the sdk states, but we will use it to know if the connection was
  NOT_STARTED: 'NOT_STARTED',
  ...SendbirdConnectionState,
};

export class SendBird {
  emitter: EventEmitter;

  client: ReturnType<
    typeof SendBirdClient.init<InstanceType<typeof GroupChannelModule>[]>
  >;

  id: string;

  token: string;

  channel?: GroupChannel;

  query?: PreviousMessageListQuery;

  connectionCheckInterval?: ReturnType<typeof setInterval>;

  lastConnectionState?: string;

  constructor(appId: string, id: string, token: string) {
    this.emitter = new EventEmitter();

    this.client = SendBirdClient.init({
      appId,
      modules: [new GroupChannelModule()],
    });

    this.id = id;
    this.token = token;
  }

  async connect(): Promise<SendBird['client']> {
    SiblySDK.Monitoring.addBreadcrumb({
      message: 'connect',
      category: 'SendBirdService',
    });

    if (this.client.connectionState === ConnectionState.OPEN) {
      return this.client;
    }

    await this.client.connect(this.id, this.token);

    if (this.connectionCheckInterval) {
      clearInterval(this.connectionCheckInterval);
    }

    this.connectionCheckInterval = setInterval(() => {
      const state = this.client.connectionState;

      if (state === ConnectionState.CLOSED) {
        this.client.reconnect();
      }

      if (state !== this.lastConnectionState) {
        Logger.log(
          `Sendbird connectionStateChange: was ${this.lastConnectionState}, now ${state}`,
        );
        SiblySDK.Monitoring.addBreadcrumb({
          message: `Sendbird connectionStateChange: was ${this.lastConnectionState}, now ${state}`,
          category: 'SendBirdService',
        });

        this.lastConnectionState = state;

        this.emitter.emit('connectionStateChange', state);
      }
    }, 250);

    return this.client;
  }

  updateChannelHandler(): void {
    const handler: GroupChannelHandler = new GroupChannelHandler({
      onMessageReceived: (...args) => {
        SiblySDK.Monitoring.addBreadcrumb({
          message: 'handler.onMessageReceived',
          category: 'SendBirdService',
        });
        this.emitter.emit('message', ...args);
      },
      onTypingStatusUpdated: () => {
        if (this.channel) {
          SiblySDK.Monitoring.addBreadcrumb({
            message: 'handler.onTypingStatusUpdated',
            category: 'SendBirdService',
          });
          this.emitter.emit(
            'typingChange',
            this.channel,
            this.channel?.isTyping,
          );
        }
      },
    });

    this.client.groupChannel.addGroupChannelHandler(
      'GLOBAL_CHANNEL_HANDLER',
      handler,
    );
  }

  async openChannel(channelUrl: string): Promise<SendBird['channel']> {
    try {
      // set log level verbose in sendbird sdk during this function execution
      this.client.logLevel = LogLevel.VERBOSE;
      SiblySDK.Monitoring.addBreadcrumb({
        message: `openChannel ${channelUrl}`,
        category: 'SendBirdService',
      });

      Logger.log(`opening Sendbird channel: ${channelUrl}`);
      await this.connect();

      const channel = await this.client.groupChannel.getChannel(channelUrl);

      const query = channel.createPreviousMessageListQuery({
        limit: 30,
        reverse: false,
      });

      this.channel = channel;
      Logger.log(`this.channel = ${channel.url} in Sendbird service`);
      this.query = query;

      this.updateChannelHandler();
      Logger.log(`finished opening Sendbird channel: ${channelUrl}`);
      return channel;
    } finally {
      // cleanup log level
      this.client.logLevel = LogLevel.WARN;
    }
  }

  setTyping(bool: boolean): Promise<void> {
    SiblySDK.Monitoring.addBreadcrumb({
      message: `setTyping ${bool}`,
      category: 'SendBirdService',
    });

    if (this.channel) {
      return bool ? this.channel.startTyping() : this.channel.endTyping();
    }
    throw new Error('Channel is undefined');
  }

  async loadMessages(): Promise<
    Array<UserMessage | FileMessage | AdminMessage | BaseMessage>
  > {
    SiblySDK.Monitoring.addBreadcrumb({
      message: 'loadMessages',
      category: 'SendBirdService',
    });

    if (this.query?.isLoading) {
      return [];
    }

    return new Promise((resolve, reject) => {
      if (this.query) {
        this.query.load().then((messages) => {
          resolve(messages);
        });
      } else {
        reject(new Error('PreviousMessageListQuery is undefined'));
      }
    });
  }

  async getMessagesByMessageId(
    messageId: number,
    channelUrl: string,
    {
      prevResultSize,
      nextResultSize,
      isInclusive = false,
    }: {
      prevResultSize: number;
      nextResultSize: number;
      isInclusive: boolean;
    },
  ): Promise<Array<UserMessage | FileMessage | AdminMessage | BaseMessage>> {
    SiblySDK.Monitoring.addBreadcrumb({
      message: 'getMessagesByMessageId',
      category: 'SendBirdService',
    });

    // check that sendbird service channelUrl didn't change
    if (this.channel && this.channel.url === channelUrl) {
      const params: MessageListParams = {
        prevResultSize,
        nextResultSize,
        isInclusive,
      };

      return this.channel.getMessagesByMessageId(messageId, params);
    }
    throw new Error('Channel is undefined');
  }

  async sendMessage(
    text: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    data: any = {},
    channelUrl: string,
  ): Promise<UserMessage> {
    SiblySDK.Monitoring.addBreadcrumb({
      message: 'sendMessage',
      category: 'SendBirdService',
    });

    return new Promise<UserMessage>((resolve, reject) => {
      if (this.channel) {
        if (channelUrl !== this.channel.url) {
          reject(
            new Error('Channel URL was not set before sending a message.'),
          );
          return;
        }

        data.sender_timezone = moment.tz.guess(); // Ex: America/Los_Angeles
        data.sender_localtime = moment().format('YYYY-MM-DD HH:mm:ss');

        const params: UserMessageCreateParams = {
          message: text,
          data: JSON.stringify(data),
        };

        this.channel
          .sendUserMessage(params)
          .onFailed((err) => {
            const errorMessage = `Sendbird error: ${err.message}`;
            reject(new Error(errorMessage));
          })
          .onSucceeded((message) => {
            resolve(message as UserMessage);
          });
      } else {
        const message = 'Channel in Sendbird service is undefined.';
        reject(new Error(message));
      }
    });
  }

  async sendImageMessage(file: File, data: any = {}): Promise<FileMessage> {
    SiblySDK.Monitoring.addBreadcrumb({
      message: 'sendImageMessage',
      category: 'SendBirdService',
    });

    return new Promise<FileMessage>((resolve, reject) => {
      if (this.channel) {
        data.sender_timezone = moment.tz.guess(); // Ex: America/Los_Angeles
        data.sender_localtime = moment().format('YYYY-MM-DD HH:mm:ss');

        const params: FileMessageCreateParams = {
          file,
          data: JSON.stringify(data),
        };

        this.channel
          .sendFileMessage(params)
          .onFailed((err) => {
            reject(err);
          })
          .onSucceeded((message) => {
            resolve(message as FileMessage);
          });
      } else {
        reject(new Error('Channel is undefined'));
      }
    });
  }

  async markAsRead(): Promise<void> {
    SiblySDK.Monitoring.addBreadcrumb({
      message: 'markAsRead',
      category: 'SendBirdService',
    });

    if (this.channel) {
      return this.channel.markAsRead();
    }
    throw new Error('Channel is undefined');
  }

  async destroy(): Promise<void> {
    this.query = undefined;
    this.channel = undefined;

    SiblySDK.Monitoring.addBreadcrumb({
      message: 'destroy',
      category: 'SendBirdService',
    });

    return this.client.disconnect();
  }

  on(event: string | symbol, fn: (...args: unknown[]) => void): void {
    this.emitter.addListener(event, fn);
  }
}
