// @ts-nocheck
import { types } from 'mobx-state-tree';

import DateTime from '@utils/mst/types/DateTime';
import { momentPT } from '@utils/dates';
import Coach from './Coach';

const Note = types.model('Note', {
  id: types.identifier,
  text: types.string,
  updatedBy: types.reference(Coach),
  lastUpdated: types.maybeNull(DateTime),
}).views((self) => ({
  get formattedLastUpdated() {
    return self.lastUpdated && momentPT(self.lastUpdated).format('hh:mm A [PST] MMM DD, YYYY');
  },
}));

export default Note;
