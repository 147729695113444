export const SAFETY_PLAN = 'SAFETY_PLAN';
export const QUESTIONNAIRE = 'QUESTIONNAIRE';
export const RESOURCE = 'RESOURCE';
export const DEFAULT_SIBLY_BENEFITS_URL =
  'https://docs.google.com/spreadsheets/d/1dMTHiP1o9u1z4Zt2xJmA3aKZPTyPNtbJKG8neMHp8Yc/edit#gid=340070372';
export const VAULT_BENEFITS_URL =
  'https://docs.google.com/spreadsheets/d/1fpOE4KJbL_2CEe7dKLrzg07BCUlo9CFOuZWPxX8f2qQ/edit#gid=1616788344';
export const ELIGIBILITY_FORM_CHAT_TEMPLATE =
  'I’d be happy to share an employer resource with you that might be helpful. It looks like you haven’t completed the Your Health Plan Info section in your profile. You can access it here. If you’re not sure how to answer the questions, there are instructions on what to do next.';

export const NPS_QUESTIONNAIRE_ID = 3;
