import Coach from '@stores/Coach';
import DateTime from '@utils/mst/types/DateTime';
import { types } from 'mobx-state-tree';
import moment from 'moment-timezone';

const LastUpdated = types.model('LastUpdated', {
  createdAt: DateTime,
  createdBy: types.reference(Coach),
}).views((self) => ({
  tooltipDateFormat() {
    return moment(self.createdAt).format('MMM DD YYYY');
  },
}));

export default LastUpdated;
