// @ts-nocheck
import { AbilityBuilder, Ability } from '@casl/ability';

import { isString, get } from 'lodash';

export default (template = {}, vars = {}) => {
  const payload = JSON.parse(
    isString(template) ? template : JSON.stringify(template ?? ''),
    (key, rawValue) => {
      if (rawValue[0] !== '$') {
        return rawValue;
      }

      const name = rawValue.slice(2, -1);
      const value = get(vars, name);

      if (typeof value === 'undefined') {
        throw new ReferenceError(`Variable ${name} is not defined`);
      }

      return value;
    },
  );
  const { can, rules } = new AbilityBuilder(Ability);

  if (Array.isArray(payload)) {
    payload.forEach(({ subject, actions }) =>
      (Array.isArray(actions) ? actions : []).forEach((action) =>
        can(action, subject),
      ),
    );
  } else if (Array.isArray(payload.actions) && isString(payload.subject)) {
    const { subject, actions } = payload;
    actions.forEach((action) => can(action, subject));
  }

  return new Ability(rules);
};
