import {
  flow, IMSTArray, SnapshotIn, types,
} from 'mobx-state-tree';
import Hades from '@services/Hades';
import { getRootStore } from '@stores/ApplicationInterfaces';
import MemberSearchResult from './MemberSearchResult';

type SearchAPIResponse = Array<{
  id: number;
  acute: boolean;
  organization: string;
  name: string;
  status: string;
  startAt: Date;
  lastMessageTimestamp: Date;
}>

const MemberSearchMetadata = types.model(
  'MemberSearchMetadata',
  {
    page: 1,
    limit: 8,
    total: 0,
  },
);

const MemberSearch = types.model(
  'MemberSearch',
  {
    results: types.array(MemberSearchResult),
    meta: MemberSearchMetadata,
  },
)
  .actions((self) => ({
    search: flow(function* search(queryParams) {
      const root = getRootStore(self);
      const response = yield (root.hades as Hades).request(
        'member.search',
        { ...queryParams },
      );

      const { results, meta } = response as {
        results: SearchAPIResponse;
        meta: SnapshotIn<typeof MemberSearchMetadata>;
      };

      const mappedResults = results.map(({
        id,
        acute,
        organization,
        name,
        status,
        startAt,
        lastMessageTimestamp,
      }) => MemberSearchResult.create({
        id,
        acute,
        organization,
        name,
        status,
        startAt,
        lastMessageTimestamp,
      }));

      self.results = mappedResults as SnapshotIn<IMSTArray<typeof MemberSearchResult>>;
      self.meta = MemberSearchMetadata.create(meta);
    }),
  }))
  .views((self) => ({
    get organizations() {
      const root = getRootStore(self);
      const orgs = Array.from(root.organizations.values()).map((org) => org.name);

      return orgs;
    },

    get organizationsForMemberSearch() {
      const root = getRootStore(self);
      const orgs = Array.from(root.organizations.values())
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((orgA, orgB) => orgA.label.localeCompare(orgB.label));

      return orgs;
    },
  }));

export default MemberSearch;
