import { types } from 'mobx-state-tree';
import moment, { Moment } from 'moment-timezone';

import { momentPT } from '@utils/dates';
import DateTime from '@utils/mst/types/DateTime';
import Coach from './Coach';

export const DailySummary = types
  .model('DailySummary', {
    id: types.identifierNumber,
    text: types.string,
    date: DateTime, // moment object with value like "2023-03-13T00:00:00.000Z"
    updatedAt: DateTime,
    updatedBy: types.maybe(types.reference(Coach)),
  })
  .views((self) => {
    const views = {
      get dateInUtc(): Moment {
        // By default, moment parses and displays in local time.
        // If you want to parse or display a moment in UTC, you can use moment.utc()
        // While in UTC mode, all display methods will display in UTC time instead of local time
        return moment(self.date).utc();
      },
  
      get searchResultDateInUtc(): string {
        return views.dateInUtc.format('DD MMM YYYY');
      },
  
      get formattedUpdatedAtTimestamp(): string {
        return momentPT(self.updatedAt).format('hh:mm A [PST] MMM DD, YYYY');
      },
    };

    return views;
  });
