import ModalDialog, { ModalDialogProps } from 'react-bootstrap/ModalDialog';
import Draggable, { DraggableProps } from 'react-draggable';

type Props = {
  bounds?: DraggableProps['bounds'];
} & ModalDialogProps;

const DraggableModal = (props: Props) => {
  const { bounds, ...dialogProps } = props;

  return (
    <Draggable
      bounds={bounds}
      handle=".modal-header"
      cancel=".prevent-modal-drag"
    >
      <ModalDialog {...dialogProps} />
    </Draggable>
  );
};

DraggableModal.defaultProps = {
  bounds: false,
};

export default DraggableModal;
