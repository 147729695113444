// @ts-nocheck
import { types } from 'mobx-state-tree';

const ReportCoach = types
  .model('ReportCoach', {
    name: types.string,
  });

const Attachment = types.model('Attachment', {
  id: types.identifierNumber,
  title: types.string,
  runId: types.optional(types.maybe(types.number)),
});

const Attachments = types.model('Attachments', {
  benefits: types.array(Attachment),
  questionnaires: types.array(Attachment),
});

const Report = types.model('Report', {
  id: types.identifierNumber,
  body: types.string,
  createdAt: types.string,
  revision: types.optional(types.number, 1),
  sentAt: types.optional(types.string, ''),
  title: types.string,
  updatedAt: types.string,
  reportTemplateId: types.maybe(types.number),
  hasSafetyPlan: types.maybe(types.boolean),
  attachments: types.maybe(Attachments),
  Coach: types.maybe(ReportCoach),
});

export default Report;
