// @ts-nocheck
import {
  types,
} from 'mobx-state-tree';

import { find, pull } from 'lodash';

import moment from 'moment-timezone';

import { coachTimezoneAbbreviation, isAValidDate } from '@utils/dates';
import DateTime from '../utils/mst/types/DateTime';

function formattedQuestionnaireDate(date) {
  return moment(date).format(`hh:mma [${coachTimezoneAbbreviation}] MMM DD, YYYY`);
}

const QuestionnaireAnswer = types
  .model('QuestionnaireAnswer', {
    id: types.identifierNumber,
    value: types.union(
      types.string,
      types.number,
      types.array(types.string),
    ),
    runId: types.number,
    stepId: types.number,
    createdAt: DateTime,
  });

const QuestionnaireStep = types
  .model('QuestionnaireStep', {
    id: types.identifierNumber,
    text: types.string,
    firstQuestion: types.boolean,
    presentation: types.number,
  });

const Questionnaire = types
  .model('Questionnaire', {
    id: types.identifierNumber,
    name: types.string,
    answers: types.array(QuestionnaireAnswer), // only holds latest answers
    steps: types.array(QuestionnaireStep),
    startedAt: types.maybe(DateTime),
    finishedAt: types.maybe(DateTime),
    legacy: types.boolean,
  })
  .views((self) => ({
    get formattedAnswers() {
      return self.steps.map((step) => {
        // first step is the title question, skip it
        if (step.firstQuestion) {
          return '';
        }

        const answer = find(self.answers, (ans) => ans.stepId === step.id);
        if (!answer) {
          return `${step.text}\n''\n\n`;
        }

        // we have an answer!
        // handle multi-select with free input
        if (step.presentation === 7) {
          let result = `${step.text}\n`;
          // handle skip
          if (answer?.value === 'Skip') {
            return result.concat(answer?.value).concat('\n\n');
          }

          const answers = answer?.value?.toJSON() || [];
          // answers may not be in order, so need to do some jankiness here
          answers.forEach((val, index) => {
            if (val === 'Not listed') {
              result = result.concat(`-${val}: ${answers[index + 1]}\n`);
              pull(answers, answers[index + 1]);
            } else {
              result = result.concat(`-${val}\n`);
            }
          });
          result = result.concat('\n');
          return result;
        }

        return `${step.text}\n${answer.value}\n\n`;
      }).join('');
    },

    get status() {
      if (self.finishedAt === undefined && self.startedAt === undefined) {
        return 'unsent';
      }

      if (self.finishedAt === undefined
        && isAValidDate(self.startedAt)
        && self.answers.length
      ) {
        return 'started';
      }

      if (
        self.finishedAt === undefined
        && isAValidDate(self.startedAt)
        && self.answers.length < 1
      ) {
        return 'sent';
      }

      if (
        self.finishedAt
        && moment(self.finishedAt).isValid()
        && moment(self.finishedAt).isBefore(moment())
      ) {
        return 'finished';
      }

      return new Error('Error getting Questionnaire status');
    },

    get isUnsent() {
      return self.status === 'unsent';
    },

    get isSent() {
      return self.status === 'sent';
    },

    get isStarted() {
      return self.status === 'started';
    },

    get isFinished() {
      return moment(self.finishedAt).isBefore(moment());
    },

    get formmattedOptionsDisplay() {
      if (self.isSent) {
        return `(Sent on ${this.formmattedSentAt})`;
      }

      if (self.isStarted) {
        return `(Started on ${this.formmattedStartAt})`;
      }

      if (self.isFinished) {
        return `(Member finished this on ${this.formattedFinishedAt})`;
      }

      return new Error('Error getting Questionnaire formmattedOptionsDisplay');
    },

    get formmattedSentAt() {
      return formattedQuestionnaireDate(self.startedAt);
    },

    get formmattedStartAt() {
      return formattedQuestionnaireDate(self.answers[0]?.createdAt);
    },

    get formattedFinishedAt() {
      return formattedQuestionnaireDate(self.finishedAt);
    },

  }));

export default Questionnaire;
