import { sharedInMemoryStorage } from '@sibly/sibly-sdk-browser';
import { Environment } from './types';

export const env8: Environment = {
  name: 'development-env8',
  env: 'development',
  mock: false,

  hades: {
    host: 'https://hades.env8.sibly.cloud/v1',
  },

  socket: {
    host: 'wss://34ynukqcz4.execute-api.us-east-1.amazonaws.com/development-env8',
  },

  sendbird: {
    appId: 'BFBA265C-05B8-4DFB-A687-E70B9CB462DD',

    bot: {
      id: 'assistant_bot',
    },

    coach: {
      id: 'sibly-sibly',
      token: '0201b079e9d6a708d8d16d7e86c902e41cccd8d2',
    },
  },

  userSnap: {
    apiKey: 'd7368b35-bd23-451c-93a3-d430984be9b0',
  },

  segment: {
    key: 'NGffpp9mD65ezVJC8x78UFZfAmp29VLW',
    track: true,
  },

  // staging key
  split: {
    key: 'k24f71keue19amad2nbqmqrmt4uupqd0l3g5',
  },

  fullStory: {
    orgId: '182P5Q',
    active: false,
  },

  siblySDK: {
    Auth: {
      userPoolId: 'us-west-1_zE2AOgirg',
      userPoolClientId: '6f24uqruo57vvpqv521tvnr5c9',
    },
    transportOptions: {
      region: 'us-west-1',
      endpoint:
        'https://vfzvip76kjgu5m6uvl4rqy7xiq.appsync-api.us-west-1.amazonaws.com/graphql',
    },
    appId: 'a84a7a19-b6af-4388-850f-eab27dce8d58',
    storage: sharedInMemoryStorage,
  },

  sessionTimeouts: {
    inactivityDetectionTimeout: 30 * 60 * 1000, // 30 minutes
    logoutModalTimeout: 2 * 60 * 1000, // 2 minutes
  },
};
