// @ts-nocheck
import { getRoot, types } from 'mobx-state-tree';
import Report from './Report';

import DateTime from '../../utils/mst/types/DateTime';

const MembersReports = types
  .model('MembersReports', {
    id: types.identifierNumber,
    name: types.string,
    acute: types.boolean,
    Reports: types.array(Report),
    engagedAt: types.maybeNull(types.union(types.string, DateTime)),
    nextReportDate: types.maybeNull(types.union(types.string, DateTime)),
  })
  .actions((self) => ({
    updateEngagedAt(engagedAt) {
      self.engagedAt = engagedAt;
    },
    updateNextReportDate(nextReportDate) {
      self.nextReportDate = nextReportDate;
    },
  }))
  .actions((self) => ({
    addReport(newReport) {
      const { me } = getRoot(self);
      const { name } = me;
      self.Reports.push({ ...newReport, Coach: { name } });
    },
    updateReport(updatedReport) {
      const { me } = getRoot(self);
      const { name } = me;
      const reportIndex = self.Reports.findIndex((report) => report.id === updatedReport.id);
      if (reportIndex < 0) return;
      self.Reports[reportIndex] = { ...updatedReport, Coach: { name } };
    },
    deleteReport(reportId) {
      self.Reports = self.Reports.filter((report) => report.id !== reportId);
    },
  }))
  .views((self) => ({
    get savedReport() {
      return self.Reports.find((report) => !report.sentAt);
    },
    get sentReports() {
      return self.Reports.filter((report) => !!report.sentAt).reverse();
    },
  }));

export default MembersReports;
