// @ts-nocheck
import moment, { Moment } from 'moment-timezone';

const today = moment();
export const todayInPT = moment.tz(moment(), 'America/Los_Angeles').format('YYYY-MM-DD');

export function getCloserDate({ settingLastUpdated, messageLastUpdated }) {
  if (!settingLastUpdated && !messageLastUpdated) return null;

  if (settingLastUpdated && !messageLastUpdated) return settingLastUpdated;
  if (!settingLastUpdated && messageLastUpdated) return messageLastUpdated;

  if (moment(settingLastUpdated).isAfter(messageLastUpdated)) return settingLastUpdated;

  return messageLastUpdated;
}

export function getRecentUpdater({
  settingLastUpdated, messageLastUpdated, messageLastUpdatedBy, settingLastUpdatedBy,
}) {
  if (!settingLastUpdated && !messageLastUpdated) return null;

  if (settingLastUpdatedBy && !messageLastUpdatedBy) return settingLastUpdatedBy;
  if (!settingLastUpdatedBy && messageLastUpdatedBy) return messageLastUpdatedBy;

  if (moment(settingLastUpdated).isAfter(messageLastUpdated)) return settingLastUpdatedBy;

  return messageLastUpdatedBy;
}

export function isDateBeforeToday(date) {
  return moment(date).isBefore(today, 'd');
}

export function momentPT(date, format: moment.MomentFormatSpecification | undefined = undefined) {
  return moment.tz(date, format, 'America/Los_Angeles');
}

// moment(undefined).isValid() returns true(same as moment().isValid()),
// so we need to handle that case first
export function isAValidDate(date) {
  return (
    date !== undefined
    && moment(date).isValid()
  );
}

function getCoachTimezoneAbbreviation() {
  const timezone = moment.tz.guess();
  const abbr = moment.tz(timezone).zoneAbbr();
  const timezoneDiff = moment.tz(new Date(), timezone).format('Z');

  // not all timezones have an abbreviation,
  // for some of them moment just returns the timezone offset like -3 or +0530
  // see https://momentjs.com/timezone/docs/#/using-timezones/formatting/
  // this regex handles those cases
  const hasAbbreviation = abbr.match(/^\+|-/) === null;
  return hasAbbreviation ? `${abbr}` : `GMT${timezoneDiff}`;
}

export const coachTimezoneAbbreviation = getCoachTimezoneAbbreviation();

const diffInMinutes = (timestamp: Moment) =>
  moment().diff(moment(timestamp), 'minutes');

const diffInHours = (timestamp: Moment) =>
  moment().diff(moment(timestamp), 'hours');

const diffInDays = (timestamp: Moment) =>
  moment().diff(moment(timestamp), 'days');

interface FormattedTimeDiffArgs {
  diff: number;
  unit: 'min' | 'hr' | 'd';
}

const formatTimeDiff = ({
  diff,
  unit,
}: FormattedTimeDiffArgs) => `${diff} ${unit}`;

export const getTimeDiff = (timestamp: Moment): string => {
  const minutes = diffInMinutes(timestamp);
  if (minutes < 60) {
    return formatTimeDiff({ diff: minutes, unit: 'min' });
  }
  const hours = diffInHours(timestamp);
  if (hours < 24) {
    return formatTimeDiff({ diff: hours, unit: 'hr' });
  }
  const days = diffInDays(timestamp);
  return formatTimeDiff({ diff: days, unit: 'd' });
};
