// @ts-nocheck
import { isAValidDate } from '@utils/dates.ts';
import { types } from 'mobx-state-tree';

import moment from 'moment-timezone';

const DateTime = types.custom<string, Moment>({
  name: 'DateTime',
  fromSnapshot: (string) => {
    if (string === undefined) {
      return undefined;
    }

    if (string === null) {
      return null;
    }

    return moment(string);
  },
  toSnapshot: (mDate) => mDate,
  isTargetType: (maybeMoment) => moment.isMoment(maybeMoment),
  getValidationMessage: (snapshot) => {
    if (snapshot === null) {
      return '';
    }

    const mDate = moment(snapshot);
    if (!isAValidDate(mDate)) {
      const message = `"${snapshot}" is not in valid date format.`;

      throw new Error(message);
    }

    return '';
  },
});

export default DateTime;
