import { useState, useEffect } from 'react';
import { PASSWORD_REGEXP } from '@sibly/sibly-sdk-browser';

import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Store from '@store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Modal from '@components/Modal';

import styles from './change-password-modal.module.scss';

const VALIDATION_SCHEMA = Yup.object().shape({
  currentPassword: Yup.string().required('A valid password is required.'),
  newPassword: Yup.string()
    .required('A valid password is required')
    .test('regexp', 'Invalid Password', (value) => {
      if (!value || value.trim() !== value) {
        return false;
      }

      // Contains at least 1 letter
      if (!/[a-zA-Z]+/.test(value)) {
        return false;
      }

      // Contains at least 1 number
      if (!/\d+/.test(value)) {
        return false;
      }

      // Contains at least 1 special character from the following set, or a non-leading, non-trailing space character.
      if (!PASSWORD_REGEXP.test(value)) {
        return false;
      }

      // 8-character minimum length
      return value.length >= 8;
    }),
  retypedPassword: Yup.string()
    .required('Retyped password is required')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});

const INITIAL_VALUES = {
  showCurrentPassword: false,
  showNewPassword: false,
  showRetypedPassword: false,
};

const initFormValues = {
  currentPassword: '',
  newPassword: '',
  retypedPassword: '',
};

export interface ChangePasswordModalProps {
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (values: typeof initFormValues) => Promise<void>;
  visible: boolean;
}

const ChangePasswordModal = observer(
  ({ onClose, isLoading, onSubmit, visible }: ChangePasswordModalProps) => {
    const { showAlert } = Store;

    const [
      { showCurrentPassword, showNewPassword, showRetypedPassword },
      setShowPassword,
    ] = useState(INITIAL_VALUES);

    const handleUpdate = async (values: typeof initFormValues) => {
      try {
        await onSubmit(values);
        showAlert(
          'Coach Information has been successfully updated!',
          'success',
        );
        onClose?.();
      } catch (error) {
        showAlert(
          (error as Error).message ?? 'Could not update information',
          'danger',
          error,
        );
      }
    };

    const { errors, handleChange, handleSubmit, values, setValues } = useFormik(
      {
        initialValues: initFormValues,
        onSubmit: handleUpdate,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: VALIDATION_SCHEMA,
      },
    );

    const handleVisiblePassword = (name: string) => () =>
      setShowPassword((oldValues) => ({
        ...oldValues,
        [name]: !oldValues[name],
      }));

    const hasError = !!Object.keys(errors).length;

    useEffect(() => {
      setValues(initFormValues);
    }, [setValues, visible]);

    return (
      <Modal
        dataTestId="change-password-modal"
        label="change-password"
        loading={isLoading}
        onClose={onClose}
        onSave={handleSubmit}
        size="sm"
        title="Change Password"
        visible={visible}
      >
        <Form className="p-3" onSubmit={handleSubmit}>
          <Form.Group controlId="current-password">
            <Form.Label className="font-weight-bold">
              Current Password
            </Form.Label>
            <InputGroup className="align-items-center">
              <Form.Control
                aria-labelledby="current-password"
                data-testid="current-password"
                name="currentPassword"
                onChange={handleChange}
                type={showCurrentPassword ? 'text' : 'password'}
                value={values.currentPassword}
              />
              <FontAwesomeIcon
                className={cn('text-muted bg-light', styles.passwordEye)}
                icon={showCurrentPassword ? faEyeSlash : faEye}
                onClick={handleVisiblePassword('showCurrentPassword')}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="new-password">
            <Form.Label className="font-weight-bold">New Password</Form.Label>
            <InputGroup className="align-items-center">
              <Form.Control
                aria-label="new-password"
                data-testid="new-password"
                name="newPassword"
                onChange={handleChange}
                type={showNewPassword ? 'text' : 'password'}
                value={values.newPassword}
              />
              <FontAwesomeIcon
                className={cn('text-muted bg-light', styles.passwordEye)}
                icon={showNewPassword ? faEyeSlash : faEye}
                onClick={handleVisiblePassword('showNewPassword')}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="retyped-password">
            <Form.Label className="font-weight-bold">
              Retype new Password
            </Form.Label>
            <InputGroup className="align-items-center">
              <Form.Control
                aria-label="retyped-password"
                data-testid="retyped-password"
                name="retypedPassword"
                onChange={handleChange}
                type={showRetypedPassword ? 'text' : 'password'}
                value={values.retypedPassword}
              />
              <FontAwesomeIcon
                className={cn('text-muted bg-light', styles.passwordEye)}
                icon={showRetypedPassword ? faEyeSlash : faEye}
                onClick={handleVisiblePassword('showRetypedPassword')}
              />
            </InputGroup>
          </Form.Group>
          <p
            className={cn('text-xxs', { 'text-danger': hasError })}
            data-testid="password-info"
          >
            Your new password must be 8 characters or more, contain at least 1
            upper case letter, 1 lower case letter, 1 number, and 1 special
            character. Additionally, no incremental or decremental sequences of
            3 or more, no repeating same character of 3 or more, and no leading
            or trailing spaces
          </p>
        </Form>
      </Modal>
    );
  },
);

export default ChangePasswordModal;
