// @ts-nocheck
import {
  getParent,
  types,
} from 'mobx-state-tree';

import moment from 'moment-timezone';

import DateTime from '../utils/mst/types/DateTime';

const NPSSurvey = types
  .model('NPSSurvey', {
    lastCompleted: DateTime,
    lastSent: DateTime,
  })
  .views((self) => ({
    get lastCompletedDaysAgo() {
      if (!self?.lastCompleted) {
        return 'Never';
      }

      const daysAgo = moment().diff(self?.lastCompleted, 'days');
      return `${daysAgo} days ago`;
    },

    get lastSentDateDiff() {
      return moment().diff(self?.lastSent, 'days');
    },

    get lastSentDaysAgo() {
      if (!self.lastSent) {
        return 'Never';
      }

      return `${self.lastSentDateDiff} days ago`;
    },

    // Member has sent a message in the last 30 days AND
    // Member has not received an NPS survey in the last 30 days
    get shouldSendNPS() {
      const member = getParent(self, 1);
      const sentMessageInLast30Days = moment()
        .subtract(30, 'days') <= member.channel?.lastMemberMessage?.momentTimestamp;
      const sentNPSInLast30Days = self.lastSentDateDiff <= 30 && self.lastSentDateDiff > 0;

      return sentMessageInLast30Days && !sentNPSInLast30Days;
    },
  }));

export default NPSSurvey;
