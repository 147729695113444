import { IRootStore } from '@stores/ApplicationInterfaces';
import { getStringHash } from '@utils/hash';
import { flow } from 'mobx-state-tree';
import { Logger } from '@utils/logger';

import {
  ILinkPreview,
  LinkPreviewInput,
  LinksPreview,
  LinkPreviewState,
} from './LinksPreview';

export type LinkPreviewMixinActions = {
  fetchUrlPreview(url: string): Promise<LinkPreviewInput>;
  findUrlData(url: string): ILinkPreview | undefined;
  modifyUrlData(url: string, newProperties: Partial<ILinkPreview>): void;
  addPreviewUrl: (url: string) => Promise<void>;
};

// TODO: this is repeated in chatinput due to circular dep. Fix in the future
function isFileURL(url: string){
  try{
    const gDocRegex = /docs\.google/;
    const isGDoc = gDocRegex.test(url);
    if (isGDoc){
      return true;
    }
    const imageExtensions = ['jpg', 'gif', 'png', 'svg', 'webp'];
    const videoExtensions = ['mp4', 'mkv', 'webm', 'avi', 'mov'];
    const audioExtensions = ['mp3', 'wav', 'aac', 'flac', 'wma', 'm4a'];
    const dataExtensions = ['pdf', 'csv', 'txt', 'yaml', 'json'];
    const extensions = imageExtensions.concat(videoExtensions, audioExtensions, dataExtensions);
    // if a file extension exists, it should be after the last '.' in the pathname
    const parsedURL = new URL(url);
    // make sure the hostname has a domain extension, we don't want https://google to match
    const urlHost = parsedURL.hostname.split('.');
    if (urlHost.length > 1) {
      const path = parsedURL.pathname;
      const splitByDot = path.split('.');
      const extension = splitByDot.length > 1 ? splitByDot.pop()!.trim() : false;
      return (extension && extensions.includes(extension));
    }
    // fail if hostname has no domain extension
    return true;
  }
  catch(error){
    console.error('Invalid URL');
    return true;
  }
}

export default {
  actions: (self: IRootStore) => ({
    fetchUrlPreview(url: string) {
      const { hades } = self;
      return hades?.request('linkPreview.get', {
        url,
      }) as Promise<LinkPreviewInput>;
    },
    findUrlData(url: string) {
      const hashedUrl = getStringHash(url);
      return self.linksPreviewData.get(hashedUrl);
    },
    modifyUrlData(url: string, newProperties: Partial<ILinkPreview>) {
      const urlData = self.findUrlData(url);
      if (!urlData) return;

      Object.assign(urlData, newProperties);
    },
    addPreviewUrl: flow(function* addPreviewUrl(url: string) {
      try {
        const urlFoundInStore = self.findUrlData(url);
        if (urlFoundInStore) {
          return;
        }

        if(isFileURL(url)){
          self.modifyUrlData(url, {
            state: LinkPreviewState.ERROR,
          });
          return;
        }

        const hashedUrl = getStringHash(url);

        self.linksPreviewData.set(
          hashedUrl,
          LinksPreview.create({
            id: hashedUrl,
            url,
            state: LinkPreviewState.LOADING,
          }),
        );

        const result = yield self.fetchUrlPreview(url);

        self.modifyUrlData(url, {
          ...result,
          state: LinkPreviewState.SUCCESS,
        });
      } catch (e) {
        Logger.error('LinkPreviewsMixin:addPreviewUrl error', e);
        self.modifyUrlData(url, {
          state: LinkPreviewState.ERROR,
        });
      }
    }),
  }),
};
