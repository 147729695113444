import { momentPT } from '@utils/dates';
import {
  addVisibilityChangeHandler,
  isVisibleTab,
  removeVisibilityChangeHandler,
} from '@utils/pageVisibility';
import { useCallback, useEffect } from 'react';
import { EventsType, useIdleTimer } from 'react-idle-timer';
import { Logger } from '@utils/logger';

import Config from '../../Config';

const {
  sessionTimeouts: { inactivityDetectionTimeout, logoutModalTimeout },
} = Config;

export enum TabMessage {
  LOGOUT,
  RENDER_LOGOUT_MODAL,
  UNMOUNT_LOGOUT_MODAL,
}

// these are the DOM events considered 'activity' in the dashboard
const events = [
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
] as EventsType[];

type Params = {
  handleVisibleInactivity: () => void;
  handleHiddenInactivity: () => void;
  handleTabMessage: (data: { type: TabMessage }) => void;
  isPromptBeingRenderedOnOtherTab: boolean;
};

export default function useIdleDetection({
  handleVisibleInactivity,
  handleHiddenInactivity,
  handleTabMessage,
  isPromptBeingRenderedOnOtherTab,
}: Params) {
  const handleIdle = useCallback(
    (sendMessage: (data: { type: TabMessage }) => void) => () => {
      // ignore event triggered when visibility is hidden
      // or logout modal is being rendered on other tab
      if (!isVisibleTab() || isPromptBeingRenderedOnOtherTab) return;

      // tab is visible while idle, show logout modal and alert the rest of tabs
      Logger.log('handleIdle: tab is idle and active/visible, show modal');
      handleVisibleInactivity();
      sendMessage({ type: TabMessage.RENDER_LOGOUT_MODAL });
    },
    [handleVisibleInactivity, isPromptBeingRenderedOnOtherTab],
  );

  const { pause, start, message, getLastActiveTime } = useIdleTimer({
    crossTab: true,
    timeout: inactivityDetectionTimeout,
    // we need this workaround to use message inside of handleIdle function
    onIdle: () => {
      handleIdle(message)();
    },
    debounce: 500,
    stopOnIdle: true,
    syncTimers: 500,
    startManually: true,
    startOnMount: true,
    leaderElection: true,
    onMessage: handleTabMessage,
    events,
  });

  const handleTabVisibilityChange = useCallback(() => {
    // ignore event triggered when visibility is hidden
    // or logout modal is being rendered on other tab
    if (!isVisibleTab() || isPromptBeingRenderedOnOtherTab) return;

    const lastActiveMoment = momentPT(getLastActiveTime());
    const now = momentPT(Date.now());

    // force logout if inactivity is longer than (idle timeout + logout modal duration)
    if (
      now.diff(lastActiveMoment) >
      inactivityDetectionTimeout + logoutModalTimeout
    ) {
      handleHiddenInactivity();
      // message all other dashboard tabs to logout
      message({ type: TabMessage.LOGOUT });
      return;
    }

    // show logout modal if inactivity is longer than idle timeout
    if (now.diff(lastActiveMoment) > inactivityDetectionTimeout) {
      handleIdle(message)();
    }
  }, [
    getLastActiveTime,
    handleHiddenInactivity,
    handleIdle,
    isPromptBeingRenderedOnOtherTab,
    message,
  ]);

  // listen to visibility changes
  useEffect(() => {
    addVisibilityChangeHandler(handleTabVisibilityChange);
    return () => {
      removeVisibilityChangeHandler(handleTabVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTabVisibilityChange]);

  return {
    startTimer: start,
    pauseTimer: pause,
    messageTabs: message,
  };
}
