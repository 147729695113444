// @ts-nocheck

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import './splash.scss';

function Splash() {
  return <div id="splash">
    <Container fluid>
      <Row noGutters />
    </Container>
  </div>;
}

export default Splash;
