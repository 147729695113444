import { isArray, isPlainObject, map, mapValues } from 'lodash';

type MapValuesDeepCallback<T> = <U>(value: T, key: string | number) => U;

function mapValuesDeep<T = Record<string, unknown>>(
  obj: T,
  fn: MapValuesDeepCallback<T>,
  key?: string | number,
): any {
  if (isArray(obj)) {
    return map(obj, (innerObj, idx) => mapValuesDeep(innerObj, fn, idx));
  }

  if (isPlainObject(obj)) {
    return mapValues(obj as unknown as object, (val, pk) =>
      mapValuesDeep(val, fn, pk),
    );
  }

  return fn(obj, key as string | number);
}

export default mapValuesDeep;
