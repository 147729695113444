import { SiblySDK } from '@sibly/sibly-sdk-browser';
import {
  flow,
  getSnapshot,
  getRoot,
  types,
} from 'mobx-state-tree';

import abilityFromJSON from '../utils/casl/fromJSON';

export default types.model(
  'Coach',
  {
    id: types.identifierNumber,
    name: types.string,
    email: types.maybe(types.string),
    isActive: types.boolean,
    role: types.maybe(types.union(
      // types.undefined, // TODO: remove
      types.literal('Admin'),
      types.literal('Associate Manager'),
      types.literal('Coach'),
    )),

    // TODO implement logic for removed Feedback component -> https://github.com/siblysibly/hypnos/pull/314
    // feedback: types.maybe(CoachingFeedback),

    permissions: types.maybe(types.frozen([])),
    isSeller: types.maybe(types.boolean),
    canManageCoaches: types.maybe(types.boolean),
    reportAdmin: types.maybe(types.boolean),
  },
)
  .actions((self) => ({
    sync: flow(function* sync() {
      const root = getRoot(self);

      const { idToken } = yield SiblySDK.Authorization.getSessionTokens();

      // @ts-ignore
      const response = yield root.hades.request(
        'coach.me',
        {},
        {
          headers: {
            // @ts-ignore
            Authorization: idToken,
          },
        },
      );

      const {
        feedback,
        role: {
          permissions,
        },
      } = response;

      // @ts-ignore
      self.feedback = feedback;
      self.permissions = permissions;

      return self;
    }),

    disable: flow(function* disable() {
      const root = getRoot(self);

      // @ts-ignore
      yield root.hades.request('coach.disable', { id: self.id });

      self.isActive = false;
    }),

    reEnable: flow(function* reEnable() {
      const root = getRoot(self);

      // @ts-ignore
      yield root.hades.request('coach.reEnable', { id: self.id });

      self.isActive = true;
    }),
  }))
  .views((self) => ({
    get isAdmin() {
      return self.role === 'Admin';
    },

    get isReportAdmin() {
      return !!self.reportAdmin;
    },

    get isMe() {
      const root = getRoot(self);

      // @ts-ignore
      return root.me && root.me.id === self.id;
    },

    get members() {
      const root = getRoot(self);

      // @ts-ignore
      return root.membersArray.filter((member) => member.coach && member.coach.id === self.id);
    },

    get abilities() {
      return abilityFromJSON(
        self.permissions,
        {
          coach: getSnapshot(self),
        },
      );
    },
  }));
