import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { isEmpty } from 'lodash';

import Member from '@stores/Member';
import { Instance } from 'mobx-state-tree';

import { ConnectionState, SendBird } from '@services/SendBird';
import { Logger } from '@utils/logger';

import Config from '../../Config';

const isCoach = (senderId: string) => senderId.endsWith('sibly');
const isBot = (senderId: string) => senderId.endsWith('bot');
const isSibly = (senderId: string) => isCoach(senderId) || isBot(senderId);
const isMember = (senderId: string) => !isSibly(senderId);

export default {
  actions: (self: any) => {
    const sendbird = new SendBird(
      Config.sendbird.appId,
      Config.sendbird.coach.id,
      Config.sendbird.coach.token,
    );

    self.sendbird = sendbird;

    // TODO: should type channel and message - use Sendbird types
    sendbird.on('message', async (channel: any, message: any) => {
      let logPrefix;

      try {
        logPrefix = `Sendbird:newMessage messageId=${message.messageId}`;

        Logger.log(logPrefix, 'Tracking message');
        SiblySDK.Monitoring.addBreadcrumb({
          message: logPrefix,
          category: 'SendbirdMixin',
        });
        // this is part of the notification workaround
        self.trackNewMessage(message.messageId);

        const isSenderAMember = isMember(message.sender.userId);
        Logger.log(
          logPrefix,
          `channelUrl=${channel.url} senderId=${message.sender.userId} isSenderAMember=${isSenderAMember}`,
        );
        SiblySDK.Monitoring.addBreadcrumb({
          message: `${logPrefix} channelUrl=${channel.url} senderId=${message.sender.userId} isSenderAMember=${isSenderAMember}`,
          category: 'SendbirdMixin',
        });

        const mappedMembers: Instance<typeof Member>[] = Array.from(
          self.members.values(),
        );

        // get the member based on the channelUrl
        const memberInStore: Instance<typeof Member> | undefined =
          mappedMembers.find(({ channel: { url } }) => url === channel.url);
        Logger.log(
          logPrefix,
          `memberInStore=${Boolean(memberInStore)} memberId=${
            memberInStore?.id
          }`,
        );

        SiblySDK.Monitoring.addBreadcrumb({
          message: `${logPrefix} memberInStore=${Boolean(memberInStore)} memberId=${
            memberInStore?.id
          }`,
          category: 'SendbirdMixin',
        });

        if (!isSenderAMember && !memberInStore) {
          // If the incoming message is not from a member (e.g., SAM),
          // and the member is not in store, we should ignore the message.
          Logger.log(
            logPrefix,
            'Incoming message is not from member, and member is not in store - Ignoring message',
          );
          SiblySDK.Monitoring.addBreadcrumb({
            message: `${logPrefix} Incoming message is not from member, and member is not in store - Ignoring message`,
            category: 'SendbirdMixin',
          });
          return;
        }

        // either get the member in store or fetch it based on the sendbird userId
        const member: Instance<typeof Member> =
          memberInStore ||
          (await self.getMember({ chatId: message.sender.userId }));

        // enable detailed logging for this member
        member.enableLogging();

        if (isSenderAMember) {
          const logDetails = `memberId=${member.id} setting messageCountLast15Min = ${
            member.messageCountLast15Min + 1
          }`;
          Logger.log(
            logPrefix,
            logDetails,
          );

          SiblySDK.Monitoring.addBreadcrumb({
            message: `${logPrefix} ${logDetails}`,
            category: 'SendbirdMixin',
          });
          member.setMessageCountLast15Min(member.messageCountLast15Min + 1);
        }

        if (!isEmpty(member?.followUp?.message)) {
          const logDetails = `memberId=${member.id} Clearing followup message`;
          Logger.log(
            logPrefix,
            logDetails,
          );
          SiblySDK.Monitoring.addBreadcrumb({
            message: `${logPrefix} ${logDetails}`,
            category: 'SendbirdMixin',
          });
          member.update({ followUp: { message: '' } });
        }

        const logDetails = [
          `memberId=${member.id} Appending new message`,
          `member.isReleased=${member.isReleased}`,
          `member.isGrabbed=${member.isGrabbed}`,
          `member.momentAssignedAt=${member.momentAssignedAt}`,
          `member.channel.lastMemberMessageAt=${member?.channel?.lastMemberMessageAt}`,
          `member in unassigned=${Boolean(
            self.unassignedMembers.find(
              (unassigned: any) => unassigned.id === member.id,
            ),
          )}`,
          `member in recently released=${Boolean(
            self.recentlyReleasedMembers.find(
              (recentlyReleased: any) => recentlyReleased.id === member.id,
            ),
          )}`,
          `member in my grabbed bucket=${Boolean(
            self.grabbedMembers.find(
              (grabbedMember: any) => grabbedMember.id === member.id,
            ),
          )}`,
          `member grabbed by another coach=${Boolean(
            self.sortedCoaches.find((coach: any) =>
              coach.members.find(
                (memberInCoach: any) => memberInCoach.id === member.id,
              ),
            )?.id,
          )}`
        ];

        Logger.log(
          logPrefix,
          ...logDetails,
        );
        SiblySDK.Monitoring.addBreadcrumb({
          message: `${logPrefix} ${logDetails.join(' ')}`,
          category: 'SendbirdMixin',
        });

        member.channel.appendMessages([message]);
      } catch (error) {
        Logger.error(logPrefix, 'Error during Sendbird message event', error);
        self.showAlert((error as any).message, 'danger', error);
      }
    });

    sendbird.on('typingChange', (__, isTyping) => {
      self.chat.setIsMemberTyping(isTyping);
    });

    sendbird.on('connectionStateChange', (connectionState) => {
      Logger.log('Sendbird connectionStateChange, new state:', connectionState);
      self.chat.setConnectionState(connectionState);
    });

    return {
      getSendBird() {
        return sendbird;
      },

      async connectSendbird() {
        try {
          await sendbird.connect();
          self.chat.setConnectionState(ConnectionState.OPEN);
          sendbird.updateChannelHandler();
        } catch (error) {
          const errorMessage = 'Failed to start SendBird connection.';
          self.showAlert(errorMessage, 'danger', error);
        }
      },

      async clearSendBird() {
        try {
          await sendbird.destroy();
          self.chat.setConnectionState(ConnectionState.NOT_STARTED);
        } catch (error) {
          self.showAlert(
            'There was an error clearing SendBird.',
            'danger',
            error,
          );
        }
      },
    };
  },
};
