import {
  getParent,
  getRoot,
  types,
} from 'mobx-state-tree';

import { has } from 'lodash';
import moment from 'moment-timezone';
import { coachTimezoneAbbreviation } from '@utils/dates';
import { Logger } from '@utils/logger';

import type { IRootStore } from './ApplicationInterfaces';
import { ILinkPreview } from './Application/LinkPreviewsMixin/LinksPreview';

const IMG_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png'];

const MessageModel = types.model(
  'Message',
  {
    content: types.string,
    data: types.maybe(types.frozen()),
    id: types.identifierNumber, // will sometimes be a Sibly id, sometimes Sendbird id
    isFile: types.optional(types.boolean, false),
    isJumpTo: types.optional(types.boolean, false),
    isLiked: types.optional(types.boolean, false),
    searchHighlightRanges: types.maybe(types.frozen()),
    searchRelevance: types.maybe(types.number),
    senderId: types.string,
    timestamp: types.Date,
  },
)
  .preProcessSnapshot((snapshot) => (
    {
      ...snapshot,
      text: snapshot?.content || '', // Covers null/unassigned text values
      timestamp: moment(snapshot?.timestamp).toDate(),
    }
  ))
  .actions((self) => ({
    setIsJumpTo(isJumpTo: boolean) {
      self.isJumpTo = isJumpTo;
      if (isJumpTo) {
        setTimeout(() => {
          this.setIsJumpTo(false);
        }, 2500);
      }
    },
  }))
  .views((self) => ({
    get isBot(): boolean {
      return self.senderId.endsWith('bot');
    },

    get isCoach(): boolean {
      return self.senderId.endsWith('sibly');
    },
    get isSibly(): boolean {
      return this.isBot || this.isCoach;
    },
    get className() {
      const memberClassName = (
        this.isSibly ? '' : 'bg-white'
      );

      const jumpToClassName = (
        self.isJumpTo ? 'bg-yellow' : ''
      );

      return jumpToClassName || memberClassName;
    },

    get classNameGrouped() {
      const { isSibly } = this;

      return isSibly ? 'bg-neutral-purple-gray-5' : 'bg-white';
    },
    get channel() {
      return getParent(self, 2);
    },
    get momentTimestamp() {
      return moment(self.timestamp);
    },
  }))
  .views((self) => ({
    get formattedTimestamp() {
      return self.momentTimestamp.format(`hh:mma [${coachTimezoneAbbreviation}] MMM DD, YYYY`);
    },

    get momentDateDiff() {
      return moment().diff(self.momentTimestamp, 'days');
    },

    get isImage(): boolean {
      if (!self.isFile) return false;

      try {
        const fileUrl = new URL(self.content);
        const fileExtension = fileUrl.pathname.split('.').pop();
        return Boolean(IMG_FILE_EXTENSIONS.find((ext) => ext === fileExtension));
      } catch (e) {
        Logger.error('Failed to determine if message is an image', e);
        return false;
      }
    },

    get isPDF(): boolean {
      if (!self.isFile) return false;

      return self.content?.endsWith('pdf');
    },

    get isMenuButtonVisible(): boolean {
      return !self.isSibly;
    },

    get isAutomatedFollowUp(): boolean {
      return self.isCoach && has(self.data || {}, 'followUp') && !has(self.data || {}, 'coachId');
    },

    get isSAM(): boolean {
      return self.isCoach && has(self.data || {}, 'sam');
    },

    get isMember(): boolean {
      return !self.isSibly;
    },

    get isCoachFollowUp(): boolean {
      return self.isCoach && has(self.data || {}, 'followUp') && has(self.data || {}, 'coachId');
    },

    // deprecated
    // get nextMessage() {
    //   const { messages } = self.channel;

    //   const index = messages
    //     .map((message) => message.id)
    //     .indexOf(self.id);

    //   return messages[
    //     (index + 1) % messages.length
    //   ];
    // },

    get previewUrls() {
      const urls = (self.data.linkPreview || []) as string[];

      return urls;
    },
  }))
  .views((self) => ({
    get previewUrlsRendered() {
      const { linksPreviewData } = getRoot<IRootStore>(self);
      const rendered = [] as ILinkPreview[];
      linksPreviewData.forEach((value) => {
        if (self.previewUrls.includes(value.url)) {
          rendered.push(value);
        }
      });

      return rendered;
    },
    // @ts-ignore
    get name() {
      const { coaches, getMemberByChatId } = getRoot<IRootStore>(self);

      if (self.isBot) {
        return 'Assistant Bot';
      }

      if (self.isCoach) {
        if (self.data?.sender) {
          return `(${self.data.sender})`;
        }

        if (self.isCoachFollowUp) {
          const coachName = coaches.get(self.data?.coachId)?.name;
          if (coachName) return `(${coachName}) [Auto Sent Follow-Up]`;
          return '[Auto Sent Follow-Up]';
        }

        if (self.isAutomatedFollowUp) {
          return '[Auto Sent Follow-Up]';
        }

        if (self.isSAM) {
          return '[Automated Message]';
        }

        if (self.data?.coachId) {
          return `(${coaches.get(self.data.coachId)?.name})`;
        }

        return 'Coach';
      }

      // it's a member message
      return getMemberByChatId(self.senderId)?.name;
    },
  }));

export default MessageModel;
