import { Instance, SnapshotIn } from 'mobx-state-tree';
import Coach from '@stores/Coach';
import Member from '@stores/Member';

interface SerializedMember {
  memberChatName: string;
  memberId: string;
  memberName: string;
}

export interface NEXTContext {
  user: SnapshotIn<typeof Coach> | null;
  memberGroups: Record<
    'unassigned' | 'active' | 'recentlyReleased' | string,
    SerializedMember[]
  >;
}

const mapMemberToNEXTContext = (
  member: Instance<typeof Member>,
): SerializedMember => ({
  memberChatName: member.chatName,
  memberId: member.id,
  memberName: member.name,
});

export const getNEXTContext = (self: any): NEXTContext => ({
  user: self.me ?? null,
  memberGroups: {
    unassigned: self.unassignedMembers.map(mapMemberToNEXTContext),
    active: self.grabbedMembers.map(mapMemberToNEXTContext),
    ...self?.sortedCoaches?.map((coach: Instance<typeof Coach>) => ({
      [coach.name]: coach.members.map(mapMemberToNEXTContext),
    })),
    recentlyReleased: self.recentlyReleasedMembers.map(mapMemberToNEXTContext),
  },
});
