// @ts-nocheck
import { getRoot, flow, types } from 'mobx-state-tree';

import {
  trackCoachSentMessage,
  trackEligibilityReminderSentInChat,
  trackMessageIncludesURL,
} from '@utils/analytics';
import { MonitoringService } from '@services';

import { includesURL } from '@utils/regex';
import { ConnectionState } from '@services/SendBird';
import { Logger } from '@utils/logger';

import ChatInput from './ChatInput';

type Volatile = {
  sendbirdConnectionState: ConnectionState;
};

export default types
  .model('Chat', {
    input: ChatInput,
    isMemberTyping: types.maybe(types.boolean),
    isJumpState: types.maybe(types.boolean, false),
    showLoadMoreMessages: types.maybe(types.boolean, false),
    isChannelLoaded: types.maybe(types.boolean, false),
    hasLoadedPreviousMessages: types.maybe(types.boolean, false),
    isLoadingPreviousMessages: types.maybe(types.boolean, false),
    hasMorePreviousMessages: types.maybe(types.boolean, true),
  })
  .volatile<Volatile>(() => ({
    sendbirdConnectionState: ConnectionState.NOT_STARTED,
  }))
  .actions((self) => ({
    deleteMessageById: flow(function* deleteMessageById(messageId) {
      const { hades, showAlert, member } = getRoot(self);

      const { channel } = member;

      if (!channel) {
        yield new Error(
          'There is no channel set/open. Please call loadMostRecentMessages() first',
        );
      }

      if (messageId) {
        try {
          yield hades.request('message.delete', {
            channelId: channel.id,
            messageId,
          });

          channel.removeMessageById(messageId);
        } catch (error) {
          showAlert(
            'There was an error deleting the message.',
            'danger',
            error,
          );
        }
      }

      return null;
    }),

    jumpToMessageById: flow(function* jumpToMessageById(messageId) {
      const {
        member: { channel },
      } = getRoot(self);

      const message = channel.getMessageById(messageId);

      if (message) {
        message.setIsJumpTo(true);
      } else {
        yield self.loadMessagesForJump(messageId);

        const jumpedMessage = channel.getSearchMessageById(messageId);

        jumpedMessage.setIsJumpTo(true);

        self.setIsJumpState(true);
        self.showLoadMoreMessages = true;
      }
    }),

    loadMessagesForJump: flow(function* loadMessagesForJump(messageId) {
      const application = getRoot(self);

      const {
        member: { channel },
      } = application;

      const sendbird = application.getSendBird();

      const messages = yield sendbird.getMessagesByMessageId(
        messageId,
        channel.url,
        {
          prevResultSize: 15,
          nextResultSize: 5,
          isInclusive: true,
        },
      );

      application.member.channel.setSearchMessages(messages);
    }),

    sendFile: flow(function* sendFile(file, data = {}) {
      const application = getRoot(self);
      const error = 'There was an error sending a file.';

      const result = yield application.getSendBird().sendImageMessage(file, {
        ...data,
        coachId: application.me.id,
      });

      if (result) {
        // this is part of the notification workaround.
        // A message sent from a Sendbird SDK connection will not trigger a new message event (SendbirdMixin) for that message on the same SDK connection
        Logger.log('sendFile: Tracking message', result.messageId);
        application.trackNewMessage(result.messageId);

        return application.member.channel.appendMessages([result]);
      }

      return application.showAlert(error, 'danger', error);
    }),

    // TODO: add typings for Sendbird data
    // eslint-disable-next-line @typescript-eslint/default-param-last
    async sendMessage(text: string, data = {}, channelUrl: string) {
      const application = getRoot(self);
      const { linkPreviewsUrlsNotOmmited } = self.input;
      const { member, me } = application;
      if (!member) {
        throw new Error(
          'There is no member set. Please call setMember() first',
        );
      }

      const {
        channel,
        resources,
        agendaMapping,
        trackSentQuestionnaire,
        addSharedLinks,
      } = member;

      if (!channel) {
        throw new Error(
          'There is no channel set/open. Please call loadMostRecentMessages() first',
        );
      }

      const message = await application.getSendBird().sendMessage(
        text,
        {
          ...data,
          linkPreview: linkPreviewsUrlsNotOmmited,
          coachId: application.me.id,
        },
        channelUrl,
      );

      if (data.resources?.length) {
        resources.shareService(data.resources.toJSON());
      }

      // keep backward compatibility with old name topics (old mobile app only knows about topics)
      if (data.topics?.length) {
        agendaMapping.shareConcerns(data.topics);
      }

      if (data.hasEligibilityForm) {
        trackEligibilityReminderSentInChat({ member, coach: me });
      }

      if (linkPreviewsUrlsNotOmmited.length) {
        addSharedLinks(linkPreviewsUrlsNotOmmited);
      }

      // if message has a url, send to analytics
      if (includesURL(text)) {
        trackMessageIncludesURL(member);
      }

      trackCoachSentMessage(member);

      trackSentQuestionnaire(data.questionnaireId);

      // this is part of the notification workaround.
      // A message sent from a Sendbird SDK connection will not trigger a new message event (SendbirdMixin) for that message on the same SDK connection
      Logger.log('sendMessage: Tracking message', message.messageId);
      application.trackNewMessage(message.messageId);

      channel.appendMessages([message]);
      self.input.clear();

      return message;
    },

    setIsMemberTyping(value) {
      self.isMemberTyping = value;
    },

    resetChat() {
      Logger.log('resetChat resetting chat');
      self.isChannelLoaded = false;
      self.hasLoadedPreviousMessages = false;
      self.isLoadingPreviousMessages = false;
      self.hasMorePreviousMessages = true;
      self.isMemberTyping = false;
      self.setIsJumpState(false);
      self.showLoadMoreMessages = false;
      Logger.log('resetChat finished resetting chat');
    },

    loadMostRecentMessages: flow(function* loadMostRecentMessages() {
      const application = getRoot(self);
      const { channel, id: memberId } = application.member;

      self.resetChat();

      if (channel) {
        const sendbird = application.getSendBird();
        yield sendbird.openChannel(channel.url);
        Logger.log(`channel opened for member ${memberId}`);

        const messages = yield sendbird.loadMessages();
        Logger.log(`setting messages for member ${memberId}`);
        channel.setMessages(messages);
        self.isChannelLoaded = true;
      } else {
        MonitoringService.addLog({
          message: '[MST][ChatInput] loadMostRecentMessages There was an issue calling setChannel. Please check if this is a valid channel.',
          logGroup: '[MST][ChatInput] loadMostRecentMessages',
          logRecordType: 'LoadMostRecentMessagesError',
          error: new Error('There was an issue calling setChannel'),
          details: {
            memberId,
          },
        });
      }
    }),

    loadPreviousMessages: flow(function* loadMoreMessages() {
      self.isLoadingPreviousMessages = true;

      if (!self.isChannelLoaded) {
        return;
      }

      const application = getRoot(self);
      const { channel } = application.member;
      const { url: channelUrl } = channel;

      const sendbird = application.getSendBird();

      const messageId = self.isJumpState
        ? channel.firstSearchMessage?.id
        : channel.firstMessage?.id;

      const messages = yield sendbird.getMessagesByMessageId(
        messageId,
        channelUrl,
        {
          prevResultSize: 30,
          nextResultSize: 0,
        },
      );

      if (messages.length < 30) {
        self.hasMorePreviousMessages = false;
      }

      if (self.isJumpState) {
        channel.prependSearchMessages(messages);
      }
      channel.prependMessages(messages);

      self.hasLoadedPreviousMessages = true;
      self.isLoadingPreviousMessages = false;
    }),

    loadNextMessages: flow(function* loadMoreMessages() {
      const application = getRoot(self);
      const { channel } = application.member;

      const sendbird = application.getSendBird();

      const messages = yield sendbird.getMessagesByMessageId(
        channel.lastSearchMessage?.id,
        channel.url,
        {
          prevResultSize: 0,
          nextResultSize: 30,
        },
      );

      if (messages.length < 30) {
        self.showLoadMoreMessages = false;
      }

      channel.appendSearchMessages(messages);
    }),

    onLastMessageVisible: flow(function* onLastMessageVisible() {
      if (self.isJumpState && !self.showLoadMoreMessages) {
        try {
          yield self.loadMostRecentMessages();
        } catch (error) {
          const application = getRoot(self);
          application.showAlert(
            'There was an error loading messages.',
            'danger',
            error,
          );
        }
      }
    }),

    setIsJumpState(isJumpState) {
      self.isJumpState = isJumpState;
    },

    setConnectionState(connectionState: ConnectionState) {
      const application = getRoot(self);
      const lastConnectionState = self.sendbirdConnectionState;
      self.sendbirdConnectionState = connectionState;

      if (connectionState === ConnectionState.OPEN && lastConnectionState === ConnectionState.CONNECTING) {
        if (application.member) {
          application.showAlert('Chat service restablished. Reloading messages.');
          // refresh sendbird chat (re-load messages in channel)
          application.loadMemberMessages();
        } else {
          application.showAlert('Chat service restablished.');
        }
      }

      if (connectionState === ConnectionState.CLOSED) {
        application.showAlert('Chat service is disconnected. Please wait for auto-reconnect.', 'danger');
      }
    },
  }));
