// @ts-nocheck
import { types } from 'mobx-state-tree';

import moment from 'moment-timezone';

import DateTime from '@utils/mst/types/DateTime';

const CoachConversation = types
  .model('CoachConversation', {
    id: types.identifierNumber,
    name: types.string,
    acute: types.snapshotProcessor(types.boolean, {
      preProcessor(snapshot) {
        return !!snapshot;
      },
    }),
    subscriptionStatus: types.string,
    lastCoachMessageTS: DateTime,
    numberCoachMessages: types.integer,
    percentCoachMessages: types.string,
  })
  .views((self) => ({
    get lastCoachMessageTSFormatted() {
      return moment(self.lastCoachMessageTS)
        .format('DD MMMM YYYY hh:mm A');
    },
    get percentCoachMessagesFormatted() {
      return (self.percentCoachMessages)
        .substring(0, 3)
        .concat('%');
    },
  }));

export default CoachConversation;
