import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from '@dashComponents/ConfirmModal';
import { momentPT } from '@utils/dates';
import { trackLogoutModalVisible } from '@utils/analytics';
import Config from '../../Config';

const { sessionTimeouts: { logoutModalTimeout } } = Config;

function formatRemainingTime(millisRemaining: number) {
  return momentPT(millisRemaining).format('mm:ss');
}

type LogoutModalProps = {
  coachId: number;
  onExtendSession: () => void;
  onLogout: () => void;
  visible: boolean;
}

function LogoutModal({
  onExtendSession, onLogout, visible, coachId,
}: LogoutModalProps) {
  const [counterMillis, setCounterMillis] = useState(logoutModalTimeout);

  useEffect(() => {
    if (visible) {
      trackLogoutModalVisible(coachId);
      setCounterMillis(logoutModalTimeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (!visible) return;
    if (counterMillis === 0) {
      onLogout();
      return;
    }

    let timeoutId: ReturnType<typeof setTimeout>;

    if (counterMillis > 0) {
      timeoutId = setTimeout(() => setCounterMillis(counterMillis - 1000), 1000);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [counterMillis, visible, onLogout]);

  return (
    <ConfirmModal
      cancelText="Logout"
      confirmationText="Extend Session"
      onClose={onLogout}
      onConfirm={onExtendSession}
      title="Are you still here?"
      visible={visible}
      unMaskContent
    >
      <span className="text-center">
        Your session is about to expire due to inactivity.
        Extend session or you will be logged out in &nbsp;
        {formatRemainingTime(counterMillis)}
      </span>
    </ConfirmModal>
  );
}

LogoutModal.propTypes = {
  onExtendSession: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default LogoutModal;
