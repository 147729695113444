import DateTime from '@utils/mst/types/DateTime';
import { types } from 'mobx-state-tree';

const QuestionnaireDontSendReason = types.model({
  id: types.identifierNumber,
  text: types.string,
  createdAt: DateTime,
  updatedAt: DateTime,
});

export default QuestionnaireDontSendReason;
