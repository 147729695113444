import { types } from 'mobx-state-tree';
import ConcernColor from './ConcernColor';

export type EditModeConcern = {
  id: number;
  text: string;
  doNotDiscuss: boolean;
  priority: boolean;
  deleted: boolean;
}

const Concern = types.model('Concern', {
  id: types.identifierNumber,
  text: types.string,
  priority: types.boolean,
  doNotDiscuss: types.boolean,
  orderRank: types.maybeNull(types.number),
  colorId: types.reference(ConcernColor),
  attached: false,
})
  .views((self) => ({
    editModeVersion(): EditModeConcern {
      return {
        id: self.id,
        text: self.text,
        priority: self.priority,
        doNotDiscuss: self.doNotDiscuss,
        deleted: false,
      };
    },
  }));

export default Concern;
