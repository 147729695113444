import { SiblySDK } from '@sibly/sibly-sdk-browser';
import { IRootStore } from '@stores/ApplicationInterfaces';
import { flow } from 'mobx-state-tree';
import { MonitoringService } from '@services';
import { Logger } from '@utils/logger';

import newMessageBase64 from '../../sounds/new-message-base64.json';
import unassignedMessageBase64 from '../../sounds/unassigned-message-base64.json';
import newMessageMissed from '../../sounds/new-message-missed-base64.json';

// added these audio files in base64 encoding
// as a workaround for this issue https://sibly-jira.atlassian.net/browse/SIB-4385
// the error is “NotSupportedError: The element has no supported sources.”
// which has been only been reproduced once by Ray,
// and it blocks sounds from working until the tab is reloaded
// it could be related to a file caching issue or CORS(the first one is more probable)
// by doing this, we can avoid this problem because all the file content is available in a
// string variable
// we will still need to monitor if there are other events with this error
const newMessageAudio = new Audio(
  `data:audio/mp3;base64, ${newMessageBase64.value}`,
);
const unassignedAudio = new Audio(
  `data:audio/mp3;base64, ${unassignedMessageBase64.value}`,
);
const newMessageMissedAudio = new Audio(
  `data:audio/mp3;base64, ${newMessageMissed.value}`,
);

export type AudioMixinActions = {
  toggleSoundNotifications(): void;
  playAudio(audio: HTMLAudioElement, audioName: string): Promise<void>;
  playUnassignedAudio: () => Promise<void>;
  playNewMessageAudio: () => Promise<void>;
  playNewMessageMissedAudio: () => Promise<void>;
};

const AudioMixin = {
  actions: (self: IRootStore) => ({
    toggleSoundNotifications() {
      self.playSoundNotifications = !self.playSoundNotifications;
    },

    playAudio: flow(function* playAudio(audio, audioName) {
      try {
        yield audio.play();
        const message = `Played ${audioName} audio successfully`;
        Logger.log(message);
        SiblySDK.Monitoring.addBreadcrumb({ message, category: 'Audio' });
      } catch (e) {
        const message = `Failed to play ${audioName} audio`;
        Logger.error(message, e);
        SiblySDK.Monitoring.addBreadcrumb({ message, category: 'Audio' });
        MonitoringService.addLog({
          message: '[MST][AudioMixin] Failed to play audio',
          logGroup: '[MST][AudioMixin] playAudio',
          logRecordType: 'PlayAudioError',
          error: e as Error,
          details: {
            audioName,
          },
        });
      }
    }),

    playUnassignedAudio: flow(function* playUnassignedAudio() {
      yield self.playAudio(unassignedAudio, 'unassigned');
    }),

    playNewMessageAudio: flow(function* playNewMessageAudio() {
      yield self.playAudio(newMessageAudio, 'new message');
    }),

    playNewMessageMissedAudio: flow(function* playNewMessageMissedAudio() {
      yield self.playAudio(newMessageMissedAudio, 'new message missed');
    }),
  }),
};

export default AudioMixin;
