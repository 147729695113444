// @ts-nocheck
export default () => {
  const { userAgent } = navigator;
  // eslint-disable-next-line
  const [_, browser, version] = userAgent.match(
    /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i,
  );

  return {
    browser,
    version,
  };
};
