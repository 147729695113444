// @ts-nocheck
import {
  flow, getRoot, types,
} from 'mobx-state-tree';

export const EligibilityInfo = types.model('EligibilityInfo', {
  method: types.maybe(types.union(types.literal('api'), types.literal('form'))),
  eligibilityDetermined: types.boolean,
});

export default {
  attributes: {
    eligibilityInfo: types.maybe(EligibilityInfo),
  },
  actions: ((self) => ({
    getEligibilityInfo: flow(function* getEligibilityInfo() {
      const { hades } = getRoot(self);

      const response = yield hades.request('member.getEligibilityInfo', {
        memberId: self.id,
      });

      self.eligibilityInfo = response;

      yield Promise.resolve(response);
    }),
  })),
};
