import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';

import { Logger } from '@utils/logger';

import Splash from './pages/Splash';
import Router from './Router';

import './theme.scss';

import Store from './stores';
import Config from './Config';

// add to global so we can use moment in browser
window.moment = moment;

// Bootstrap MST store
const bootstrap = async () => {
  try {
    await Store.load();
  } catch (error) {
    const errorMessage = 'Error during initialization!';
    Logger.error(errorMessage, error);
    Store.showAlert(errorMessage, 'danger');

    // if there is problem let the router take over
    Store.setBootstrapped(true);

    // safety net to get back to root, but get stuck on splash
    navigate('/');
  }
};

function useMSTFastRefresh(rootStore: typeof Store) {
  const rootStoreRef = useRef(rootStore);

  if (!rootStoreRef.current.bootstrapped) {
    bootstrap();
  }

  // fast refresh only applies for development envs
  if (Config.env === 'prod') {
    return;
  }

  if (rootStore !== rootStoreRef.current) {
    Logger.log('Store code changed. Applying previous snapshot.');
    const snapshot = getSnapshot(rootStoreRef.current);

    try {
      applySnapshot(rootStore, snapshot);
      rootStoreRef.current = rootStore;
    } catch (error) {
      // apply snapshot can fail if mst model was changed
      Logger.error('Failed to apply previous snapshot', error);
      window.location.reload();
    }
  }
}

const ObservedApp = observer(() => {
  // If we haven't bootstrapped yet, show a splash screen
  if (!Store.bootstrapped) {
    return <Splash />;
  }

  // If were good in the hood, drop the main router
  return <Router />;
});

function App() {
  useMSTFastRefresh(Store);

  return <ObservedApp />;
}

export default App;
