// @ts-nocheck
import { trackAgendaMappingClickedAddConcern, trackAgendaMappingFirstTimeClickedAddConcern } from '@utils/analytics';
import {
  flow,
  getRoot,
} from 'mobx-state-tree';

export default {
  actions: (self) => ({
    createConcern: flow(function* createConcern(text, colorId) {
      const root = getRoot(self);

      const result = yield root.hades.request('agendaConcern.create', {
        memberId: self.id,
        text,
        colorId,
      });

      self.trackConcernCreation(text);
      self.addNewConcern(result);

      return result;
    }),
    addNewConcern(result) {
      self.agendaMapping.concerns = result.concerns;
      self.agendaMapping.lastShared = result.lastShared;
      self.agendaMapping.lastUpdated = result.lastUpdated;
    },
    trackConcernCreation(concern) {
      const { me } = getRoot(self);
      const data = { coachName: me.name, memberId: self.id, concern };

      // null lastUpdated means this is the first concern to be created for this member
      if (!self.agendaMapping.lastUpdated) {
        trackAgendaMappingFirstTimeClickedAddConcern(data);
      } else {
        trackAgendaMappingClickedAddConcern(data);
      }
    },
  }),
};
