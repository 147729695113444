import {
  Children,
  cloneElement,
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
} from 'react';

export const UNMASK_CLASSNAME = 'fs-unmask';
export const MASK_CLASSNAME = 'fs-mask';

function addClassToAllChildren(
  children: React.ReactNode | React.ReactNode[],
  className: string,
) {
  const arrayChildren = Children.toArray(children);

  return Children.map(arrayChildren, (child) => {
    if (
      typeof child !== 'string' &&
      typeof child !== 'number' &&
      'props' in child
    ) {
      const clonedChild = cloneElement(child, {
        className: child.props.className
          ? `${child.props.className} ${className}`
          : className,
      });

      return clonedChild;
    }

    return child;
  });
}

// This component will add the UNMASK_CLASSNAME to all its direct children elements
// Only works with child react elements
// this wont work:
// <UnMaskWrapper>Hello<UnmaskWrapper>
// <UnMaskWrapper>25<UnmaskWrapper>
export function UnmaskWrapper({ children }: PropsWithChildren<{}>) {
  const ModifiedChildren = useMemo(
    () => addClassToAllChildren(children, UNMASK_CLASSNAME),
    [children],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ModifiedChildren}</>;
}

// This component will add the MASK_CLASSNAME to all its direct children elements
export function MaskWrapper({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const ModifiedChildren = addClassToAllChildren(children, MASK_CLASSNAME);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ModifiedChildren}</>;
}

// adds the UNMASK_CLASSNAME to an element by passing it its ref
// if no ref is passed, will create a ref and return it for usage
export function useUnmaskInRef<T extends HTMLElement>(
  ref?: MutableRefObject<T>,
) {
  const innerRef = useRef<T>();
  const finalRef = ref || innerRef;

  useEffect(() => {
    if (finalRef.current) {
      finalRef.current.classList.add(UNMASK_CLASSNAME);
    }
  }, [finalRef]);

  return finalRef as MutableRefObject<T>;
}

export function useMaskInRef<T extends HTMLElement>(ref?: MutableRefObject<T>) {
  const innerRef = useRef<T>();
  const finalRef = ref || innerRef;

  useEffect(() => {
    if (finalRef.current) {
      finalRef.current.classList.add(MASK_CLASSNAME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return finalRef;
}
