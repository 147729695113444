// @ts-nocheck
import {
  types,
} from 'mobx-state-tree';

const Alert = types.model('Alert', {
  message: types.string,
  // maps to the variants used by Bootstrap
  variant: types.enumeration(
    'AlertVariant',
    ['success', 'danger', 'info'],
  ),
});

export default Alert;
