import MemberSearch from '@dashComponents/Member/Search';
import Hades from '@services/Hades';
import {
  getRoot,
  IAnyStateTreeNode,
  IMaybe,
  Instance,
  IReferenceType,
  ISimpleType,
  SnapshotIn,
  types,
} from 'mobx-state-tree';
import Socket from '@services/Socket';
import AccessCode from './AccessCode';

import Chat from './Chat';
import Coach from './Coach';
import Draft from './Draft';
import Member from './Member';
import Organization from './Organization';
import Request from './Request';
import Alert from './Alert';
import CoachConversations from './CoachConversations';
import Reports from './Reports/Reports';
import ConcernColor from './Member/AgendaMapping/ConcernColor';

import AccessCodesMixin from './Application/AccessCodeMixin';
import AlertMixin from './Application/AlertMixin';
import { AuthMixinActions } from './Application/AuthMixin';
import CoachConversationsMixin from './Application/CoachConversationsMixin';
import { NotificationWorkaroundMixin } from './Application/NotificationWorkaroundMixin';
import OrganizationMixin from './Application/OrganizationMixin';
import SendbirdMixin from './Application/SendbirdMixin';
import { SocketMixinActions } from './Application/SocketMixin';
import {
  MembersMixinActions,
  MembersMixinViews,
} from './Application/MembersMixin';
import Channel from './Channel';
import { AudioMixinActions } from './Application/AudioMixin';
import { CoachMixinActions, CoachMixinViews } from './Application/CoachMixin';
import QuestionnaireDontSendReason from './Application/QuestionnaireDontSendReason';
import { LinkPreviewMixinActions } from './Application/LinkPreviewsMixin';
import { LinksPreview } from './Application/LinkPreviewsMixin/LinksPreview';

export const AccessCodesModel = types.map(AccessCode);
export const BootstrappedModel = types.optional(types.boolean, false);
export const CoachesModel = types.map(Coach);
export const MembersModel = types.map(Member);
export const MemberModel: IMaybe<IReferenceType<typeof Member>> = types.maybe(types.reference(Member));
export const OrganizationsModel = types.map(Organization);
export const MeModel = types.maybe(types.reference(Coach));
export const PlaySoundNotificationsModel = types.optional(types.boolean, true);
export const AlertModel = types.maybe(Alert);
export const RequestsModel = types.map(Request);
export const CoachConversationsModel = types.array(CoachConversations);
export const QuestionnaireDontSendReasonsModel = types.array(QuestionnaireDontSendReason);
export const ReportsModel = types.maybe(Reports);
export const ConcernsColorsModel = types.array(ConcernColor);
export const LinksPreviewDataModel = types.map(LinksPreview);

export interface IAccessCodes extends Instance<typeof AccessCodesModel> {}

export type IBootstrapped = Instance<typeof BootstrappedModel>;

export interface IChat extends Instance<typeof Chat> {}

export interface ICoaches extends Instance<typeof CoachesModel> {}

export interface IMembers extends Instance<typeof MembersModel> {}

export type IMember = Instance<typeof MemberModel>;

export interface IOrganizations extends Instance<typeof OrganizationsModel> {}

export type IMe = Instance<typeof MeModel>;
export type IPlaySoundNotifications = Instance<typeof PlaySoundNotificationsModel>;
export interface IDrafts extends Instance<typeof Draft> {}

export type IAlert = Instance<typeof AlertModel>;

export interface IRequests extends Instance<typeof RequestsModel> {}

export interface IMemberSearch extends Instance<typeof MemberSearch> {}

export interface ICoachConversations extends Instance<typeof CoachConversationsModel> {}

export type IReports = Instance<typeof ReportsModel>;

export interface IConcernsColors extends Instance<typeof ConcernsColorsModel> {}

export interface ILinksPreviewData extends Instance<typeof LinksPreviewDataModel> {}

export interface IQuestionnaireDontSendReasons extends Instance<typeof QuestionnaireDontSendReasonsModel> {}

export type IRootStore = {
  // state
  accessCodes: IAccessCodes;
  bootstrapped: Instance<ISimpleType<boolean>>;
  chat: IChat;
  coaches: ICoaches;
  members: IMembers;
  member: IMember;
  organizations: IOrganizations;
  me: IMe;
  playSoundNotifications: IPlaySoundNotifications;
  drafts: IDrafts;
  alert: IAlert;
  requests: IRequests;
  memberSearch: IMemberSearch;
  coachConversations: ICoachConversations;
  reports: IReports;
  concernsColors: IConcernsColors;
  questionnaireDontSendReasons: IQuestionnaireDontSendReasons;
  linksPreviewData: ILinksPreviewData;

  // volatile
  hades: Hades | undefined;
  socket: Socket | undefined;
  isShowingLegalAgreement: boolean;
  isShowingConfirmSignInWithNewPasswordModal: boolean;
  isShowingConfirmEmailModal: boolean;
  nextMemberId: number | undefined;
  intervalForElapsedTimeUpdate: number;

  // actions
  addRequest: (id: string) => void;
  logout: () => Promise<unknown>;
  initializeDashboard: () => Generator<
    any,
    {
      members: any;
      coaches: any;
      accessCodes: any;
      organizations: any;
    },
    any
  >;
  save: () => Promise<void>;

  addOrganizations(items?: never[]): void;

  formatChannel(channels: any[]): SnapshotIn<typeof Channel>;
  setIntervalForElapsedTimeUpdate: () => void;
  clearIntervalForElapsedTimeUpdate: () => void;
  updateMembersElapsedTimeSinceLastMessage: () => void;
} & ReturnType<typeof AccessCodesMixin.actions> &
  ReturnType<typeof AlertMixin.actions> &
  ReturnType<typeof CoachConversationsMixin.actions> &
  ReturnType<typeof NotificationWorkaroundMixin.actions> &
  ReturnType<typeof OrganizationMixin.actions> &
  ReturnType<typeof SendbirdMixin.actions> &
  AuthMixinActions &
  SocketMixinActions &
  AudioMixinActions &
  CoachMixinActions &
  MembersMixinActions &
  LinkPreviewMixinActions &
  // views
  CoachMixinViews &
  MembersMixinViews;

export function getRootStore(node: IAnyStateTreeNode) {
  return getRoot<IRootStore>(node);
}
