// @ts-nocheck
import { getParent, types } from 'mobx-state-tree';
import { isEmpty, findIndex, find } from 'lodash';

import { formReportMixin } from './Track';

export const FormReport = types.model('FormReport', formReportMixin);

const DraftMessages = types.model('DraftMessages', {
  id: types.identifierNumber,
  text: types.string,
});

const Draft = types
  .model('Draft', {
    messages: types.map(DraftMessages),
    reports: types.array(FormReport),
  })
  .actions((self) => ({
    addMessage(channel, text) {
      self.messages.set(channel, { id: channel, text });
    },

    clearMessages() {
      self.messages = {};
    },

    saveDraftMessage() {
      const { chat, member } = self.application;

      const isEmptyMessage = isEmpty(chat.input.value);
      const hasDraftMessage = member && self.hasDraftMessage(member.id);

      if (!isEmptyMessage) {
        self.addMessage(member.channel.id, chat.input.value);
      }

      if (isEmptyMessage && hasDraftMessage) {
        self.removeMessage(member.channel.id);
      }
    },

    removeMessage(channelId) {
      self.messages.delete(channelId);
    },

    saveReport({ memberId, milestoneId, report }) {
      const { guidance, intro, toolbox } = report;

      const index = findIndex(self.reports, { milestoneId, memberId });

      const reportForm = {
        intro,
        memberId,
        milestoneId,
        guidance,
        toolbox,
      };

      if (index >= 0) {
        self.reports[index] = reportForm;
        return reportForm;
      }

      self.reports = [...self.reports, reportForm];

      return reportForm;
    },
  }))
  .views((self) => ({
    get application() {
      return getParent(self);
    },

    get draftMessage() {
      const channelId = self.application.member.channel.id;
      const message = self.messages.get(channelId);

      if (message) {
        return message.text;
      }

      return null;
    },

    getDraftReportById({ memberId, milestoneId }) {
      return find(self.reports, { memberId, milestoneId });
    },

    hasDraftMessage(id) {
      const channelId = self.application.members.get(id).channel.id;
      return !!self.messages.get(channelId);
    },
  }));

export default Draft;
