import DateTime from '@utils/mst/types/DateTime';
import { types } from 'mobx-state-tree';
import moment from 'moment-timezone';

const MemberSearchResult = types.model(
  'MemberSearchResult',
  {
    id: types.number,
    acute: types.boolean,
    organization: types.string,
    name: types.string,
    status: types.string,
    startAt: types.maybeNull(DateTime),
    lastMessageTimestamp: types.maybeNull(DateTime),
  },
)
  .views((self) => ({
    get startAtDisplayTimestamp() {
      return moment(self.startAt).format('YYYY-MM-DD hh:mma');
    },

    get lastMessagetDisplayTimestamp() {
      return self.lastMessageTimestamp ? moment(self.lastMessageTimestamp).format('YYYY-MM-DD hh:mma') : 'No Message Sent';
    },

  }));

export default MemberSearchResult;
