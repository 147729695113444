const dashboardInstancesKey = 'dashboard-instances';

function getDashboardInstances(): Set<string> {
  const serialized = window.localStorage.getItem(dashboardInstancesKey) || '[]';
  const parsed = JSON.parse(serialized) as Array<string>;
  return new Set(parsed);
}

function getWindowName(): string {
  if (!window.name) {
    throw new Error('window.name is not defined');
  }
  return window.name;
}

function setDashboardInstances(instances: Set<string>) {
  const serialized = JSON.stringify(Array.from(instances));
  window.localStorage.setItem(dashboardInstancesKey, serialized);
}

function removeDashboardInstance() {
  const windowName = getWindowName();
  const instances = getDashboardInstances();
  instances.delete(windowName);
  setDashboardInstances(instances);
}

function addDashboardInstance() {
  const windowName = getWindowName();
  const instances = getDashboardInstances();
  instances.add(windowName);
  setDashboardInstances(instances);
}

export function getDashboardInstancesCount() {
  return getDashboardInstances().size;
}

export function loadStorageEvent() {
  addDashboardInstance();

  // remove dashboard instance from ls when tab is closed
  window.addEventListener('beforeunload', () => {
    removeDashboardInstance();
  });
}
