// @ts-nocheck
import { flow, getRoot, types } from 'mobx-state-tree';
import { find, uniqBy, uniq } from 'lodash';
import moment from 'moment-timezone';

import { momentPT } from '@utils/dates';
import {
  trackEditDailySummary,
  trackSavedDailySummary,
} from '@utils/analytics';
import { Logger } from '@utils/logger';

import { DailySummary } from '../DailySummary';

// this will get us the expected format of 'YYYY-MM-DD' from the db date string
export function getDailySummaryString(date: string) {
  return date.substring(0, 10);
}

export default {
  attributes: {
    // TODO(kai): use a map instead of an array
    summaries: types.array(DailySummary), // holds all summaries already fetched (could be incomplete)
    summaryDates: types.array(types.string),
    summarySearchResult: types.array(types.reference(DailySummary)),
    searchQueries: types.array(types.string),
    selectedSummary: types.maybeNull(types.reference(DailySummary)),
    loadingSummary: types.maybe(types.boolean, false),
    initiallySelectedSummaryDate: types.maybeNull(types.string),
  },

  actions: (self) => ({
    searchDailySummary: flow(function* searchDailySummary(query) {
      self.summarySearchResult = [];
      self.searchQueries = [];
      self.selectedSummary = undefined;

      const application = getRoot(self);

      const result = yield application.hades.request('dailySummary.search', {
        memberId: application.member.id,
        query,
      });

      const summaries = result.records.slice().concat(self.summaries);
      self.summaries = uniqBy(summaries, 'id');
      self.summarySearchResult = result.records.map((record) => record.id);
      self.searchQueries = result.words;
    }),

    saveDailySummary: flow(function* saveDailySummary(text, date) {
      const {
        hades,
        showAlert,
        me,
        member: { id: memberId },
      } = getRoot(self);

      const isEditingSummary = self.hasSummaryForDate(date);

      Logger.log('Daily summary date input', date);
      const dateInPST = momentPT(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      Logger.log('Daily summary date input in PT', dateInPST);

      const summary = yield hades.request('dailySummary.upsert', {
        memberId: self.id,
        text,
        date: dateInPST,
      });

      if (isEditingSummary) {
        trackEditDailySummary({ coachId: me?.id, memberId, date });
      } else {
        trackSavedDailySummary({ coachId: me?.id, memberId, date });
      }

      summary.updatedBy = summary.coachId;

      const summaries = self.summaries.slice();
      summaries.unshift(summary);
      self.summaries = uniqBy(summaries, 'id');

      const summaryDates = self.summaryDates.slice();

      const summaryDate = getDailySummaryString(summary.date);

      summaryDates.unshift(summaryDate);
      self.summaryDates = uniq(summaryDates);

      self.setSummary(summaryDate);
      showAlert('Daily Summary has been saved');
      return self.selectedSummary;
    }),

    getSummary: flow(function* getSummary(date) {
      const { hades, member } = getRoot(self);

      // TODO fix this to avoid BE calls
      // const summary = self.summaries.find((s) => s.date.isSame(moment(date), 'day'));
      // if (summary) {
      //   return summary;
      // }

      const datePST = moment(date).format('YYYY-MM-DD');

      // we have a summary, but not in store. need to fetch from backend
      const fetched = yield hades.request('dailySummary.get', {
        memberId: member.id,
        date: datePST,
      });

      if (fetched) {
        const summaries = self.summaries.slice();
        summaries.unshift(fetched);
        self.summaries = uniqBy(summaries, 'id');

        return fetched;
      }

      // selected a new date, db doesnt have it, set to undefined!
      return undefined;
    }),

    setSummary: flow(function* setSummary(date) {
      const { showAlert } = getRoot(self);
      // "unselect" a summary
      if (date === undefined) {
        self.selectedSummary = undefined;
        return;
      }

      Logger.log('Daily summary hasSummaryForDate', self.hasSummaryForDate(date), date);

      if (!self.hasSummaryForDate(date)) {
        self.selectedSummary = undefined;
        return;
      }

      try {
        // it was actually a date object
        self.loadingSummary = true;
        const summary = yield self.getSummary(date);
        self.selectedSummary = summary.id;
      } catch (error) {
        showAlert('Error setting summary', 'danger', error);
      } finally {
        self.loadingSummary = false;
      }
    }),
  }),

  views: (self) => ({
    hasSummaryForDate(date: string) {
      // eslint-disable-next-line arrow-body-style
      return !!find(self.summaryDates, (summaryDate) => {
        return summaryDate === date;
      });
    },

    get sortedSummaryDates() {
      return self.summaryDates.slice().sort((a, b) => moment(b) - moment(a));
    },

    get lastDateWithSummary() {
      return self.sortedSummaryDates[0] || undefined;
    },
  }),
};
