import { APIError } from '@utils/customErrors';
import { AxiosError } from 'axios';

interface RequestData {
  method?: string;
  params?: object;
  id?: string;
  jsonrpc?: string;
}

/**
 * Convert a generic Axios error into a Sibly API error.
 *
 * @param error - The Axios error to convert.
 * @returns A SiblyAPIError representing the error.
 */
export const getAPIError = (error: AxiosError): APIError => {
  let data: RequestData = {};

  if (error.config.data) {
    data = JSON.parse(error.config.data);
  }

  const apiError = new APIError(error.message);
  apiError.stack = error.stack;
  apiError.code = error.request.status;
  if (
    error.config.headers?.['x-amzn-RequestId'] &&
    typeof error.config.headers?.['x-amzn-RequestId'] === 'string'
  ) {
    apiError.requestId = error.config.headers['x-amzn-RequestId'];
  }
  apiError.url = error.request.responseURL;
  if (data?.method) {
    apiError.method = data?.method;
  }
  return apiError;
};

/**
 * Convert an Axios error response into a Sibly API error.
 *
 * @param error - The Axios error response to convert.
 * @returns A SiblyAPIError representing the error response.
 */
export const getHadesError = (
  error: AxiosError<{ error?: APIError }>,
): APIError => {
  if (
    'response' in error &&
    error.response?.data &&
    'error' in error.response.data
  ) {
    const innerError = error.response.data.error;
    if (innerError) {
      let data: RequestData = {};

      if (error.config.data) {
        data = JSON.parse(error.config.data);
      }

      const innerErrorObject = new APIError(innerError.message);
      innerErrorObject.code = innerError.code;
      if (
        error.config.headers?.['x-amzn-RequestId'] &&
        typeof error.config.headers?.['x-amzn-RequestId'] === 'string'
      ) {
        innerErrorObject.requestId = error.config.headers['x-amzn-RequestId'];
      }
      innerErrorObject.stack = error.stack;
      innerErrorObject.url = error.request.responseURL;
      if (data?.method) {
        innerErrorObject.method = data?.method;
      }
      return innerErrorObject;
    }
  }
  return getAPIError(error);
};
