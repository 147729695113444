import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export enum LinkPreviewState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const ImageModel = types.model({
  height: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  url: types.string,
  width: types.maybeNull(types.string),
});

export const LinksPreview = types
  .model({
    id: types.identifier,
    state: types.enumeration([
      LinkPreviewState.ERROR,
      LinkPreviewState.LOADING,
      LinkPreviewState.SUCCESS,
    ]),
    url: types.string,
    siteName: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    favicon: types.maybeNull(types.string),
    image: types.maybeNull(ImageModel),
    videoUrl: types.maybeNull(types.string),
  })
  .views((self) => ({
    get finishedLoading() {
      return self.state === LinkPreviewState.SUCCESS;
    },
  }));

export type ILinkPreview = Instance<typeof LinksPreview>;
export type LinkPreviewInput = SnapshotIn<typeof LinksPreview>;
