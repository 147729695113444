import { sharedInMemoryStorage } from '@sibly/sibly-sdk-browser';
import { Environment } from './types';

export const mock: Environment = {
  name: 'mock',
  env: 'mock',
  mock: true,

  hades: {
    host: `http://${window.location.host}`,
  },

  socket: {
    host: `ws://${window.location.host}`,
  },

  sendbird: {
    appId: 'A44D94BD-99C0-4F26-AEAF-8D4BF69BC180',

    bot: {
      id: 'bot',
    },

    coach: {
      id: 'sibly',
      token: 'd61e10138ece832212624d97ce2e732bfa7bb41e',
    },
  },

  segment: {
    key: 'NGffpp9mD65ezVJC8x78UFZfAmp29VLW',
    track: true,
  },

  // QA key
  split: {
    key: 'k24f71keue19amad2nbqmqrmt4uupqd0l3g5',
  },

  // QA key
  userSnap: {
    apiKey: 'd7368b35-bd23-451c-93a3-d430984be9b0',
  },

  fullStory: {
    orgId: '182P5Q',
    active: false,
  },

  siblySDK: {
    // NOTE: will be fixed soon
    Auth: {
      userPoolId: 'us-west-1_zE2AOgirg',
      userPoolClientId: '6f24uqruo57vvpqv521tvnr5c9',
    },
    transportOptions: {
      region: 'us-west-1',
      endpoint:
        'https://vfzvip76kjgu5m6uvl4rqy7xiq.appsync-api.us-west-1.amazonaws.com/graphql',
    },
    appId: 'a84a7a19-b6af-4388-850f-eab27dce8d58',
    storage: sharedInMemoryStorage,
  },

  sessionTimeouts: {
    inactivityDetectionTimeout: 30 * 60 * 1000, // 30 minutes
    logoutModalTimeout: 2 * 60 * 1000, // 2 minutes
  },
};
