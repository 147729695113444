import { ComponentProps } from 'react';
import { Link } from '@reach/router';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import SiblyLogo from '@icons/sibly-logo.svg';

import Store from '@store';
import ChatNavigationSettings from '@dashComponents/Chat/Navigation/Settings';
import cn from 'classnames';

import { UNMASK_CLASSNAME } from '@components/FullStory';
import styles from './header.module.scss';

function NavLink(props: ComponentProps<typeof Link>) {
  return <Link
    {...props}
    // the object returned here is passed to the
    // anchor element's props
    getProps={({ isCurrent }) => ({
      className: cn(props.className, isCurrent && styles.activeLink),
    })}
  />;
}

function Header() {
  return (
    <Navbar bg="secondary" className={cn(styles.navbar, UNMASK_CLASSNAME)} variant="light">
      <Navbar.Brand className="m-0 p-0">
        <div className={styles.logoContainer}>
          <img src={SiblyLogo} alt="Sibly Logo" className="mx-2" height={32} />
        </div>
      </Navbar.Brand>
      <Nav className="mr-auto">
        <NavLink key="/dashboard" className={styles.link} to="/dashboard"><div className={styles.linkContainer}>Dashboard</div></NavLink>
        {
          Store.me?.isSeller
          && <NavLink key="/organizations" className={styles.link} to="/organizations" data-testid="navbar-organizations"><div className={styles.linkContainer}>Organizations</div></NavLink>
        }
        <NavLink key="/reports" className={styles.link} to="/reports"><div className={styles.linkContainer}>Reports</div></NavLink>
        {
          Store.me?.canManageCoaches
          && <NavLink key="/coaches" className={styles.link} to="/coaches" data-testid="navbar-coaches"><div className={styles.linkContainer}>Coaches</div></NavLink>
        }
        {
          Store.me?.isAdmin
          && <NavLink key="/conversations" className={styles.link} to="/conversations"><div className={styles.linkContainer}>Conversations</div></NavLink>
        }
      </Nav>
      <div className={styles.verticalSeparator} />
      <Nav>
        <ChatNavigationSettings />
      </Nav>
    </Navbar>
  );
}

export default Header;
