// @ts-nocheck
import {
  flow,
  getRoot,
  types,
} from 'mobx-state-tree';

import NPSSurvey from '../NPSSurvey';

export default {
  attributes: {
    NPSSurvey: types.maybe(NPSSurvey),
  },

  actions: (self) => ({
    sendNPSSurvey: flow(function* sendNPSSurvey() {
      const root = getRoot(self);

      const result = yield root.hades.request(
        'member.sendNPS',
      );

      return result;
    }),
  }),
};
