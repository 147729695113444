// @ts-nocheck
import { types } from 'mobx-state-tree';

const Request = types
  .model('Request', {
    id: types.identifier,
    error: types.maybe(types.string),
    status: types.maybe(
      types.enumeration('Status', ['ERROR', 'IDLE', 'PENDING', 'REJECTED', 'RESOLVED']),
    ),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    status: 'PENDING',
  }))
  .actions((self) => ({
    process(status, error) {
      self.status = status;
      self.error = error;
    },
  }))
  .views((self) => ({
    get hasError() {
      return self.status === 'ERROR' || self.status === 'REJECTED';
    },

    get isLoading() {
      return self.status === 'PENDING';
    },
  }));

export default Request;
