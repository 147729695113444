import { v4 } from 'uuid';
import { SiblySDK } from '@sibly/sibly-sdk-browser';
import FullStoryService from '@services/FullStory';
import Store from '@store';
import { loadStorageEvent } from './locastorage';
import { startAudioContext } from './notifications';
import { addVisibilityChangeHandler } from './pageVisibility';

import Config from '../Config';

function loadWindowProperties() {
  if (!window.name) {
    window.name = v4();
  }
}

export function bootup() {
  loadWindowProperties();
  SiblySDK.configure(Config.siblySDK);
  SiblySDK.Authorization.authListener.startListening(async ({ payload }) => {
    if (payload.event === 'tokenRefresh_failure') {
      await Store.logout();
    }
    if (payload.event === 'signedOut') {
      // NOTE: clear Application state; navigate to login page etc.
      await Store.clearUserSession();
    }
  });
  FullStoryService.init();
  addVisibilityChangeHandler();
  loadStorageEvent();
  startAudioContext();
}
